import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AlertProvider from '../contexts/AlertContext';
import SidebarProvider from '../contexts/SidebarContext';
import MainContainers from '../components/containers/main_containers/MainContainers';
import ToastAlert from '../components/shared/Alert';
import './Home.css';
import Login from './Login/LoginPage';
import { AuthContext } from '../contexts/AuthContext';
import { Grid } from '@material-ui/core';
import logoLogin from "../assets/pngs/logoLogin.png";
import { LogoGrande } from '../components/shared/Logo';

const Home = (): JSX.Element => {
  const authContext = useContext(AuthContext);
  return (
    <div className="home">
      <Router>
        <AlertProvider>
          <SidebarProvider>
            {authContext &&
              authContext.token &&
              authContext.permissions.length > 0 ? (
              <MainContainers>
                <ToastAlert />
              </MainContainers>
            ) : (
                <div className='Login'>
                  <img src={logoLogin} className='logo'/>
                  <Login>
                    <ToastAlert />
                  </Login>
                </div>
            )}
          </SidebarProvider>
        </AlertProvider>
      </Router>
    </div>
  );
};
export default Home;
