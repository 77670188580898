import API from './services';
import { IGeneralCondition } from '../models/IGeneralCondition';

interface IGetGeneralConditionsResponse {
    value: IGeneralCondition[]
}

export const getGeneralConditions = () => {
    return API.get<IGetGeneralConditionsResponse>('/Condicoes');
}

export const getFile = (id: string) => {
    return API.get<any>(`/Condicoes/download/${id}`);
}

export const postGeneralCondition = (generalCondition: FormData) => {
    return API.post('/Condicoes/Upload', generalCondition)
}

export const activateDeactivateCondition = (id: string) => {
    return API.delete(`/Condicoes/ativarDesativar/${id}`, '')
}

export const deleteGeneralCondition = (id: string) => {
    return API.delete(`/Condicoes/${id}`, '')
}