import React, { useState, useEffect, useContext } from 'react';
import InputMask from 'react-input-mask';
import { Grid, Typography, TextField, InputAdornment, Divider, Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Decision } from '../../../../components/shared/Alert';
import PartnersTable, { Column, Actions } from '../../../../components/shared/Table';

import nProgress from 'nprogress';
import { snackbar, formatDateBr } from '../../../../utils/util';
import { Client } from '../../ClientModel';

import { AlertContext } from '../../../../contexts/AlertContext';
import API from '../../../../services/services';
import { getPartners } from '../../../../services/client-service';
import LabelHeaderTable from '../../../../components/shared/Label/LabelHeaderTable';
import moment from 'moment';

const Partner = (props: any): JSX.Element => {

  const { setAlert } = useContext(AlertContext);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [maskCpfCnpj, setMaskCpfCnpj] = useState('999.999.999-99');
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [idPartner, setIdPartner] = useState(0);
  const [showDeleteDecisionAlert, setShowDeleteDecisionAlert] = useState(false);
  const [partners, setPartners] = useState<any[]>([]);
  const [editPartners, setEditPartners] = useState(false);

  const [namePartnerError, setNamePartnerError] = useState(false);
  const [cpfCnpjPartnerError, setCpjCnpjPartnerError] = useState(false);
  const [votingCapitalPartnerError, setVotingCapitalPartnerError] = useState(false);
  const [totalCapitalPartnerError, setTotalCapitalPartnerError] = useState(false);
  const [datePartnerError, setDatePartnerError] = useState(false);
  const [birthDatePartnerError, setBirthDatePartnerError] = useState(false);
  const [addressPartnerError, setAddressPartnerError] = useState(false);
  const [cityPartnerError, setCityPartnerError] = useState(false);
  const [statePartnerError, setStatePartnerError] = useState(false);
  const [cepPartnerError, setCepPartnerError] = useState(false);

  const [partner, setPartner] = useState<any>({
    name: '',
    birthDate: '',
    cpfCnpj: '',
    votingCapital: '',
    totalCapital: '',
    entryDate: '',
    address: '',
    city: '',
    state: '',
    cep: '',
    customerId: parseInt(props.id)
  } as any);

  const columnsPartners: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'entryDate',
      label: 'Entrada',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'cpfCnpj',
      label: 'CPF/CNPJ',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'votingCapital',
      label: '% Votante',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'totalCapital',
      label: '% Total',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#006193",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0] as number;
          const name = selectedRow.rowData[1];
          const cpfCnpj = selectedRow.rowData[3];
          const votingCapital = selectedRow.rowData[4] as number;
          const totalCapital = selectedRow.rowData[5] as number;
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DELETAR',
                  onClick: (): void => {
                    setShowDeleteDecisionAlert(true);
                    setIdPartner(id);
                  },
                },
                {
                  type: 'EDITAR',
                  onClick: (): void => {
                    setEditPartners(true);
                    setIdPartner(id);
                    partner.name = name
                    partner.cpfCnpj = cpfCnpj
                    partner.votingCapital = votingCapital
                    partner.totalCapital = totalCapital
                    partner.address = partners[selectedRow.rowIndex].address
                    partner.city = partners[selectedRow.rowIndex].city
                    partner.state = partners[selectedRow.rowIndex].state
                    partner.cep = partners[selectedRow.rowIndex].cep 
                    partner.birthDate = partners[selectedRow.rowIndex].birthDate.slice(0, 10);                 
                    partner.entryDate = partners[selectedRow.rowIndex].entryDate.slice(0, 10);                 
                  },
                },
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchPartners();
  }, [pagination]);

  const removePartner = async (decision: 'OK' | 'CANCEL', id: number) => {
    setShowDeleteDecisionAlert(false);
    nProgress.start();
    if (decision === 'OK') {
      const { data } = await API.delete('/partners', id);
      if (data?.deleted) {
        snackbar(setAlert, {
          type: 'success',
          message: 'Sócio removido com sucesso',
        });
        fetchPartners()
      } else {
        snackbar(setAlert, {
          type: 'error',
          message: 'Erro ao remover sócio',
        });
      }
    }
    nProgress.done();
  };

  function validationPartner() {
    setNamePartnerError(!partner.name);
    setCpjCnpjPartnerError(!partner.cpfCnpj);
    setVotingCapitalPartnerError(!partner.votingCapital);
    setTotalCapitalPartnerError(!partner.totalCapital);
    setDatePartnerError(!partner.entryDate);
    setBirthDatePartnerError(!partner.birthDate);
    setAddressPartnerError(!partner.address);
    setCityPartnerError(!partner.city);
    setStatePartnerError(!partner.state);
    setCepPartnerError(!partner.cep);
    return (
      !!partner.name &&
      !!partner.cpfCnpj &&
      !!partner.votingCapital &&
      !!partner.totalCapital &&
      !!partner.entryDate &&
      !!partner.birthDate &&
      !!partner.address &&
      !!partner.city &&
      !!partner.state &&
      !!partner.cep
    );
  }

  const savePartner = async () => {
    if (editPartners) {
      try {
        const { status } = await API.patch(`/partners/${idPartner}`, partner);
        if (status === 200) {
          setEditPartners(false)
          fetchPartners();
          partner.name = ''
          partner.birthDate = ''
          partner.cpfCnpj = ''
          partner.votingCapital = ''
          partner.totalCapital = ''
          partner.entryDate = ''
          partner.address = ''
          partner.city = ''
          partner.state = ''
          partner.cep = ''
          snackbar(setAlert, {
            type: 'success',
            message: 'Sócio atualizado com sucesso',
          })
        }
      } catch (err: any) {
        const message = err?.response?.data?.message[0] || 'Erro ao tentar atualizar o sócio';
        snackbar(setAlert, {
          type: 'error',
          message
        })
      }
    } else {
      try {
        const { status } = await API.post(`/partners`, partner);
        if (status === 201) {
          snackbar(setAlert, {
            type: 'success',
            message: 'Sócio criado com sucesso!',
          })
          fetchPartners();
          partner.name = ''
          partner.birthDate = ''
          partner.cpfCnpj = ''
          partner.votingCapital = ''
          partner.totalCapital = ''
          partner.entryDate = ''
          partner.address = ''
          partner.city = ''
          partner.state = ''
          partner.cep = ''
        }
      } catch (err: any) {
        const message = err?.response?.data?.message[0] || 'Erro ao tentar criar o sócio';
        snackbar(setAlert, {
          type: 'error',
          message
        })
      }
    }
  };

  const fetchPartners = async () => {
    const params = { customerId: props.id };
    getPartners(params)
      .then(({ data }) => {
        data.data.forEach((element: any, index: number) => {
          element.entryDate = moment(element.entryDate).format('DD/MM/YYYY');
        });
        setPartners([
          ...new Array(pagination.page * pagination.limit),
          ...data.data,
        ]);
        setTotal(data.total);
      })
      .catch((error) =>
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message || 'Erro ao listar os sócioes',
        }),
      );
  };

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setPartners([]);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({ ...old, page }));
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};
      const { data } = await API.get<any>(`/partners`, params);
      setPartners([
        ...new Array(pagination.page * pagination.limit),
        ...data.data,
      ]);
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message: err?.response?.data?.message,
      });
    }
  };

  const maskBuilder = (value: string) => {
    let digits = value.replaceAll('_', '').replaceAll('.', '').replaceAll('-', '').replaceAll('/', '').length
    console.log(digits)
    if (digits <= 11) {
      setMaskCpfCnpj('999.999.999-99')
    } else {
      setMaskCpfCnpj('99.999.999/9999-99')
    }
  }

  return (
    <Grid container spacing={5} justifyContent="space-between">
      {showDeleteDecisionAlert && (
        <Decision
          title="Exclusão de registro"
          message="Tem certeza de que deseja excluir o registro selecionado?"
          handleDecision={(decision) => removePartner(decision, idPartner)}
        />
      )}
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Nome*
        </Typography>
        <TextField
          error={namePartnerError}
          helperText={namePartnerError ? 'Campo Obrigatório!' : ''}
          required
          variant="outlined"
          type="text"
          placeholder="Digite o nome do sócio"
          value={partner.name}
          onChange={({ target: { value } }): void =>
            setPartner({ ...partner, name: value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Data de Nascimento*
        </Typography>
        <TextField
          variant="outlined"
          type="date"
          error={birthDatePartnerError}
          helperText={birthDatePartnerError ? 'Campo Obrigatório!' : ''}
          required
          value={partner.birthDate}
          onChange={({ target: { value } }): void =>
            setPartner({ ...partner, birthDate: value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Typography className="subtitle">
          CPF/CNPJ*
        </Typography>
        <InputMask
          required
          mask={maskCpfCnpj}
          value={partner.cpfCnpj}
          onChange={({ target: { value } }): void => {
            maskBuilder(value)
            setPartner({ ...partner, cpfCnpj: value })
          }
          }
        >
          {() => (
            <TextField
              error={cpfCnpjPartnerError}
              helperText={cpfCnpjPartnerError ? 'Campo Obrigatório!' : ''}
              required
              variant="outlined"
              type="tel"
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Typography className="subtitle">
          Capital Votante*
        </Typography>
        <TextField
          variant="outlined"
          type="number"
          error={votingCapitalPartnerError}
          helperText={votingCapitalPartnerError ? 'Campo Obrigatório!' : ''}
          required
          value={partner.votingCapital}
          onChange={({ target: { value } }): void =>
            setPartner({ ...partner, votingCapital: Number(value) })
          }
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: (
              <InputAdornment position="start">%</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Typography className="subtitle">
          Capital Total*
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          error={totalCapitalPartnerError}
          helperText={totalCapitalPartnerError ? 'Campo Obrigatório!' : ''}
          required
          value={partner.totalCapital}
          onChange={({ target: { value } }): void =>
            setPartner({ ...partner, totalCapital: Number(value) })
          }
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: (
              <InputAdornment position="start">%</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Typography className="subtitle">
          Data de Entrada*
        </Typography>
        <TextField
          variant="outlined"
          type="date"
          error={datePartnerError}
          helperText={datePartnerError ? 'Campo Obrigatório!' : ''}
          required
          value={partner.entryDate}
          onChange={({ target: { value } }): void =>
            setPartner({ ...partner, entryDate: value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography className="subtitle">
          Endereço
        </Typography>
        <TextField
          variant="outlined"
          type="text"
          placeholder="Digite o endereço"
          error={addressPartnerError}
          helperText={addressPartnerError ? 'Campo Obrigatório!' : ''}
          required
          value={partner.address}
          onChange={({ target: { value } }): void =>
            setPartner({ ...partner, address: value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Typography className="subtitle">
          Cidade
        </Typography>
        <TextField
          variant="outlined"
          type="text"
          placeholder="Digite a cidade"
          error={cityPartnerError}
          helperText={cityPartnerError ? 'Campo Obrigatório!' : ''}
          required
          value={partner.city}
          onChange={({ target: { value } }): void =>
            setPartner({ ...partner, city: value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Typography className="subtitle">
          Estado
        </Typography>
        <TextField
          variant="outlined"
          type="text"
          placeholder="Digite o estado"
          error={statePartnerError}
          helperText={statePartnerError ? 'Campo Obrigatório!' : ''}
          required
          value={partner.state}
          onChange={({ target: { value } }): void =>
            setPartner({ ...partner, state: value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Typography className="subtitle">
          CEP
        </Typography>
        <InputMask
          mask="99999-999"
          value={partner.cep}
          onChange={({ target: { value } }): void =>
            setPartner({ ...partner, cep: value })
          }
          required
          name="cep"
          title={'CEP'}
        >
          {() => (
            <TextField
              variant="outlined"
              type="text"
              error={cepPartnerError}
              helperText={cepPartnerError ? 'Campo Obrigatório!' : ''}
              required
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} className="text-center">
        <Button
          className="button buttonContainedRounded buttonContainedColorPrimary CTA"
          onClick={() => {
            if (validationPartner() == true) {
              savePartner();
            }
          }}
        >
          <Check /> Salvar Sócio
        </Button>
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <PartnersTable
          columns={columnsPartners}
          data={partners}
          onSearchChange={(event: any) => { return debounce(onSearchChange, 700)(event); }}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          currentPage={pagination.page}
          totalRecords={total}
          rowsPerPage={rowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default Partner;
