import React, { useState, useEffect, useContext, useRef } from 'react';
import InputMask from 'react-input-mask';
import { Grid, Typography, TextField, InputAdornment, Divider, Button, Input } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Decision } from '../../../../components/shared/Alert';
import RevenuesTable, { Column, Actions } from '../../../../components/shared/Table';

import nProgress from 'nprogress';
import { snackbar, getYearDate, getMonthDate } from '../../../../utils/util';
import { Client } from '../../ClientModel';

import { AlertContext } from '../../../../contexts/AlertContext';
import API from '../../../../services/services';
import { getRevenues } from '../../../../services/client-service';
import LabelHeaderTable from '../../../../components/shared/Label/LabelHeaderTable';

const Revenue = (props: any): JSX.Element => {

  const { setAlert } = useContext(AlertContext);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [idRevenue, setIdRevenue] = useState(0);
  const [showDeleteDecisionAlert, setShowDeleteDecisionAlert] = useState(false);
  const [revenues, setRevenues] = useState<Client[]>([]);
  const [editRevenue, setEditRevenue] = useState(false);

  const [periodRevenueError, setPeriodRevenueError] = useState(false);
  const [valueRevenueError, setValueRevenueError] = useState(false);
  const monthRef = useRef<HTMLInputElement>(null);

  function currencyMask(value: any) {
    //let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d)/g, "$1");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".")

    return value;
  }

  const [revenue, setRevenue] = useState<any>({
    period: '',
    value: '',
    customerId: parseInt(props.id)
  } as any);

  const columnsRevenues: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'year',
      label: 'Ano',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'month',
      label: 'Mês',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'value',
      label: 'Valor',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#006193",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0] as number;
          const name = selectedRow.rowData[1];
          const contact = selectedRow.rowData[2];
          const phone = selectedRow.rowData[3];
          const profitSharing = selectedRow.rowData[4] as number;
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DELETAR',
                  onClick: (): void => {
                    setShowDeleteDecisionAlert(true);
                    setIdRevenue(id);
                  },
                },
                {
                  type: 'EDITAR',
                  onClick: (): void => {
                    setEditRevenue(true);
                    setIdRevenue(id);
                    revenue.name = name
                    revenue.contact = contact
                    //console.log("ph: ", phone)
                    //let ph = phone
                    //ph = ph.replaceAll('R$', '');
                    //ph = ph.replaceAll(' ', '');
                    //ph = ph.replaceAll('.', '');
                    //ph = ph.replaceAll(',', '.');
                    //revenue.phone = parseFloat(ph);
                    revenue.phone = currencyMask(phone);
                    //console.log("revenue phone: ", revenue.phone)
                    revenue.profitSharing = profitSharing
                    let periodConvert: string = revenue.contact;
                    switch (periodConvert) {
                      case 'Janeiro':
                        periodConvert = '-01'
                        break;
                      case 'Fevereiro':
                        periodConvert = '-02'
                        break;
                      case 'Marco':
                        periodConvert = '-03'
                        break;
                      case 'Abril':
                        periodConvert = '-04'
                        break;
                      case 'Maio':
                        periodConvert = '-05'
                        break;
                      case 'Junho':
                        periodConvert = '-06'
                        break;
                      case 'Julho':
                        periodConvert = '-07'
                        break;
                      case 'Agosto':
                        periodConvert = '-08'
                        break;
                      case 'Setembro':
                        periodConvert = '-09'
                        break;
                      case 'Outubro':
                        periodConvert = '-10'
                        break;
                      case 'Novembro':
                        periodConvert = '-11'
                        break;
                      case 'Dezembro':
                        periodConvert = '-12'
                        break;
                    }
                    periodConvert = revenue.name + periodConvert
                    setRevenue(
                      {
                        period: periodConvert,
                        value: revenue.phone,
                        customerId: parseInt(props.id)
                      }
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchRevenues();
  }, [pagination]);

  const removeRevenue = async (decision: 'OK' | 'CANCEL', id: number) => {
    setShowDeleteDecisionAlert(false);
    nProgress.start();
    if (decision === 'OK') {
      const { data } = await API.delete('/revenues', id);
      if (data?.deleted) {
        snackbar(setAlert, {
          type: 'success',
          message: 'Faturamento removido com sucesso',
        });
        fetchRevenues()
      } else {
        snackbar(setAlert, {
          type: 'error',
          message: 'Erro ao remover faturamento',
        });
      }
    }
    nProgress.done();
  };

  function validationRevenue() {
    setPeriodRevenueError(!revenue.period);
    setValueRevenueError(!revenue.value);
    return (
      !!revenue.period &&
      !!revenue.value
    );
  }

  const saveRevenue = async () => {
    revenue.period = revenue.period + '-01';
    if (editRevenue) {
      
      //console.log("editando faturamento: ", revenue);
      //return;
      try {
        const { status } = await API.patch(`/revenues/${idRevenue}`, {
          period: revenue.period,
          value: parseFloat(revenue.value.replace('.','').replace(',','.')),
          customerId: revenue.customerId
        });
        if (status === 200) {
          setEditRevenue(false)
          fetchRevenues();
          setRevenue({
            period: '',
            value: '',
            customerId: parseInt(props.id)
          });
          revenue.name = ''
          revenue.contact = ''
          revenue.phone = ''
          revenue.profitSharing = ''
          snackbar(setAlert, {
            type: 'success',
            message: 'Faturamento atualizado com sucesso',
          })
        }
      } catch (err: any) {
        const message = err?.response?.data?.message[0] || 'Erro ao tentar atualizar o faturamento';
        snackbar(setAlert, {
          type: 'error',
          message
        })
      }
    } else {
      try {
        //console.log("faturamento: ", parseFloat(revenue.value.replace('.','').replace(',','.')))
        //return;
        const { status } = await API.post(`/revenues`, {
          period: revenue.period,
          value: parseFloat(revenue.value.replace('.','').replace(',','.')),
          customerId: revenue.customerId
        });
        if (status === 201) {
          snackbar(setAlert, {
            type: 'success',
            message: 'Faturamento criado com sucesso!',
          })
          fetchRevenues();
          setRevenue({
            period: '',
            value: '',
            customerId: parseInt(props.id)
          });
          revenue.name = ''
          revenue.contact = ''
          revenue.phone = ''
          revenue.profitSharing = ''
        }
      } catch (err: any) {
        const message = err?.response?.data?.message[0] || 'Erro ao tentar criar o faturamento';
        snackbar(setAlert, {
          type: 'error',
          message
        })
      }
    }
  };

  const fetchRevenues = async () => {
    const params = { customerId: props.id };
    getRevenues(params)
      .then(({ data }) => {
        //console.log("aa", data);
        let revenueData: Object[] = [];
        data.data.forEach((rev: any) => {
          let year = getYearDate(rev.period)
          let month = getMonthDate(rev.period)
          let value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rev.value)

          let obj = {
            id: rev.id,
            year: year,
            month: month,
            value: value,
          }
          revenueData.push(obj)
        })
        setRevenues([
          ...new Array(pagination.page * pagination.limit),
          ...revenueData,
        ]);
        setTotal(data.total);
      })
      .catch((error) =>
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message || 'Erro ao listar os compradores',
        }),
      );
  };

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setRevenues([]);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({ ...old, page }));
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};
      const { data } = await API.get<any>(`/revenues`, params);
      setRevenues([
        ...new Array(pagination.page * pagination.limit),
        ...data.data,
      ]);
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message: err?.response?.data?.message,
      });
    }
  };

  return (
    <Grid container spacing={5} justifyContent="space-between">
      {showDeleteDecisionAlert && (
        <Decision
          title="Exclusão de registro"
          message="Tem certeza de que deseja excluir o registro selecionado?"
          handleDecision={(decision) => removeRevenue(decision, idRevenue)}
        />
      )}
      <Grid item xs={12} sm={12} md={6} >
        <Typography className="subtitle">
          Período*
        </Typography>
        <TextField
          type="month"
          error={periodRevenueError}
          helperText={periodRevenueError ? 'Campo Obrigatório!' : ''}
          variant="outlined"
          inputRef={monthRef}
          value={revenue.period}
          onChange={(e): void => {
            setRevenue({ ...revenue, period: e.currentTarget.value });
          }
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Valor*
        </Typography>
        <TextField
          variant="outlined"
          type="tel"
          error={valueRevenueError}
          helperText={valueRevenueError ? 'Campo Obrigatório!' : ''}
          value={revenue.value}
          onChange={({ target: { value } }): void => {
            //console.log('faturamento: ',);
            setRevenue({ ...revenue, value: currencyMask(value) })
          }}
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} className="text-center">
        <Button
          className="button buttonContainedRounded buttonContainedColorPrimary CTA"
          onClick={() => {
            if (validationRevenue() == true) {
              saveRevenue();
            }
          }}
        >
          <Add /> Adicionar Faturamento
        </Button>
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <RevenuesTable
          columns={columnsRevenues}
          data={revenues}
          onSearchChange={(event: any) => { return debounce(onSearchChange, 700)(event); }}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          currentPage={pagination.page}
          totalRecords={total}
          rowsPerPage={rowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default Revenue;
