import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { PlaylistAddCheck, Close } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { createRole } from '../../../services/role-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import { Checkbox, FormControlLabel } from '@mui/material';
import Modal from '../../../components/shared/Modal/ModalSales';
import PermissionGroupSelect from '../../../components/shared/inputs/PermissionGroupSelect';

interface ICreateRoleModal {
    setOpen: any;
    open: boolean;
    clients: object[];
}
const ShowNotificationModal = (props: ICreateRoleModal): JSX.Element => {
    const { open, setOpen, clients } = props;
    const { setAlert } = useContext(AlertContext);
    
    const close = async () => {
        
        setOpen(false);
    }

    return (
        <Modal 
            open={open}
            modalWidth={'700px'}
            handleClose={setOpen}
          >
             <Grid container spacing={2}>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <PlaylistAddCheck style={{ color: 'orange' }} />
                <span style={{fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>&nbsp;DETALHES DA NOTIFICAÇÃO</span>
                <div style={{marginLeft: 420, cursor: 'pointer'}} onClick={close}>
                  <Close  />
                </div>
              </div>
              <div>
                {clients.map((obj: any) => <p>{obj?.nome}</p>)}
              </div>   
            </Grid>
          </Modal>
    );
};

export default ShowNotificationModal;
