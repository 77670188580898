import React, { useState, useEffect, useContext } from 'react';
import CoverageTable, { Column, Actions, StatusOfColumn } from '../../components/shared/Table';
import { Button, Grid, TextField, Typography, InputAdornment, IconButton, Switch  } from '@material-ui/core';
import Container from '../../components/shared/Container';
//import { getUsers, activeUser } from '../../../services/user-service';
import { AlertContext } from '../../contexts/AlertContext';
import { snackbar } from '../../utils/util';
import API from '../../services/services';
import LabelHeaderTable from '../../components/shared/Label/LabelHeaderTable';
import { DeleteForever, Launch, Visibility, PlaylistAddCheck } from '@material-ui/icons';
import Modal from '../../components/shared/Modal/ModalSales'
import { borderRadius, fontStyle } from '@mui/system';
import './styles.css';

import CreateCoverage from './CreateCoverage';
import ShowCoverage from './ShowCoverage';
import EditCoverage from './EditCoverage';

interface iCoverage{
  id: number;
  order: number;
  coverage: string;
}

const Coverage: React.FC = () => {
  const [coverage, setCoverage] = useState<iCoverage[]>([]);
  const { setAlert } = useContext(AlertContext);
  const [id] = useState(0);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedCoverage, setSelectedCovergare] = useState({})

  const [idToDel, setIdToDel] = useState(0);

  const [showModalNewCoverage, setShowModalNewCoverage] = useState(false);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalDel, setShowModalDel] = useState(false);
  const [showModalUpdateCoverage, setShowModalUpdateCoverage] = useState(false);
  
  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setCoverage([]);
  };
  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page,
    }));
  };

  async function disableEnableCoverage(event: any, id: number) {
    try{
      const response = await API.delete(`Cobertura/ativarDesativar`, id);
      snackbar(setAlert, { type: 'success', message: 'Ação realizada com sucesso!'});
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          err?.response?.data?.errors[0]
      });
    }
    
    fetchCoverage();
  };

  const deleteTarget = async () => {
    
    try {
      const resp = await API.delete(`Cobertura`, idToDel);
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });
      //setPagination({ limit:10, page: 0 })
      fetchCoverage();
    } catch (ex: any) {
      //snackbar(setAlert, { type: 'error', message: '' });
      snackbar(setAlert, { type: 'error', message: ex?.response?.data?.message });
    } finally {
      setShowModalDel(false);
    }
  };

  const columns: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      },
    },
    {
      name: 'ordem',
      label: 'Ordem',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'nome',
      label: 'Cobertura',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'coberturaOpcao',
      label: 'Valores',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        customBodyRender: (value) => <>{value.map((op: any) => op?.opcao).toString()}</>,
        sort: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        customBodyRender: (value) => <>{value ? 'Ativada' : 'Desativada'}</>,
        sort: false,
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0] as number;
          const activeColumn = selectedRow.rowData[4];
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DISABLE',
                  onClick: disableEnableCoverage,
                  id: id,
                  checked: activeColumn
                },
                {
                  type: 'OTHER',
                  onClick: () => {
                    setSelectedCovergare(selectedRow.rowData);
                    setShowModalDetails(true)
                  },
                  icon: <Visibility />,
                  text:'visualizar',
                  id: id
                },
                {
                    type: 'OTHER',
                    onClick: () => {
                      setIdToDel(selectedRow.rowData[0]);
                      setShowModalDel(true)
                    },
                    icon: <DeleteForever />,
                    text:'excluir',
                    id: id
                },
                {
                  type: 'OTHER',
                  onClick: () => {
                    setSelectedCovergare(selectedRow.rowData);
                    setShowModalUpdateCoverage(true);
                  },
                  icon: <Launch />,
                  text:'editar',
                  id: id
                },
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchCoverage();
  }, [pagination]);

  const fetchCoverage = async () => {

    try{
      const response = await API.get<any>('/Cobertura');
      //console.log(response);
      setCoverage(response.data?.value);
     } catch(err: any){
      snackbar(setAlert, { type: 'error', message: err?.response?.data?.message });
     }
    /*getUsers(
      pagination.page + 1,
      pagination.limit)
      .then(({ data }) => {
        console.log(data);
        
        data.value.forEach((element: any, index: number) => {
          element.deletado = element.deletado === true ? 'Inativo' : 'Ativo';
        });
        setUsers([
          ...new Array(pagination.page * pagination.limit),
          ...data.value,
        ]);
        setTotal(data.total);
      })
      .catch((error) =>
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message || 'Erro ao listar o usuários',
        }),
      );*/
  };

  /*const deleteMultiple = async (usersId: any) => {
    try {
      await API.post('/users/deactivate-multiple', { ids: usersId });
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });

      //return window.location.reload();
      fetchUsers();
    } catch (ex: any) {
      snackbar(setAlert, { type: 'error', message: ex?.response?.data?.message });
    }
    // finally {
    //   setIsLoaded(true);
    // }
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};
      //const { data } = await API.get<any>(`/users`, params);
      getUsers(
        pagination.page + 1,
        pagination.limit,
        params
      ).then(({ data }) => {
        console.log(data);
        
        setTotal(data.total);
        searchText[0] ? onChangeRowsPerPage(total) : onChangeRowsPerPage(10)
        setUsers(users => users.concat(data.data));
      })
    } catch (err: any) {
      // snackbar(setAlert, {
      //   type: 'error',
      //   message: err?.response?.data?.message,
      // });
    }
  };
*/
  return (
    <>
      <Grid container>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Coberturas
        </Typography>
        <Button 
          style={{ position: 'absolute', top: 65, right: 15, color: '#fff', backgroundColor: '#30235d', borderRadius: 10, padding: 6}}
          onClick={() => setShowModalNewCoverage(true)} 
        >
            Nova Cobertura
  </Button>
        <Container>
          <Grid item xs={12}>
            <Grid style={{ padding: "10px" }}>
              <CoverageTable
                title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Consultar Coberturas</h3>}
                columns={columns}
                data={coverage}
                //onSearchChange={(event: any) => {
                  //return debounce(onSearchChange, 700)(event);
                //}}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                currentPage={pagination.page}
                totalRecords={total}
                rowsPerPage={rowsPerPage}
              // onRowsDelete={(indexRole) => {
              //   console.log("index: ", indexRole);
              //   const usersId = users
              //     .filter((_: any, i: any) => indexRole.find((x) => x.index === i))
              //     .map((id: any) => id.id);
              //     console.log('users ids: ', usersId)
              //   deleteMultiple(usersId);
              // }}
              />
            </Grid>
          </Grid>
         <CreateCoverage 
          open={showModalNewCoverage} 
          setOpen={setShowModalNewCoverage}
          fetchData={() => fetchCoverage()}
          />
         <ShowCoverage
          open={showModalDetails}
          setOpen={setShowModalDetails}
          coverageSelected={selectedCoverage}
         />
          <Modal 
            open={showModalDel}
            modalWidth={'400px'}
            handleConfirm={deleteTarget}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'EXCLUIR'}
            handleClose={() => setShowModalDel(false)}
          >
            <div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <span style={{fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>EXCLUIR COBERTURA</span>
              </div>
              <div style={{ fontSize: 18, color: '#8c8c8c', fontWeight: 'bold', marginTop: 30}}>Tem certeza que deseja excluir esta cobertura?</div>
            </div>
          </Modal>
          <EditCoverage
            open={showModalUpdateCoverage}
            setOpen={setShowModalUpdateCoverage}
            coverageSelected={selectedCoverage}
            updateData={() => fetchCoverage()}
          />
        </Container>
      </Grid>
    </>
  );
};

export default Coverage;
