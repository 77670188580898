import API from './services';
import { IGetUserId, IUser } from '../models/IUser'

interface UserRequestParams {
  search?: ISearch;
  empresaId?: string;
  name?: string;
  username?: string;
  cpf?: string;
  take?: number;
  page?: number;
  offset?: number;
  sortingProp?: string;
  ascending?: boolean;
}

interface ISearch {
  value?: string;
  fields: string[]
}

// const getUsers = (page?: number, limit?: number, params?: {}) => {
//   return API.get<any>(`/users?sorters=name&page=${(page)}&limit=${limit}`, params);
// };

// Usuario?take=10&page=2&loginType=corretor
// Usuario?loginType=corretor
// /Usuario?page=${(page)}&take=${take}
const getUsers = ( page?: number, take?: number, params?: {}) => {
  return API.get<any>(`/Usuario?page=${(page)}&take=${take}`);
};

const getBrokers= ( page?: number, take?: number, params?: {}) => {
  return API.get<any>(`Usuario?loginType=corretor`);
};

const getClients = () => {
  return API.get<any>(`/users?search[value]=3&search[fields]=roles.id`);
};

const getUserById = async (payload: IGetUserId) => {
  const { data } = await API.get<any>(`/Usuario/Consulta-id`, payload);
  return data;
};

// const getUserByGuid = async (guid: number) => {
//   const { data } = await API.get<any>(`/users/${guid}`);
//   return data;
// };

const createUser = async (user: IUser) => {
  return await API.post(`/Usuario`, user);
};

const createUserGroup = async (userId: string, groupId: string) => {
  return await API.post(`/Usuario/${userId}/grupo`, groupId);
};

const updateUser = async (user: IUser) => {
  const data = await API.put(`/Usuario/${user?.id}`, user);
  return data;
};

const removeUser = (id: number | string) => {
  console.log(id);
  const response = API.delete(`/Usuario`, id);
  console.log('Remover Broker', response)
  return API.delete(`/Usuario`, id);
}
const activeUser = (id: number) => {
  return API.delete(`/Usuario`, id)
}
const checkCustomerAvailability = (params: { cpf: string, email: string }) => {
  return API.get(`/check_customer_availability`, params)
}
const addCustomer = (body: any) => {
  return API.post(`/add_customer`, body)
}

export { getUsers, getBrokers, getClients, getUserById, createUser, updateUser, removeUser, activeUser, checkCustomerAvailability, addCustomer };
