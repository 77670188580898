import { ISale } from '../models/ISales';
import API from './services';

interface IGetSalesResponse {
    value: ISale[]
}

interface IGetSaleById {
    value: ISale
}

export const getSales = () => {
    return API.get<IGetSalesResponse>('/Promocoes', {
        sortingProp: 'dataCriacao',
        ascending: false
    });
};

export const getSaleById = (id: string) => {
    return API.get<IGetSaleById>(`/Promocoes/${id}`);
};

export const postSale = (sale: ISale) => {
    return API.post('/Promocoes', sale)
}

export const putSale = (sale: ISale) => {
    return API.put(`/Promocoes/${sale?.id}`, sale)
}

export const deleteSale = (id: string) => {
    return API.delete(`/Promocoes/${id}`, '')
}

export const activateDeactivateSale = (sale: any) => {
    return API.put(`/Promocoes/${sale?.id}`, { ...sale})
}