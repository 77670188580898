import API from './services';

export const getBrands = () => {
    return API.get<any>('/Marca');
}

export const getColours = () => {
    return API.get<any>('/Cor');
}

export const getGroups = () => {
    return API.get<any>('/Grupo');
}

export const getCovers = (payload?: any) => {
    return API.get<any>('/Cobertura', payload);
}

export const getUsers = (payload: any) => {
    return API.get<any>('/Usuario', payload)
}