import React, { useState, useEffect, useContext, useRef } from 'react';
import VehiclesTable, { Column, Actions, StatusOfColumn } from '../../components/shared/Table';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import Container from '../../components/shared/Container';
// import { getUsers, activeUser } from '../../services/user-service';
import { getVehicles, getVehicleById, putVehicle, activateDeactivateVehicle } from '../../services/vehicle-service';
import { AlertContext } from '../../contexts/AlertContext';
import { snackbar } from '../../utils/util';
import API from '../../services/services';
import LabelHeaderTable from '../../components/shared/Label/LabelHeaderTable';
import { DeleteForever, Launch, CloudDownload, Assignment, CloudUpload, Loop, DirectionsCar } from '@material-ui/icons';
import Modal from '../../components/shared/Modal/ModalSales';
import IVehicle from '../../models/IVehicle';
import { getBrands, getColours, getCovers, getUsers } from '../../services/options-service';
import { borderRadius, fontStyle } from '@mui/system';
import './styles.css';

const Vehicles: React.FC = () => {
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [vehicle, setVehicle] = useState<IVehicle>({
    id: '',
    placa: '',
    modeloNome: '',
    modeloAno: '',
    marcaNome: '',
    marcaId: '',
    chassi: '',
    renavam: '',
    corNome: '',
    corId: '',
    usuarioNome: '',
    usuarioId: '',
    situacao: '',
    cor: { id: '', nome: '' },
    marca: { id: '', nome: '' },
    veiculoCobertura: [
      {
        cobertura: {
          id: '',
          coberturaId: '',
          opcao: ''
        }
      }
    ]
  })
  const [brands, setBrands] = useState<any>([]);
  const [colours, setColours] = useState<any>([]);
  const [covers, setCovers] = useState<any>([]);
  const [ranges, setRanges] = useState<any>([]);
  const [selectedCover, setSelectedCover] = useState('');
  const [selectedCoverOption, setSelectedCoverOption] = useState(null);
  const [isCoverOptionsDisabled, setIsCoverOptionsDisabled] = useState(true);
  const [isCoverOptionsOpened, setIsCoverOptionsOpened] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const [newUser, setNewUser] = useState<any>({})
  const situations = [
    { label: 'Ativo', value: 'Ativo' },
    { label: 'Inativo', value: 'Inativo' },
    { label: 'Em análise', value: 'Analise' },
    { label: 'Aguardando pagamento', value: 'AguardandoPagamento' },
    { label: 'Aguardando cancelamento', value: 'AguardandoCancelamento' }

  ]

  const rangeInput = useRef(null);
  const { setAlert } = useContext(AlertContext);
  const [id] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [showModalTransfer, setShowModalTransfer] = useState(false);
  const [showModalUpdateVehicle, setShowModalUpdateVehicle] = useState(false);

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
  };
  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page,
    }));
  };

  /* const disableEnableUser = async (event: any, id: number) => {
    try{
      const { data } = await activeUser(id);
      
      if(data.updated){
        snackbar(setAlert, {
          type: 'success',
          message: 'Ação realizada com sucesso'
        });
      }
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          err?.response?.data?.message
      });
    }
    
    // fetchUsers();
  }; */

  const columns: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: 'placa',
      label: 'Placa',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'modeloNome',
      label: 'Modelo',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'renavam',
      label: 'Renavam',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'usuarioNome',
      label: 'Proprietário',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'situacao',
      label: 'Situação',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
        customBodyRender: (value: string) => StatusOfColumn(value)
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0];
          const status = selectedRow.rowData[1];
          const state = selectedRow.rowData[6]
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DISABLE',
                  onClick: () => changeVehicleStatus(id),
                  id: id,
                  checked: status,
                  text: 'Ativar/Desativar',
                  disabled: state === 'Analise' || state === 'AguardandoPagamento'
                },
                {
                  type: 'OTHER',
                  onClick: () => fetchVehicleById(id, 'TRANSFER', status),
                  icon: <Loop />,
                  text: 'Transferir veículo',
                  id: id,
                  disabled: state === 'Analise' || state === 'AguardandoPagamento'
                },
                {
                  type: 'OTHER',
                  onClick: () => fetchVehicleById(id, 'EDIT'),
                  icon: <Launch />,
                  text: 'Editar',
                  id: id,
                  disabled: !status
                },
              ]}
            />
          );
        },
      },
    },
  ];

  const coversColumns: Column[] = [
    {
      name: 'coberturaId',
      label: 'Código',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'coberturaOpcao',
      label: 'Opção',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        customBodyRender: value => value?.map((v: any) => v?.opcao),
        sort: false,
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0];
          const name = selectedRow.rowData[1];
          const coverOption = selectedRow.rowData[2];
          return (
            <Actions
              buttonProps={[
                {
                  type: 'OTHER',
                  onClick: () => editCoverOption(name),
                  icon: <Launch />,
                  text: 'Editar',
                  id: id,
                  disabled: !isCoverOptionsDisabled
                }
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchVehicles()
    fetchColours()
    fetchBrands()
    fetchCovers()
    fetchUsers()
  }, [])

  const fetchVehicles = async () => {
    setIsLoaded(true)
    try {
      const { data: { value } } = await getVehicles();
      setVehicles(value)
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoaded(false)
  }

  const fetchVehicleById = async (id: string, type: 'EDIT' | 'TRANSFER', status?: boolean) => {
    if (type === 'TRANSFER' && status) {
      snackbar(setAlert, {
        type: 'warning',
        message:
          'Não é possível transferir o veículo, o mesmo encontra-se ativo.',
        timeToClose: 5000
      });
    } else {
      type === 'EDIT' ? setShowModalUpdateVehicle(true) : setShowModalTransfer(true)
      setIsLoaded(true)
      try {
        const { data: { value } } = await getVehicleById(id);
        setVehicle({ ...value, veiculoCobertura: value?.veiculoCobertura.map(v => v?.cobertura) })
      } catch (error: any) {
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message
        });
      }
      setIsLoaded(false)
    }
  }

  const fetchBrands = async () => {
    setIsLoaded(true)
    try {
      const { data: { value } } = await getBrands();
      setBrands(value)
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoaded(false)
  }

  const fetchColours = async () => {
    setIsLoaded(true)
    try {
      const { data: { value } } = await getColours();
      setColours(value)
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoaded(false)
  }

  const fetchCovers = async () => {
    setIsLoaded(true)
    try {
      const { data: { value } } = await getCovers();
      setCovers(value)
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoaded(false)
  }

  const fetchRangeCovers = async (option: any) => {
    setIsLoaded(true)
    try {
      const { data: { value } } = await getCovers({ nome: option?.nome });
      setRanges(value)
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoaded(false)
  }

  const editCoverOption = async (name: string) => {
    setIsLoaded(true)
    try {
      const { data: { value } } = await getCovers({ nome: name });
      setSelectedCover(name)
      setRanges(value)
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    } finally {
      setIsCoverOptionsDisabled(false)
    }
    setIsLoaded(false)
  }

  useEffect(() => {
    if (selectedCover && !isCoverOptionsDisabled) {
      rangeInput.current.focus()
      setIsCoverOptionsOpened(true)
    }
  }, [isCoverOptionsDisabled])

  const fetchUsers = async () => {
    setIsLoaded(true)
    try {
      const { data: { value } } = await API.get<any>('Usuario/App');
      setUsers(value)
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoaded(false)
  }

  const changeVehicleStatus = async (id: string) => {
    setIsLoaded(true)
    try {
      await activateDeactivateVehicle(id)
      snackbar(setAlert, {
        type: 'success',
        message: 'Ação realizada com sucesso!'
      });
      fetchVehicles()
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoaded(false)
  }

  const updateVehicle = async (type: 'EDIT' | 'TRANSFER') => {
    setIsLoaded(true)
    try {
      switch (type) {
        case 'EDIT':
          await putVehicle(vehicle, 'EDIT');
          break;
        case 'TRANSFER':
          await putVehicle(vehicle, 'TRANSFER', newUser?.id);
          break;
      }
      type === 'EDIT' ? setShowModalUpdateVehicle(false) : setShowModalTransfer(false)
      snackbar(setAlert, {
        type: 'success',
        message: 'Veículo salvo com sucesso'
      });
      fetchVehicles()
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoaded(false)
  }

  /* useEffect(() => {
    fetchUsers();
  }, [pagination]); */

  /* const fetchTermsOfUse = async () => {
    getUsers(
      pagination.page + 1,
      pagination.limit)
      .then(({ data }) => {
        console.log(data);
        
        data.value.forEach((element: any, index: number) => {
          element.deletado = element.deletado === true ? 'Inativo' : 'Ativo';
        });
        setUsers([
          ...new Array(pagination.page * pagination.limit),
          ...data.value,
        ]);
        setTotal(data.total);
      })
      .catch((error) =>
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message || 'Erro ao listar o usuários',
        }),
      );
  }; */

  /* const deleteMultiple = async (usersId: any) => {
    try {
      await API.post('/users/deactivate-multiple', { ids: usersId });
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });

      return window.location.reload();
      // fetchUsers();
    } catch (ex: any) {
      snackbar(setAlert, { type: 'error', message: ex?.response?.data?.message });
    }
     finally {
       setIsLoaded(true);
     }
  }; */

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  /* const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};
      const { data } = await API.get<any>(`/users`, params);
      getUsers(
        pagination.page + 1,
        pagination.limit,
        params
      ).then(({ data }) => {
        console.log(data);

        setTotal(data.total);
        searchText[0] ? onChangeRowsPerPage(total) : onChangeRowsPerPage(10)
        // setUsers(users => users.concat(data.data));
      })
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message: err?.response?.data?.message,
      });
    }
  }; */

  const formatSituacaoValue = () => {
    return {
      label: situations?.find(
        e => e?.value === vehicle?.situacao
      )?.label,
      value: vehicle?.situacao
    }
  }

  const updateVehicleCover = (option: any) => {
    const newCoverOption = [option]
    const newCovers = vehicle?.veiculoCobertura.map(cover => {
      return cover?.nome === selectedCover
        ? { ...cover, coberturaOpcao: newCoverOption }
        : cover
    })
    setSelectedCoverOption(null)
    setSelectedCover('')
    setIsCoverOptionsOpened(false)
    setIsCoverOptionsDisabled(true)
    setVehicle({ ...vehicle, veiculoCobertura: newCovers })
  }

  const closeCoverOptionsRequest = (reason: string) => {
    if (reason === 'blur') {
      setSelectedCoverOption(null)
      setSelectedCover('')
      setIsCoverOptionsOpened(false)
      setIsCoverOptionsDisabled(true)
    }
  }

  return (
    <>
      <Grid container>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Veículos
        </Typography>
        {/* {<Button 
          style={{ position: 'absolute', top: 65, right: 15, color: '#fff', backgroundColor: '#30235d', borderRadius: 10, padding: 6}}
          onClick={() => setShowModalNewTerm(true)} 
        >
            Novo Termo
  </Button>} */}
        <Container>
          <Grid item xs={12}>
            <Grid style={{ padding: "10px" }}>
              <VehiclesTable
                title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Consultar Veículos</h3>}
                columns={columns}
                data={vehicles}
                /* onSearchChange={(event: any) => {
                  return debounce(onSearchChange, 700)(event);
                }} */
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                currentPage={pagination.page}
                totalRecords={total}
                rowsPerPage={rowsPerPage}
              /* onRowsDelete={(indexRole) => {
                console.log("index: ", indexRole);
                const usersId = users
                  .filter((_: any, i: any) => indexRole.find((x) => x.index === i))
                  .map((id: any) => id.id);
                  console.log('users ids: ', usersId)
                deleteMultiple(usersId);
              }} */
              />
            </Grid>
          </Grid>
          <Modal
            open={showModalTransfer}
            modalWidth={'700px'}
            handleConfirm={() => updateVehicle('TRANSFER')}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={() => setShowModalTransfer(false)}
          >
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Loop htmlColor='#ffb812' />
                <span style={{ fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>&nbsp;TRANSFERIR VEÍCULO</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30 }}>
                <div style={{ width: '31%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>PLACA</p>
                  <TextField
                    variant='outlined'
                    placeholder='TCR3564'
                    style={{ backgroundColor: '#eeeef3' }}
                    value={vehicle?.placa}
                    disabled
                  />
                </div>
                <div style={{ width: '31%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>MODELO</p>
                  <TextField
                    placeholder='COROLLA'
                    style={{ backgroundColor: '#eeeef3' }}
                    value={vehicle?.modeloNome}
                    variant='outlined'
                    disabled
                  />
                </div>
                <div style={{ width: '31%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>MARCA</p>
                  <TextField
                    placeholder='TOYOTA'
                    style={{ backgroundColor: '#eeeef3' }}
                    value={vehicle?.marcaNome}
                    variant='outlined'
                    disabled
                  />
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>PROPRIETÁRIO ANTIGO</p>
                <TextField
                  variant='outlined'
                  placeholder='NOME COMPLETO'
                  style={{ backgroundColor: '#eeeef3' }}
                  value={vehicle?.usuarioNome}
                  disabled
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>NOVO PROPRIETÁRIO</p>
                <Autocomplete
                  disablePortal
                  options={users}
                  getOptionLabel={(option: any) => option?.nome}
                  onChange={(event: any, option: any): void => {
                    setNewUser(option)
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </div>
            </div>
          </Modal>
          <Modal
            open={showModalUpdateVehicle}
            modalWidth={'700px'}
            handleConfirm={() => updateVehicle('EDIT')}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={() => setShowModalUpdateVehicle(false)}
          >
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DirectionsCar htmlColor='#ffb812' />
                <span style={{ fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>&nbsp;EDITAR VEÍCULO</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30 }}>
                <div style={{ width: '31%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>PLACA</p>
                  <TextField
                    variant='outlined'
                    placeholder='TCR3564'
                    value={vehicle?.placa}
                    onChange={({ target: { value } }): void =>
                      setVehicle({ ...vehicle, placa: value })
                    }
                  />
                </div>
                <div style={{ width: '31%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>MODELO</p>
                  <TextField
                    placeholder='COROLLA'
                    variant='outlined'
                    value={vehicle?.modeloNome}
                    onChange={({ target: { value } }): void =>
                      setVehicle({ ...vehicle, modeloNome: value })
                    }
                  />
                </div>
                <div style={{ width: '31%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>MARCA</p>
                  <Autocomplete
                    disablePortal
                    options={brands}
                    getOptionLabel={(option: any) => option?.nome}
                    value={!vehicle?.marcaNome ? null : vehicle?.marca}
                    onChange={(event: any, option: any): void => {
                      setVehicle({ ...vehicle, marcaId: option?.id, marcaNome: option?.nome })
                    }}
                    isOptionEqualToValue={(option: any, value: any) => option?.nome === value?.nome}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                <div style={{ width: '31%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>CHASSI</p>
                  <TextField
                    variant='outlined'
                    placeholder='5W12341542E5812R45'
                    value={vehicle?.chassi}
                    onChange={({ target: { value } }): void =>
                      setVehicle({ ...vehicle, chassi: value })
                    }
                  />
                </div>
                <div style={{ width: '31%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>RENAVAM</p>
                  <TextField
                    placeholder='005462315795'
                    variant='outlined'
                    value={vehicle?.renavam}
                    onChange={({ target: { value } }): void =>
                      setVehicle({ ...vehicle, renavam: value })
                    }
                  />
                </div>
                <div style={{ width: '31%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>ANO DE FABRICAÇÃO</p>
                  <TextField
                    placeholder={`${new Date().getFullYear()}`}
                    variant='outlined'
                    value={vehicle?.modeloAno}
                    onChange={({ target: { value } }): void =>
                      setVehicle({ ...vehicle, modeloAno: value })
                    }
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                <div style={{ width: '50%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>COR</p>
                  <Autocomplete
                    disablePortal
                    options={colours}
                    getOptionLabel={(option: any) => option?.nome}
                    value={!vehicle?.corNome ? null : vehicle?.cor}
                    onChange={(event: any, option: any): void => {
                      setVehicle({ ...vehicle, corId: option?.id, corNome: option?.nome })
                    }}
                    isOptionEqualToValue={(option: any, value: any) => option?.nome === value?.nome}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    style={{ marginRight: 11 }}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>SITUAÇÃO</p>
                  <Autocomplete
                    disablePortal
                    options={situations}
                    getOptionLabel={(option: any) => option?.label}
                    value={!vehicle?.situacao ? null : formatSituacaoValue()}
                    onChange={(event: any, option: any): void => {
                      setVehicle({ ...vehicle, situacao: option?.value })
                    }}
                    isOptionEqualToValue={(option: any, value: any) => option?.label === value?.label}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    style={{ marginLeft: 11 }}
                  />
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <p style={{ color: '#8c8c8c', fontWeight: 'bold' }}>PROPRIETÁRIO</p>
                <TextField
                  placeholder='PROPRIETÁRIO'
                  variant='outlined'
                  style={{ backgroundColor: '#eeeef3' }}
                  value={vehicle?.usuarioNome}
                  disabled
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>COBERTURAS</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                  <Autocomplete
                    disablePortal
                    disabled={isCoverOptionsDisabled}
                    options={covers}
                    getOptionLabel={(option: any) => option?.nome}
                    value={!selectedCover ? null : { nome: selectedCover }}
                    onChange={(event: any, option: any): void => {
                      fetchRangeCovers(option)
                    }}
                    isOptionEqualToValue={(option: any, value: any) => option?.nome === value?.nome}
                    style={{ width: '50%', marginRight: 11 }}
                    renderInput={(params) => <TextField {...params} label="Nome" variant="standard" />}
                  />
                  <Autocomplete
                    disablePortal
                    open={isCoverOptionsOpened}
                    onClose={(event: any, reason: string) => closeCoverOptionsRequest(reason)}
                    disabled={isCoverOptionsDisabled}
                    options={ranges[0]?.coberturaOpcao}
                    getOptionLabel={(option: any) => option?.opcao}
                    value={!selectedCover ? null : selectedCoverOption}
                    onChange={(event: any, option: any): void => {
                      updateVehicleCover(option)
                    }}
                    isOptionEqualToValue={(option: any, value: any) => option?.opcao === value?.opcao || option?.opcao === selectedCoverOption?.opcao}
                    style={{ width: '50%', marginLeft: 11 }}
                    renderInput={(params) => <TextField {...params} label="Alcance" variant="standard" inputRef={rangeInput} />}
                  />
                </div>
                <VehiclesTable
                  selectableRows="none"
                  data={vehicle?.veiculoCobertura}
                  columns={coversColumns}
                />
              </div>
            </div>
          </Modal>
          {/* {<Modal 
            open={showModalDel}
            modalWidth={'400px'}
            handleConfirm={() => setShowModalDel(false)}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'EXCLUIR'}
            handleClose={() => setShowModalDel(false)}
          >
            <div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <span style={{fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>EXCLUIR TERMO</span>
              </div>
              <div style={{ fontSize: 18, color: '#8c8c8c', fontWeight: 'bold', marginTop: 30}}>Tem certeza que deseja excluir este termo?</div>
            </div>
          </Modal>} */}
        </Container>
      </Grid>
    </>
  );
};

export default Vehicles;
