import API from './services';

interface UserRequestParams {
  search?: ISearch;
  empresaId?: string;
  name?: string;
  username?: string;
  cpf?: string;
  take?: number;
  page?: number;
  offset?: number;
  sortingProp?: string;
  ascending?: boolean;
  customerId?: string;
}

interface ISearch {
  value?: string;
  fields: string[]
}

const getUsers = (params?: UserRequestParams) => {
  return API.get<any>('/users', { params });
};

const getClients = (page?: number, limit?: number, params?: {}) => {
  return API.get<any>(`/users?sorters=company.name&search[value]=3&search[fields]=roles.id&page=${(page)}&limit=${limit}`, params);
};
const getClientsExternal = (page?: number, limit?: number, params?: UserRequestParams) => {
  return API.get<any>(`/users?sorters=name&page=${(page)}&limit=${limit}&status=accepted&status=pending&status=declined`, { params });
};

const getClientById = async (id: number) => {
  const { data } = await API.get<any>(`/users/${id}`);
  return data;
};

const getSellers = async (params?: UserRequestParams) => {
  const { data } = await API.get<any>(`/users?search[value]=Vendedor&search[fields]=roles.name`, { params });
  return data;
};

const getBuyers = (params?: UserRequestParams) => {
  return API.get<any>(`/buyers?sorters=name&customerId=${params?.customerId}`);
};

const getProviders = (params?: UserRequestParams) => {
  return API.get<any>(`/providers?sorters=name&customerId=${params?.customerId}`);
};

const getPartners = (params?: UserRequestParams) => {
  return API.get<any>(`/partners?sorters=name&customerId=${params?.customerId}`);
};

const getBankReferences = (params?: UserRequestParams) => {
  return API.get<any>(`/bank-references?sorters=bank&customerId=${params?.customerId}`);
};

const getRevenues = (params?: UserRequestParams) => {
  return API.get<any>(`/revenues?sorters=period&customerId=${params?.customerId}`);
};

const updateClient = async (user: any) => {
  const data = await API.patch(`/users/${user.user.id}`, user.user);
  return data;
};

const acceptCustomer = (id: number, body: any) => {
  return API.post(`users/${id}/accept`, body)
}

export { getUsers, getClients, acceptCustomer, getClientsExternal, getClientById, getSellers, getBuyers, getProviders, getPartners, getBankReferences, getRevenues };
