import { Button, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React, { useState } from 'react';
import InputText from '../../../../components/shared/inputs/InputText';
import { IAccessData } from '../../LoginModel';
import '../../LoginPage.css';
import InputMask from 'react-input-mask';
import { validateEmail, validateCpf } from '../../../../utils/util';


interface IData {
    throwAboutCompany?: () => void;
    setAccessData: React.Dispatch<React.SetStateAction<IAccessData>>;
    accessData: IAccessData;
};
interface IAccessDataErrors {
    name: Error;
    email: Error;
    cpf: Error;
}

interface Error {
    value: boolean;
    text: string;
}

const AccessData = ({ throwAboutCompany, setAccessData, accessData }: IData) => {
    const [errors, setErrors] = useState<IAccessDataErrors>({
        name: { value: false, text: '' },
        email: { value: false, text: '' },
        cpf: { value: false, text: '' }
    })

    const handleContinue = () => {
        setErrors({
            name: { value: false, text: '' },
            email: { value: false, text: '' },
            cpf: { value: false, text: '' }
        });

        const isNameInvalid = accessData.name === '';
        const isEmailInvalid = !validateEmail(accessData.email);
        const isCpfInvalid = validateCpf(accessData.cpf);

        if (isNameInvalid) setErrors(prev => ({ ...prev, name: { value: true, text: 'Campo obrigatório*' } }));
        if (isEmailInvalid) setErrors(prev => ({ ...prev, email: { value: true, text: 'Email inválido*' } }));
        if (isCpfInvalid) setErrors(prev => ({ ...prev, cpf: { value: true, text: 'Campo obrigatório*' } }));

        if (isNameInvalid || isEmailInvalid || isCpfInvalid ) return;

        if (throwAboutCompany) throwAboutCompany()
    };

    return (
        <FormControl fullWidth>
            <InputText type="text" required fullWidth value={accessData.name} name="name" title={'Nome*'} variant="outlined"
                onChange={(event) => setAccessData(prev => ({ ...prev, name: event.target.value }))}
                error={errors.name.value}
                helperText={errors.name.text}
            />
            <InputText type="email" required fullWidth value={accessData.email} name="email" title={'Email*'} variant="outlined"
                onChange={(event) => setAccessData(prev => ({ ...prev, email: event.target.value }))}
                error={errors.email.value}
                helperText={errors.email.text}
            />
            <Typography className="subtitle" style={{ fontSize: "small" }} >
                CPF*
            </Typography>
            <InputMask
                mask="999.999.999-99"
                value={accessData.cpf}
                onChange={(event) => setAccessData(prev => ({ ...prev, cpf: event.target.value }))}
                required
                placeholder=""
                name="cpf"
                title={'CPF'}
                style={{borderColor: "#fff000"}}
            >
                {(inputProps: any) => <TextField {...inputProps} type="text" variant="outlined" error={errors.cpf.value}
                helperText={errors.cpf.text} fullWidth size="small" />}
            </InputMask>

            <Grid item style={{ textAlign: "end", marginTop: "20px" }}>
                <Button
                    onClick={handleContinue}
                    className="button buttonContainedRounded CTA buttonContainedColorPrimary"
                >
                    Continuar
                    <ChevronRight fontSize="small" />
                </Button>
            </Grid>
        </FormControl>
    )

};

export default AccessData;