import React, { useState } from 'react';
import {
    Grid,
    Button,
    Typography,
    TextField,
    Select,
    MenuItem,
} from '@material-ui/core';
import '../../LoginPage.css';
import { ChevronLeft, ChevronRight, } from '@material-ui/icons';
import InputText from '../../../../components/shared/inputs/InputText';
import { Autocomplete } from '@material-ui/lab';
import { ICompanyAddress } from '../../LoginModel';
import { validateCep } from '../../../../utils/util';
import InputMask from 'react-input-mask';

interface IAdress {
    optionsComplete: { title: string }[];
    throwAboutCompany?: () => void;
    throwContact?: () => void;
    setAddress: React.Dispatch<React.SetStateAction<ICompanyAddress>>;
    address: ICompanyAddress;
}
interface IAddressErrors {
    cep: Error;
}

interface Error {
    value: boolean;
    text: string;
}

const Adress = ({ optionsComplete, throwAboutCompany, throwContact, setAddress, address }: IAdress) => {
    const [errors, setErrors] = useState<IAddressErrors>({
        cep: { value: false, text: '' },
    })

    const handleContinue = () => {
        setErrors({
            cep: { value: false, text: '' },
        });

        const isCepInvalid = address.cep !== '' && !validateCep(address.cep);

        if (isCepInvalid) setErrors(prev => ({ ...prev, cep: { value: true, text: 'CEP inválido*' } }));

        if (isCepInvalid) return;

        if (throwContact) throwContact()
    };

    return (<>
        <InputText title={'Endereço'} variant="outlined"
            value={address.address}
            onChange={(event) => setAddress(prev => ({ ...prev, address: event.target.value }))}
        />

        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
                <InputText title={'Bairro'}
                    value={address.neighborhood}
                    onChange={(event) => setAddress(prev => ({ ...prev, neighborhood: event.target.value }))}
                    variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <InputText
                    value={address.city}
                    onChange={(event) => setAddress(prev => ({ ...prev, city: event.target.value }))}
                    title={'Cidade'} fullWidth variant="outlined" />
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4}>
                <InputText title={'Estado'} variant="outlined"
                    value={address.state}
                    onChange={(event) => setAddress(prev => ({ ...prev, state: event.target.value }))}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>

                <Typography className="subtitle" style={{ fontSize: "small" }} >
                    CEP
                </Typography>
                <InputMask
                    mask="99999-999"
                    value={address.cep}
                    onChange={(event) => setAddress(prev => ({ ...prev, cep: event.target.value }))}
                    required
                    placeholder=""
                    name="cep"
                    title={'CEP'}
                >
                    {(inputProps: any) => <TextField {...inputProps} helperText={errors.cep.text} error={errors.cep.value} type="text" variant="outlined" fullWidth size="small" />}
                </InputMask>
            </Grid>
            <Grid item md={4}>
                <Typography className="subtitle" style={{ fontSize: "small" }} >
                    Tipo
                </Typography>
                <Grid item xs={12}>
                <TextField
                    select
                    variant="outlined"
                    size="small"
                    value={address.type}
                    onChange={(event) => setAddress(prev => ({ ...prev, type: event.target.value }))}
                  >
                    <MenuItem value={"Comercial"}>Comercial</MenuItem>
                    <MenuItem value={"Residencial"}>Residencial</MenuItem>
                  </TextField>
                  </Grid>
                {/* <Autocomplete
                    id="combo-box-demo"
                    options={optionsComplete}
                    size="small"
                    getOptionLabel={(option) => option.title}
                    renderInput={
                        (params) => <TextField {...params}  variant="outlined"  />}
                /> */}

            </Grid>
        </Grid>

        <Grid container justifyContent="space-between" style={{ marginTop: "20px" }}>
            <Button
                onClick={throwAboutCompany}
                className="button buttonContainedRounded buttonContainedColorSecondary"
            >
                <ChevronLeft fontSize="small" />
                Sobre a empresa
            </Button>
            <Button
                onClick={handleContinue}
                className="button buttonContainedRounded CTA buttonContainedColorPrimary"
            >
                Continuar
                <ChevronRight fontSize="small" />
            </Button>
        </Grid>
    </>)
}

export default Adress;