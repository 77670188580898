import React, { useState, Dispatch, SetStateAction } from 'react';

export type AuthContextDefaultValueType = {
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
  roles: string;
  setRoles: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  permissions: string;
  setPermissions: Dispatch<SetStateAction<string>>;
};

const defaultContextValue: AuthContextDefaultValueType = {
  token: localStorage.getItem('jwtSecurityToken') || '',
  setToken: () => localStorage.getItem('jwtSecurityToken') || '',
  roles: localStorage.getItem('roles') || '',
  setRoles: () => localStorage.getItem('roles') || '',
  email: localStorage.getItem('email') || '',
  setEmail: () => localStorage.getItem('email') || '',
  permissions: localStorage.getItem('permissions') || '',
  setPermissions: () => localStorage.getItem('permissions') || '',
};

export const AuthContext = React.createContext(defaultContextValue);

const AuthProvider = ({ children }: any): JSX.Element => {
  const [token, setToken] = useState(localStorage.getItem('jwtSecurityToken') || '');
  const [roles, setRoles] = useState(localStorage.getItem('roles') || '');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [permissions, setPermissions] = useState(localStorage.getItem('permissions') || '');

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        roles,
        setRoles,
        email,
        setEmail,
        permissions,
        setPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
