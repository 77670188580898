import React from 'react';

import logoGrande from '../../../assets/pngs/logoLogin.png';
import logoPequena from '../../../assets/bird_logo.png';

interface LogoGrandeProps {
  width?: string;
  height?: string;
}

export const LogoGrande = (
  commomProps: LogoGrandeProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
): JSX.Element => {
  const { height } = commomProps;
  return (
    <div {...commomProps}>
      <img height={height || 40} src={logoGrande} alt="Logo da Novo Seguros" />
    </div>
  );
};

export const LogoPequena = (
  commomProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
): JSX.Element => (
  <div {...commomProps}>
    <img width="1" src={logoPequena} alt="Logo Officell " />
  </div >
);
