import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { PlaylistAddCheck } from '@material-ui/icons';
import { Autocomplete } from '@mui/material';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import Modal from '../../../components/shared/Modal/ModalSales';
import API from '../../../services/services';
import { IMaskInput } from 'react-imask';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    type: string;
}

const getRawValue = (value: string) => {
    return value.replace(/\D/g, '');
}

const containsSpecialChars = (value: string) => {
    const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(value);
}

const DescriptionMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props: any, ref: any) {
        const { onChange, type, ...other } = props;

        const mask = other?.value[5] === '9' ? '(#0) 00000-0000' : '(#0) 0000-0000'

        return (
            <IMaskInput
                {...other}
                mask={type === 'Email' ? String : mask}
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);


interface ICreateRoleModal {
    setOpen: any;
    open: boolean;
    refresh: () => void;
}

const CreateAttendanceModal = (props: ICreateRoleModal): JSX.Element => {
    const { open, setOpen, refresh } = props;
    const { setAlert } = useContext(AlertContext);
    const [roleName, setRoleName] = useState('');

    const [name, setName] = useState('');
    const [typeAtt, setTypeAtt] = useState('');
    const [description, setDescription] = useState('')
    const [message, setMessage] = useState('')

    const [nameError, setNameError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [messageError, setMessageError] = useState(false);

    const types = [
        'Email',
        'WhatsApp',
        'Telefone'
    ]

    function validation() {
        setNameError(!name);
        setTypeError(!typeAtt);
        setDescriptionError(!description);
        setMessageError(!message);

        return (
            !name || !typeAtt || !description || !message
        );
    }

    useEffect(() => {

        if (!open && nameError || typeError || descriptionError || messageError) {
            setNameError(false);
            setTypeError(false);
            setDescriptionError(false);
            setMessageError(false);
        }

    }, [open])

    function cleanFields() {
        setName('');
        setTypeAtt('');
        setDescription('');
        setMessage('');
    }

    const saveAttendance = async () => {
        if (typeAtt === 'Email' && containsSpecialChars(description[0])) {
            snackbar(setAlert, {
                type: 'warning',
                message: "O primeiro caractere deve ser obrigatóriamente uma letra ou um número",
                timeToClose: 5000
            })
            return;
        }

        if (validation()) {
            return;
        }

        try {
            //console.log('aqui: ', { pergunta: question, resposta: answer });
            const response = await API.post('/Atendimento', {
                nome: name,
                tipo: typeAtt,
                descricao: description,
                mensagem: message
            })
            //const response = await createRole({ name: roleName, permissionsIds: [] });
            snackbar(setAlert, { type: 'success', message: "Atendimento salvo com sucesso!" })
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });

        }
        cleanFields();
        setOpen(false);
        refresh();
    }
    return (
        <Modal
            open={open}
            modalWidth={'700px'}
            handleConfirm={saveAttendance}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={setOpen}
        >
            <Grid container spacing={2}>
                <Grid container alignItems="center" style={{ fontWeight: 'bold', color: '#30235d' }}>
                    <PlaylistAddCheck style={{ color: 'orange' }} />

                    &nbsp;&nbsp;CADASTRAR ATENDIMENTO
                </Grid>
                <Grid item xs={6}>
                    <Typography className="subtitle">NOME</Typography>
                    <TextField
                        variant="outlined"
                        error={nameError}
                        helperText={nameError && 'Campo Obrigatório!'}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setName(value)
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className="subtitle">TIPO</Typography>
                    <Autocomplete
                        disablePortal
                        options={types}
                        getOptionLabel={(option: any) => option}
                        onChange={(event: any, option: any): void => {
                            setTypeAtt(option)
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">DESCRIÇÃO</Typography>
                    <TextField
                        variant="outlined"
                        disabled={!typeAtt}
                        placeholder={!typeAtt ? 'Selecione o tipo de atendimento' : ''}
                        error={descriptionError}
                        helperText={descriptionError && 'Campo Obrigatório!'}
                        required={true}
                        value={description}
                        onChange={({ target: { value } }): void =>
                            setDescription(value)
                        }
                        InputProps={{
                            inputComponent: DescriptionMaskCustom as any,
                            type: typeAtt
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">MENSAGEM</Typography>
                    <TextField
                        variant="outlined"
                        error={descriptionError}
                        helperText={descriptionError && 'Campo Obrigatório!'}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setMessage(value)
                        }
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CreateAttendanceModal;
