import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { PlaylistAddCheck, Close } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { createRole } from '../../../services/role-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import { Checkbox, dividerClasses, FormControlLabel } from '@mui/material';
import Modal from '../../../components/shared/Modal/ModalSales';
import API from '../../../services/services';

interface IShowCoverage {
    setOpen: any;
    open: boolean;
    coverageSelected: any;
}
const ShowCoverage = (props: IShowCoverage): JSX.Element => {
    const { open, setOpen, coverageSelected } = props;
    const { setAlert } = useContext(AlertContext);

    const [order, setOrder] = useState('');
    const [name, setName] = useState('');
    const [options, setOptions] = useState<any[]>([]);

      useEffect(() => {

        function initValues(){
            setOrder(String(coverageSelected[1]));
            setName(coverageSelected[2]);
            setOptions(coverageSelected[3].map((op: any) => op?.opcao));   
        }

        if(open){
            initValues();
        }


    },[open])

    const closeModal = () => {

        setOpen(false);
    }

    return (
        <Modal 
        open={open}
        modalWidth={'700px'}
        handleClose={setOpen}
      >
        <div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <PlaylistAddCheck htmlColor='#ffb812'/>
            <span style={{fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>&nbsp;DETALHES DA COBERTURA</span>
            <div style={{marginLeft: 420, cursor: 'pointer'}} onClick={closeModal}>
                <Close  />
            </div>
          </div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop: 30}}>
              <div style={{ width:'48%'}}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>ORDEM</p>
                  <div style={{
                            fontSize: 16,
                            backgroundColor: '#eeeef3',
                            height: 50,
                            padding: 10,
                            borderRadius: 10
                        }}
                    >
                    {order}
                  </div>
              </div>
              <div style={{ width:'48%'}}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>COBERTURA</p>
                  <div style={{
                            fontSize: 16,
                            backgroundColor: '#eeeef3',
                            height: 50,
                            padding: 10,
                            borderRadius: 10
                        }}
                    >
                    {name}
                  </div>
              </div>
          </div>
          <div style={{ marginTop:20}}>
            <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>VALORES</p>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                {options.map((opcao: any) => <div key={opcao} style={{fontSize: 16,backgroundColor: '#eeeef3',height: 40,borderRadius: 20, padding: 10, marginRight: 10}}>
                        {opcao}
                        
                </div>)}
            </div>
            </div>
        </div>
      </Modal>

    );
};

export default ShowCoverage;
