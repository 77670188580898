import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { GroupAdd } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { createRole } from '../../../services/role-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import Modal from '../../../components/shared/Modal/ModalSales'
import API from '../../../services/services';

interface ICreateRoleModal {
    setOpen: any;
    open: boolean;
    refresh: () => void;
}
const CreateRoleModal = (props: ICreateRoleModal): JSX.Element => {
    const { open, setOpen, refresh } = props;
    const { setAlert } = useContext(AlertContext);
    const [roleName, setRoleName] = useState('');

    const [nameError, setNameError] = useState(false);

    function validation() {
      setNameError(!roleName);
      return (
        !roleName
      );
    }

    const saveRole = async () => {
        try {
            const response = await API.post('Grupo', {
                nome: roleName,
                moduloId: "D59C2F99-3462-4FF0-BD1F-85F1B86D3420",
                empresaId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                padrao: true
            });
            snackbar(setAlert, { type: 'success', message: "Grupo salvo com sucesso!" });
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });

        }
        
        setOpen(false);
        refresh();
    }
    return (
        <Modal 
            open={open}
            modalWidth={'700px'}
            handleConfirm={saveRole}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={setOpen}
        >
        <Grid container spacing={2}>
            <Grid container alignItems="center" style={{ fontWeight: 'bold' }}>
                     <GroupAdd style={{ color: 'orange' }} />

                     &nbsp;&nbsp;CRIAR GRUPO DE USUÁRIOS
                 </Grid>
                 <Grid item xs={12}>
                     <TextField
                        label="Nome do Grupo"
                        variant="outlined"
                        error={nameError}
                        helperText={nameError && 'Campo Obrigatório!'}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setRoleName(value)
                        }
                    />
                </Grid>
            </Grid>
          </Modal>
    );
};

export default CreateRoleModal;
