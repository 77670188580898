import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TextField, Button, Select, InputLabel, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Check, Close, Launch } from '@material-ui/icons';
import './ListClientExternal.css';

import ClientTable, { Column, Actions, StatusColumnSales } from '../../../components/shared/Table';
import Container from '../../../components/shared/Container';
import { Decision } from '../../../components/shared/Alert';
import { Client } from '../ClientModel';
import { AlertContext } from '../../../contexts/AlertContext';

import { snackbar, formatCpf, formatPhone } from '../../../utils/util';
import API from '../../../services/services';
import { getSellers, getClientsExternal, acceptCustomer } from '../../../services/client-service';
import LabelHeaderTable from '../../../components/shared/Label/LabelHeaderTable';
import nProgress from 'nprogress';
import ModalWithTransition from '../../../components/shared/Modal';
import moment from 'moment';

const ListClientExternal = () => {
  const routerHistory = useHistory();

  const [clients, setClients] = useState<Client[]>([]);
  const { setAlert } = useContext(AlertContext);

  const [pendingAnalysis, setPendingAnalysis] = useState(false);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showData, setShowData] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [showDecline, setShowDecline] = useState(false);
  const [pendingClients, setPendingClients] = useState(0);

  const [sellers, setSellers] = useState([] as any[]);
  const [user, setUser] = useState<any>({});

  const [sellerName, setSellerName] = useState('');
  //const [sellerId, setSellerId] = useState(0);

  const [seller, setSeller] = useState<any>({
    sellerId: 0,
  } as any);
  const [reasonText, setReasonText] = useState<any>({
    reason: '',
  } as any);

  const [idClient, setIdClient] = useState(0);

  async function loadClientsPending() {
    try {
      const response = await API.get<any>('/users?status=pending');
      if (response.data.total > 0) {
        setPendingClients(response.data.total);
      }
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message: error?.response?.data?.message
      });
    }
  }

  useEffect(() => {
    loadClientsPending();
  }, []);

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setClients([]);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({ ...old, page }));
  };

  const handleAcceptSubmit = async () => {

    try {
      console.log(seller);

      const { status } = await API.post(`users/${idClient}/accept`, seller);
      setShowAccept(false);
      snackbar(setAlert, {
        type: 'success',
        message: 'Ação realizada com sucesso',
      });
      fetchClients();
      loadClientsPending();
    } catch (error: any) {

      snackbar(setAlert, {
        type: 'error',
        message: error?.response?.data?.message
      })
    }
  }

  const handleDeclineSubmit = async () => {

    try {
      const { status } = await API.post(`users/${idClient}/decline`, reasonText);
      setShowDecline(false);
      snackbar(setAlert, {
        type: 'success',
        message: 'Ação realizada com sucesso',
      });
      fetchClients();
      loadClientsPending();
    } catch (error: any) {

      snackbar(setAlert, {
        type: 'error',
        message: error?.response?.data?.message
      })
    }
  }


  const columns: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'email',
      label: 'E-mail',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        customBodyRender: (value: string): JSX.Element => StatusColumnSales(value),
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#006193",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0] as number;
          return (
            <Actions
              buttonProps={[
                {
                  type: 'OTHER',
                  icon: <Check style={{ color: selectedRow.rowData[3] === "Pendente" ? 'green' : 'gray' }} />,
                  text: 'Aceitar',
                  onClick: (): void => {
                    setShowAccept(true);
                    setIdClient(id);
                    setSellerName("");
                    setSeller({ ...seller, sellerId: 0 });
                  },
                  disabled: (selectedRow.rowData[3] != "Pendente")
                },
                {
                  type: 'OTHER',
                  icon: <Close style={{ color: selectedRow.rowData[3] === "Pendente" ? 'red' : 'gray' }} />,
                  text: 'Rejeitar',
                  onClick: (): void => {
                    setShowDecline(true);
                    setIdClient(id);
                    setReasonText({ ...reasonText, reason: '' })
                  },
                  disabled: (selectedRow.rowData[3] != "Pendente")
                },
                {
                  type: 'OTHER',
                  icon: <Launch />,
                  text: 'Ver',
                  onClick: (): void => {
                    setShowData(true);
                    setIdClient(id);
                    setUser(clients.find(obj => obj?.id === selectedRow.rowData[0]))
                  },
                },
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchClients();
  }, [pagination]);

  const fetchClients = async () => {
    var page = 1;
    var lastPage = 1;
    while (lastPage >= page) {
      const { data } = await API.get<any>(
        `/users?page=${page}&limit=10&search[value]=Vendedor&search[fields]=roles.name`,
      );

      page++;
      lastPage = data.lastPage;

      setSellers(sellers => sellers.concat(data.data));
    }


    getClientsExternal(
      pagination.page + 1,
      pagination.limit,
    )
      .then(({ data }) => {

        data.data.forEach((client: any) => {
          if (client.cpf) {
            let cpfAtt = formatCpf(client.cpf)
            client.cpf = cpfAtt;
          }

          if (client.phone) {
            let phoneAtt = formatPhone(client.phone)
            client.phone = phoneAtt;
          }

          switch (client.status) {
            case 'accepted':
              client.status = "Aprovado";
              break;
            case 'pending':
              client.status = "Pendente";
              break;
            case 'declined':
              client.status = "Reprovado";
              break;
            default:
              break;
          }
        });

        setClients([
          ...new Array(pagination.page * pagination.limit),
          ...data.data,
        ]);

        setTotal(data.total);
      })
      .catch((error) =>
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message || 'Erro ao listar os clientes',
        }),
      );
  };

  const deleteMultiple = async (clientsId: any) => {
    try {
      await API.post('/users/delete-multiple', clientsId);
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });

      return window.location.reload();
    } catch (ex: any) {
      snackbar(setAlert, { type: 'error', message: 'Erro ao remover grupo de clientes' });
    }
    // finally {
    //   setIsLoaded(true);
    // }
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};
      const { data } = await API.get<any>(`/users?page=${Number(pagination.page + 1)}&limit=${Number(pagination.limit)}&status=accepted&status=pending&status=declined`, params);

      data.data.forEach((client: any) => {
        let cpfAtt = formatCpf(client.cpf)
        client.cpf = cpfAtt;

        if (client.phone) {
          let phoneAtt = formatPhone(client.phone)
          client.phone = phoneAtt;
        }
      });
      setClients([
        ...new Array(pagination.page * pagination.limit),
        ...data.data,
      ]);
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message: err?.response?.data?.message,
      });
    }
  };

  function maskCNPJ(cnpj: any|null ) {
    return cnpj ? cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : " - ";
  }
  

  function maskReal(valor?: any) {
    valor = isNaN(valor) || valor === null ? '0.00' : valor;
    return `R$ ${valor.toString().replace('.',',')}`
  }


  return (
    <>
      <ModalWithTransition open={showData} handleClose={setShowData} modalWidth={'300'}>
        <Grid container spacing={2}>

          <strong className="labels">DADOS PESSOAIS</strong>

          <Grid className="gridWidth">
            <div className="row">
              <span className="txtModal">{user.name}</span>
              <span className="txtModal">&nbsp;-&nbsp;CPF:&nbsp;</span>
              <span className="txtModal">{user.cpf}</span>
            </div>
            <span className="txtModal">{user.email}</span>
          </Grid>

          <Grid className="gridWidth">
            <div className="column">
              <strong className="fantasyName">{user.company && user.company.name}</strong>
              <span className="companyNameCpnj">{user.company && user.company.company_name} - {user.company && maskCNPJ(user.company.cnpj)}</span>
            </div>

            <p>
              <span className="labels">Ramo:&nbsp;</span>
              <span className="txtModal">{user.company && user.company.type_of_activity}</span>
            </p>

            <p className="labels">ENDEREÇO</p>
            <div className="column">
              <span className="txtModal">{user.company && user.company.address}</span>
              <span className="txtModal">{user.company && user.company.neighborhood}</span>
              <span className="txtModal">CEP: {user.company && user.company.cep}</span>
            </div>
          </Grid>

          <Grid className="gridWidth">
            <div className="row" style={{ justifyContent: "space-between" }}>
              <div className="column">
                <span className="labels">Ins. Estadual</span>
                <span className="txtModal">{user.company && user.company.state_registration}</span>
                <br />
                <span className="labels">Nº de Filiais</span>
                <span className="txtModal">{user.company && user.company.branches}</span>
              </div>
              <div className="column">
                <span className="labels">Nº de Funcionários</span>
                <span className="txtModal">{user.company && user.company.number_of_employees}</span>
                <span className="labels">Capital Social</span>
                <span className="txtModal">{user.company && maskReal(user.company.net_worth)}</span>
              </div>
              <div className="column">
                <span className="labels">Data de Fundação</span>
                <span className="txtModal">{user.company && moment(user.company.foundation_data).format('DD/MM/YYYY')}</span>
                <br />
              </div>
            </div>

          </Grid>

          <Grid style={{ width: '49%', marginRight: '1%', marginTop: '3%' }}>
            <div className="columnCard">
              <span className="financeOperationalName">{user.company && user.company.finance_name} <span className="txtModal">(financeiro)</span> </span>
              <span className="txtModal">{user.company && user.company.finance_email}</span>
              <span className="txtModal">{user.company && user.company.finance_phone}</span>
            </div>
          </Grid>
          <Grid style={{ width: '49%', marginLeft: '1%', marginTop: '3%' }}>
            <div className="columnCard">
              <span className="financeOperationalName">{user.company && user.company.operational_name} <span className="txtModal">(Operacional)</span></span>
              <span className="txtModal">{user.company && user.company.operational_email}</span>
              <span className="txtModal">{user.company && user.company.operational_phone}</span>
            </div>
          </Grid>
        </Grid>
      </ModalWithTransition>

      <ModalWithTransition open={showAccept} handleClose={setShowAccept} modalWidth={'300'}>
        <Grid container spacing={2}>
          <Grid container alignItems="center" style={{ fontWeight: 'bold', marginBottom: '30px', color: '#006193' }}>
            <Check style={{ color: 'green', marginRight: '10px' }} />
            Aprovar Cadastro
          </Grid>
          <Grid style={{ width: '100%' }}>
            <Typography>Vendedor Responsável</Typography>
            <Autocomplete
              id="autocomplete-permission"
              options={sellers}
              getOptionLabel={(option) => (option.name)}
              inputValue={sellerName}
              onChange={(event, value) => {
                setSellerName(value.name);
                setSeller({ ...seller, sellerId: value.id });
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    placeholder="Pesquisar vendedores..."
                    variant="outlined"
                    size='small'
                    autoComplete="off"
                    required
                  />
                );
              }}
            />
          </Grid>
          <Grid container xs={12} direction="row" justifyContent="space-between">
            <Button
              className="button buttonContainedRounded buttonContainedColorPrimary CTA"
              style={{ marginTop: '10px' }}
              onClick={(): void => { setShowAccept(false); }}
            >
              Cancelar
            </Button>
            <Button
              className="button buttonContainedRounded buttonAux CTA"
              style={{ marginTop: '10px' }}
              onClick={(): void => { handleAcceptSubmit() }}
            >
              Aprovar
            </Button>
          </Grid>
        </Grid>
      </ModalWithTransition>

      <ModalWithTransition open={showDecline} handleClose={setShowDecline} modalWidth={'300'}>
        <Grid container spacing={2}>
          <Grid container alignItems="center" style={{ fontWeight: 'bold', marginBottom: '30px', color: '#006193' }}>
            <Close style={{ color: 'red', marginRight: '10px' }} />
            REPROVAR CADASTRO
          </Grid>
          <Grid style={{ width: '100%' }}>
            <Typography>Motivo</Typography>
            <textarea
              placeholder="Digite o motivo"
              className="motivoInput" name="motivo"
              value={reasonText.reason}
              onChange={({ target: { value } }): void =>
                setReasonText({ ...reasonText, reason: value })
              }
            >

            </textarea>
          </Grid>
          <Grid container xs={12} direction="row" justifyContent="space-between">
            <Button
              onClick={(): void => {
                setShowDecline(false);
              }}
              className="button buttonContainedRounded buttonContainedColorPrimary CTA"
              style={{ marginTop: '10px' }}
            >
              Cancelar
            </Button>
            <Button
              className="button buttonContainedRounded buttonContainedColorDanger CTA"
              style={{ marginTop: '10px' }}
              onClick={(): void => { handleDeclineSubmit() }}

            >
              Reprovar
            </Button>
          </Grid>
        </Grid>
      </ModalWithTransition>

      <Grid container>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Clientes Externos
        </Typography>
        {pendingClients > 0 ? (<Grid style={{
          display: 'flex',
          backgroundColor: '#f3a72b',
          padding: 10,
          width: "100%",
          borderRadius: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <span style={{ fontSize: 16, color: '#fff' }}>{pendingClients === 1 ? 'Existe' : 'Existem'} {pendingClients} {pendingClients === 1 ? 'cadastro pendente' : 'cadastros pendentes'} de análise</span>
          < Close onClick={() => setPendingClients(0)} className="close" style={{ color: '#fff' }} />
        </Grid>) : null}
        <Container

        >


          <Grid item xs={12}>
            <Grid style={{ padding: "10px" }}>
              <ClientTable
                title={<h3 style={{ color: "#006193", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Cadastros Externos</h3>}
                columns={columns}
                data={clients}
                onSearchChange={(event: any) => { return debounce(onSearchChange, 700)(event); }}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                currentPage={pagination.page}
                totalRecords={total}
                rowsPerPage={rowsPerPage}
                onRowsDelete={(indexRole) => {
                  const clientsId = clients
                    .filter((_, i) => indexRole.find((x) => x.index === i))
                    .map((id) => id.id);
                  deleteMultiple(clientsId);
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default ListClientExternal;
