import React, { useState, useEffect, useContext } from 'react';
import PoliciesTable, { Column, Actions } from '../../components/shared/Table';
import { Button, Grid, TextField, Typography, InputAdornment } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import Container from '../../components/shared/Container';
//import { getUsers, activeUser } from '../../../services/user-service';
import { AlertContext } from '../../contexts/AlertContext';
import { snackbar } from '../../utils/util';
import { getPolicies, getPolicyById, postPolicy, putPolicy, getFile, deletePolicy } from '../../services/policies-service';
import LabelHeaderTable from '../../components/shared/Label/LabelHeaderTable';
import { DeleteForever, Launch, CloudDownload, Assignment, CloudUpload, PlaylistAddCheck, PictureAsPdf } from '@material-ui/icons';
import Modal from '../../components/shared/Modal/ModalSales'
import { fontStyle } from '@mui/system';
import { IPolicy } from '../../models/IPolicy';
import { getUsers } from '../../services/options-service';
import './styles.css';
import API from '../../services/services';
import { getVehicles } from '../../services/vehicle-service';

const Policies: React.FC = () => {
  const { setAlert } = useContext(AlertContext);
  const [id] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [policies, setPolicies] = useState<IPolicy[]>([])
  const [policy, setPolicy] = useState<IPolicy>({
    id: '',
    nomeArquivoApolice: '',
    arquivo: '',
    veiculoId: '',
    numeroApolice: '',
    usuarioId: '',
    usuarioNome: ''
  })
  const [users, setUsers] = useState<any>([]);
  const [selectedPolicyId, setSelectedPolicyId] = useState('');

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [showModalDel, setShowModalDel] = useState(false);
  const [showModalBind, setShowModalBind] = useState(false);
  const [showModalUpdatePolicy, setShowModalUpdatePolicy] = useState(false);

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setPolicies([]);
  };
  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page,
    }));
  };

  const columns: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: 'numeroApolice',
      label: 'Número',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'placa',
      label: 'Veículo',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'caminhoArquivoApolice',
      label: 'Arquivo',
      options: {
        display: 'false',
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0];
          return (
            <Actions
              buttonProps={[
                {
                  type: 'OTHER',
                  onClick: () => openRemovePolicy(id),
                  icon: <DeleteForever />,
                  text: 'Excluir',
                  id: id
                },
                {
                  type: 'OTHER',
                  onClick: () => fetchPolicyFile(id),
                  icon: <PictureAsPdf />,
                  text: 'Download de Apólice',
                  id: id
                },
                {
                  type: 'OTHER',
                  onClick: () => fetchPolicyById(id),
                  icon: <Launch />,
                  text: 'Editar',
                  id: id
                },

              ]}
            />
          );
        },
      },
    },
  ];

  const openRemovePolicy = (id: string) => {
    setShowModalDel(true)
    setSelectedPolicyId(id)
  }

  useEffect(() => {
    fetchPolicies();
    fetchUsers();
    
  }, [pagination]);

  const fetchPolicies = async () => {
    setIsLoading(true);
    try {
      const { data: { value } } = await getPolicies();
      
      value.forEach((element: any, index: number) => {
        element.placa = element.veiculo.placa;
      });
      setPolicies(value);
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
    }
    setIsLoading(false);

  }

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const { data: { value } } = await getVehicles();

      var array: any = [];
      value.forEach((element: any, index: number) => {
        array.push({id: element.id, placa: element.placa})
      });
      
      setUsers(value);
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
    }
    setIsLoading(false);
  }

  const fetchPolicyById = async (id: string) => {
    setShowModalUpdatePolicy(true)
    setIsLoading(true)
    try {

      var { data: { value } } = await getPolicyById(id);
      setPolicy({...value, placa: value.veiculo.placa});
      
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }finally{
      setIsLoading(false)

    }

  }

  const fetchPolicyFile = async (id: string) => {
    setIsLoading(true)
    try {
      const file = await getFile(id);
      const a = document.createElement('a');
      a.target = '_blank'
      a.href = file?.request?.responseURL
      a.download = file?.data
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoading(false)
  }

  const savePolicy = async (type: 'SAVE' | 'UPDATE') => {
    setIsLoading(true)
    try {
      var formData = new FormData();
      formData.append('numeroApolice', policy?.numeroApolice);
      formData.append('veiculoId', policy?.veiculoId);
      formData.append('arquivo', policy?.arquivo);

      switch (type) {
        case 'SAVE':
          await postPolicy(formData)
          setShowModalBind(false)
          break;
        case 'UPDATE':
          await putPolicy(formData, policy?.id)
          setShowModalUpdatePolicy(false)
          break;
      }
      snackbar(setAlert, { type: 'success', message: 'Ação realizada com sucesso' });
      fetchPolicies()
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
    }
    setIsLoading(false)
  }

  const removePolicy = async (id: string) => {
    setIsLoading(true)
    try {
      await deletePolicy(id)
      fetchPolicies()
      snackbar(setAlert, {
        type: 'success',
        message: 'Ação realizada com sucesso'
      });
      setShowModalDel(false)
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoading(false)
  }

  return (
    <>
      <Grid container>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Apólices
        </Typography>
        <Button
          style={{ position: 'absolute', top: 65, right: 15, color: '#fff', backgroundColor: '#30235d', borderRadius: 10, padding: 6 }}
          onClick={() => setShowModalBind(true)}
        >
          Vincular Apólices
        </Button>
        <Container>
          <Grid item xs={12}>
            <Grid style={{ padding: "10px" }}>
              <PoliciesTable
                title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Consultar Apólices</h3>}
                columns={columns}
                data={policies}
                //onSearchChange={(event: any) => {
                //return debounce(onSearchChange, 700)(event);
                //}}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                currentPage={pagination.page}
                totalRecords={total}
                rowsPerPage={rowsPerPage}
              // onRowsDelete={(indexRole) => {
              //   console.log("index: ", indexRole);
              //   const usersId = users
              //     .filter((_: any, i: any) => indexRole.find((x) => x.index === i))
              //     .map((id: any) => id.id);
              //     console.log('users ids: ', usersId)
              //   deleteMultiple(usersId);
              // }}
              />
            </Grid>
          </Grid>
          <Modal
            open={showModalBind}
            modalWidth={'700px'}
            handleConfirm={() => savePolicy('SAVE')}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={() => setShowModalBind(false)}
          >
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PlaylistAddCheck htmlColor='#ffb812' />
                <span style={{ fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>&nbsp;VINCULAR APÓLICE</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30 }}>
                <div style={{ width: '48%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>NÚMERO DA APÓLICE</p>
                  <TextField
                    variant='outlined'
                    onChange={({ target: { value } }): void =>
                      setPolicy({ ...policy, numeroApolice: value })
                    }
                  />
                </div>
                <div style={{ width: '48%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>VEÍCULO</p>
                  <Autocomplete
                    disablePortal
                    options={users}
                    getOptionLabel={(option: any) => option?.placa}
                    onChange={(event: any, option: any): void => {
                      setPolicy({ ...policy, veiculoId: option?.id})
                    }}
                    isOptionEqualToValue={(option: any, value: any) => option?.nome === value?.nome}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                </div>
              </div>
              <p style={{ marginTop: 20, color: '#8c8c8c', fontWeight: 'bold', }}>IMPORTAR APÓLICE</p>
              <input
                style={{ display: 'none' }}
                id="file-input"
                type="file"
                accept=".pdf"
                onChange={(event: any) => {
                  {
                    setPolicy({ ...policy, arquivo: event.target.files[0], nomeArquivoApolice: event.target.files[0]?.name });
                  }
                }}
              />
              <div style={{ display: 'flex' }}>
                <label htmlFor="file-input" style={{ display: 'flex' }}>
                  <Button
                    variant="outlined"
                    component="span"
                    style={{ fontSize: 14, borderWidth: 2, borderRadius: "4px", color: "#000000", borderColor: "#000000", width: '100%' }}
                  >
                    Escolher arquivo
                  </Button>
                  <span style={{
                    display: 'block',
                    width: 500,
                    overflow: 'hidden',
                    padding: 10,
                    whiteSpace: 'nowrap',
                    alignSelf: 'center',
                    textOverflow: 'ellipsis'
                  }}>
                    {policy?.nomeArquivoApolice ? policy?.nomeArquivoApolice : policy?.arquivo ? policy?.arquivo : 'Nenhum arquivo selecionado'}
                  </span>
                </label>
              </div>
            </div>
          </Modal>
          <Modal
            open={showModalUpdatePolicy}
            modalWidth={'700px'}
            handleConfirm={() => savePolicy('UPDATE')}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={() => setShowModalUpdatePolicy(false)}
          >
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PlaylistAddCheck htmlColor='#ffb812' />
                <span style={{ fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>&nbsp;EDITAR APÓLICE</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30 }}>
                <div style={{ width: '48%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>NÚMERO DA APÓLICE</p>
                  <TextField
                    variant='outlined'
                    value={policy?.numeroApolice}
                    onChange={({ target: { value } }): void =>
                      setPolicy({ ...policy, numeroApolice: value })
                    }
                  />
                </div>


                <div style={{ width: '48%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>VEÍCULO</p>
                  <Autocomplete
                    disablePortal
                    value={{id: policy?.veiculoId, placa: policy?.placa}}
                    options={users}
                    getOptionLabel={(option: any) => option?.placa || ""}
                    onChange={(event: any, option: any): void => {
                      
                      setPolicy({ ...policy, veiculoId: option?.id, placa: option?.placa})

                    }}
                    isOptionEqualToValue={(option: any, value: any) => option?.placa === value?.placa}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                </div>
              </div>
              <p style={{ marginTop: 20, color: '#8c8c8c', fontWeight: 'bold', }}>IMPORTAR APÓLICE</p>
              <input
                style={{ display: 'none' }}
                id="file-input"
                type="file"
                onChange={(event: any) => {
                  {
                    setPolicy({ ...policy, arquivo: event.target.files[0], nomeArquivoApolice: event.target.files[0]?.name });
                  }
                }}
              />
              <div style={{ display: 'flex' }}>
                <label htmlFor="file-input" style={{ display: 'flex' }}>
                  <Button
                    variant="outlined"
                    component="span"
                    style={{ fontSize: 14, borderWidth: 2, borderRadius: "4px", color: "#000000", borderColor: "#000000", width: '100%' }}
                  >
                    Escolher arquivo
                  </Button>
                  <span style={{
                    display: 'block',
                    width: 500,
                    overflow: 'hidden',
                    padding: 10,
                    whiteSpace: 'nowrap',
                    alignSelf: 'center',
                    textOverflow: 'ellipsis'
                  }}>
                    {policy?.nomeArquivoApolice ? policy?.nomeArquivoApolice : policy?.arquivo ? policy?.arquivo : 'Nenhum arquivo selecionado'}
                  </span>
                </label>
              </div>
            </div>
          </Modal>
          <Modal
            open={showModalDel}
            modalWidth={'400px'}
            handleConfirm={() => removePolicy(selectedPolicyId)}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'EXCLUIR'}
            handleClose={() => setShowModalDel(false)}
          >
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>EXCLUIR APÓLICE</span>
              </div>
              <div style={{ fontSize: 18, color: '#8c8c8c', fontWeight: 'bold', marginTop: 30 }}>Tem certeza que deseja excluir esta apólice?</div>
            </div>
          </Modal>
        </Container>
      </Grid>
    </>
  );
};

export default Policies;
