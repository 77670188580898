import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { PlaylistAddCheck, Close } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { createRole } from '../../../services/role-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import { Checkbox, dividerClasses, FormControlLabel } from '@mui/material';
import Modal from '../../../components/shared/Modal/ModalSales';
import { Loop }  from '@material-ui/icons';
import API from '../../../services/services';

interface IFinalizeQuotation {
    setOpen: any;
    open: boolean;
    //quotationSelected: any;
}
const FinalizeQuotation = (props: IFinalizeQuotation): JSX.Element => {
    const { open, setOpen } = props;
    const { setAlert } = useContext(AlertContext);
      useEffect(() => {

        function initValues(){

        }

        if(open){
            initValues();
        }


    },[open])

    const closeModal = () => {

        setOpen(false);
    }

    const finalize = async () => {
      try {
        const response = await API.get<any>('');
        console.log(response);
        snackbar(setAlert, { type: 'success', message: 'Cotação finalizada com sucesso!' });
      } catch (error: any) {
        snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
      }
    };

    return (
        <Modal 
            open={open}
            modalWidth={'400px'}
            handleConfirm={() => {}}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'FINALIZAR'}
            handleClose={setOpen}
          >
             <div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <span style={{fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>FINALIZAR COTAÇÃO</span>
              </div>
              <div style={{ fontSize: 18, color: '#8c8c8c', marginTop: 30}}>Deseja finalizar a cotação?</div>
            </div>
          </Modal>
    );
};

export default FinalizeQuotation;
