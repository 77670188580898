import API from './services';

export const getGroupPermissions = (id: string) => {
    return API.get<any>('/PermissaoGrupo/disponiveis', {
        grupoId: id
    });
}

export const getPermissionsByGroup = (id: string) => {
    return API.get<any>('/PermissaoGrupo', {
        grupoId: id
    });
}

export const postGroupPermissions = (payload: any) => {
    return API.post('/PermissaoGrupo', payload);
}

export const deleteGroupPermissions = (id: string, payload: any) => {
    return API.delete('/PermissaoGrupo', id, payload);
}