import React, { useState, useEffect, useContext } from 'react';
import UserTable, { Column, Actions } from '../../../components/shared/Table';
import { Grid, Typography } from '@material-ui/core';
import './ListUser.css';
import Container from '../../../components/shared/Container';
import { getUsers, activeUser } from '../../../services/user-service';
import { User } from '../UserModel';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import API from '../../../services/services';
import LabelHeaderTable from '../../../components/shared/Label/LabelHeaderTable';
import CreateUser from '../CreateUser/CreateUser';
import EditUser from '../EditUser/EditUser';

const ListUser = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [role, setRole] = useState<User[]>([]);

  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);

  const { setAlert } = useContext(AlertContext);
  const [userId, setUserId] = useState('');

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page,
    }));
  };

  const disableEnableUser = async (event: any, id: number) => {
    try {
      const { data } = await activeUser(id);

      if (data.updated) {
        snackbar(setAlert, {
          type: 'success',
          message: 'Ação realizada com sucesso'
        });
      }
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          err?.response?.data?.message
      });
    }

    fetchUsers();
  };

  const columns: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
      }
    },
    {
      name: 'login',
      label: 'Login',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
      }
    },
    {
      name: 'grupo',
      label: 'Grupo',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
      }
    },
    {
      name: 'telefone',
      label: 'Telefone',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
      }
    },
    {
      name: 'inativo',
      label: 'Ativo',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0];
          let activeColumn = selectedRow.rowData[6]
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DISABLE',
                  onClick: disableEnableUser,
                  id: id,
                  checked: !activeColumn,
                },
                {
                  type: 'EDITAR',
                  onClick: (): void => {
                    setShowEditUser(true);
                    setUserId(id);
                  },
                  disabled: !activeColumn
                },
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchUsers();
  }, [pagination]);

  const fetchUsers = async () => {
    getUsers(
      pagination.page + 1,
      pagination.limit)
      .then(({ data }) => {
        data.value.forEach((element: any, index: number) => {
          element.deletado = element.deletado === true ? 'Inativo' : 'Ativo';
        });

        data.value.forEach((element: any, index: number) => {
          element.grupo = element.grupoAspNetUsers[0]?.grupo.nome;
        });

        setUsers([
          ...new Array(pagination.page * pagination.limit),
          ...data.value
        ]);
        setTotal(data.count);
      })
      .catch((error) =>
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message || 'Erro ao listar o usuários',
        }),
      );
  };

  const deleteMultiple = async (usersId: any) => {
    try {
      await API.post('/users/deactivate-multiple', { ids: usersId });
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });

      //return window.location.reload();
      fetchUsers();
    } catch (ex: any) {
      snackbar(setAlert, { type: 'error', message: ex?.response?.data?.message });
    }
    // finally {
    //   setIsLoaded(true);
    // }
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};
      //const { data } = await API.get<any>(`/users`, params);
      getUsers(
        pagination.page + 1,
        pagination.limit,
        params
      ).then(({ data }) => {
        //console.log(data);

        setTotal(data.total);
        searchText[0] ? onChangeRowsPerPage(total) : onChangeRowsPerPage(10)
        //setUsers(users => users.concat(data.data));
      })
    } catch (err: any) {
      // snackbar(setAlert, {
      //   type: 'error',
      //   message: err?.response?.data?.message,
      // });
    }
  };

  return (
    <>
      <Grid container>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Usuários
        </Typography>
        <Container
          showButtonOnTop
          textButtonOnTop="Novo Usuário"
          onClickButtonTop={setShowCreateUser}
        >
          <Grid item xs={12}>
            <Grid style={{ padding: "10px" }}>
              <UserTable
                title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Usuários Cadastrados</h3>}
                columns={columns}
                data={users}
                onSearchChange={(event: any) => {
                  return debounce(onSearchChange, 700)(event);
                }}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                currentPage={pagination.page}
                totalRecords={total}
                rowsPerPage={rowsPerPage}
              />
            </Grid>
          </Grid>
        </Container>
        <CreateUser
          open={showCreateUser}
          setOpen={setShowCreateUser}
          refresh={() => {
              fetchUsers();
            }
          }
        />
        <EditUser
          open={showEditUser}
          setOpen={setShowEditUser}
          id={userId}
          refresh={() => {
            fetchUsers();
          }
          }
        />
      </Grid>
    </>
  );
};

export default ListUser;
