import React, { useState, useEffect, useContext } from 'react';
import InputMask from 'react-input-mask';
import { Grid, Typography, TextField, InputAdornment, Divider, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Decision } from '../../../../components/shared/Alert';
import BankReferencesTable, { Column, Actions } from '../../../../components/shared/Table';

import nProgress from 'nprogress';
import { snackbar } from '../../../../utils/util';
import { Client } from '../../ClientModel';

import { AlertContext } from '../../../../contexts/AlertContext';
import API from '../../../../services/services';
import { getBankReferences } from '../../../../services/client-service';
import LabelHeaderTable from '../../../../components/shared/Label/LabelHeaderTable';

const BankReference = (props: any): JSX.Element => {

  const { setAlert } = useContext(AlertContext);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [idBankReference, setIdBankReference] = useState(0);
  const [showDeleteDecisionAlert, setShowDeleteDecisionAlert] = useState(false);
  const [bankReferences, setBankReferences] = useState<Client[]>([]);
  const [editBankReference, setEditBankReference] = useState(false);

  const [bankBankReferenceError, setBankBankReferenceError] = useState(false);
  const [agencyBankReferenceError, setAgencyBankReferenceError] = useState(false);
  const [accountBankReferenceError, setAccountBankReferenceError] = useState(false);
  const [managerBankReferenceError, setManagerBankReferenceError] = useState(false);
  const [phoneBankReferenceError, setPhoneBankReferenceError] = useState(false);

  const [bankReference, setBankReference] = useState<any>({
    bank: '',
    agency: '',
    account: '',
    manager: '',
    phone: '',
    customerId: parseInt(props.id)
  } as any);

  const columnsBankReferences: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: { display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }), },
    },
    {
      name: 'bank',
      label: 'Banco',
      options:{
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'agency',
      label: 'Agência',
      options:{
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'account',
      label: 'Conta',
      options:{
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'manager',
      label: 'Gerente',
      options:{
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'phone',
      label: 'Telefone',
      options:{
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#006193",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0] as number;
          const bank = selectedRow.rowData[1];
          const agency = selectedRow.rowData[2] as number;
          const account = selectedRow.rowData[3];
          const manager = selectedRow.rowData[4];
          const phone = selectedRow.rowData[5];
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DELETAR',
                  onClick: (): void => {
                    setShowDeleteDecisionAlert(true);
                    setIdBankReference(id);
                  },
                },
                {
                  type: 'EDITAR',
                  onClick: (): void => {
                    setEditBankReference(true);
                    setIdBankReference(id);
                    bankReference.bank = bank
                    bankReference.agency = agency
                    bankReference.account = account
                    bankReference.manager = manager
                    bankReference.phone = phone
                  },
                },
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchBankReferences();
  }, [pagination]);

  const removeBankReference = async (decision: 'OK' | 'CANCEL', id: number) => {
    setShowDeleteDecisionAlert(false);
    nProgress.start();
    if (decision === 'OK') {
      const { data } = await API.delete('/bank-references', id);
      if (data?.deleted) {
        snackbar(setAlert, {
          type: 'success',
          message: 'Referência bancária removida com sucesso',
        });
        fetchBankReferences()
      } else {
        snackbar(setAlert, {
          type: 'error',
          message: 'Erro ao remover a referência bancária',
        });
      }
    }
    nProgress.done();
  };

  function validationBankReference() {
    setBankBankReferenceError(!bankReference.bank);
    setAgencyBankReferenceError(!bankReference.agency);
    setAccountBankReferenceError(!bankReference.account);
    setManagerBankReferenceError(!bankReference.manager);
    setPhoneBankReferenceError(!bankReference.phone);
    return (
      !!bankReference.bank &&
      !!bankReference.agency &&
      !!bankReference.account &&
      !!bankReference.manager &&
      !!bankReference.phone
    );
  }

  const saveBankReference = async () => {
    if (editBankReference) {
      try {
        
        
        const { status } = await API.patch(`/bank-references/${idBankReference}`, bankReference);
        if (status === 200) {
          setEditBankReference(false)
          fetchBankReferences();
          bankReference.bank = ''
          bankReference.agency = ''
          bankReference.account = ''
          bankReference.manager = ''
          bankReference.phone = ''
          snackbar(setAlert, {
            type: 'success',
            message: 'Referênca bancária atualizada com sucesso',
          })
        }
      } catch (err: any) {
        const message = err?.response?.data?.message[0] || 'Erro ao tentar atualizar a referênca bancária';
        snackbar(setAlert, {
          type: 'error',
          message
        })
      }
    } else {
      try {
        debugger
        console.log(bankReference);
        const { status } = await API.post(`/bank-references`, bankReference);
        if (status === 201) {
          snackbar(setAlert, {
            type: 'success',
            message: 'Referência Bancária criada com sucesso!',
          })
          fetchBankReferences();
          bankReference.bank = ''
          bankReference.agency = ''
          bankReference.account = ''
          bankReference.manager = ''
          bankReference.phone = ''
        }
      } catch (err: any) {
        const message = err?.response?.data?.message[0] || 'Erro ao tentar criar a referência bancária';
        snackbar(setAlert, {
          type: 'error',
          message
        })
      }
    }
  };

  const fetchBankReferences = async () => {
    const params = { customerId: props.id };
    getBankReferences(params)
      .then(({ data }) => {
        setBankReferences([
          ...new Array(pagination.page * pagination.limit),
          ...data.data,
        ]);
        setTotal(data.total);
      })
      .catch((error) =>
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message || 'Erro ao listar as referência bancáriaes',
        }),
      );
  };

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setBankReferences([]);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({ ...old, page }));
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};
      const { data } = await API.get<any>(`/bank-references`, params);
      setBankReferences([
        ...new Array(pagination.page * pagination.limit),
        ...data.data,
      ]);
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message: err?.response?.data?.message,
      });
    }
  };

  return (
    <Grid container spacing={5} justifyContent="space-between">
      {showDeleteDecisionAlert && (
        <Decision
          title="Exclusão de registro"
          message="Tem certeza de que deseja excluir o registro selecionado?"
          handleDecision={(decision) => removeBankReference(decision, idBankReference)}
        />
      )}
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Banco*
        </Typography>
        <TextField
          error={bankBankReferenceError}
          helperText={bankBankReferenceError ? 'Campo Obrigatório!' : ''}
          required
          variant="outlined"
          type="text"
          placeholder="Digite o nome do referência bancária"
          value={bankReference.bank}
          onChange={({ target: { value } }): void =>
            setBankReference({ ...bankReference, bank: value })
          }
          inputProps={{ maxLength: 75 }} 
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Typography className="subtitle">
          Agência*
        </Typography>
        <TextField
          error={agencyBankReferenceError}
          helperText={agencyBankReferenceError ? 'Campo Obrigatório!' : ''}
          required
          variant="outlined"
          placeholder="Digite o número da agência"
          value={bankReference.agency}
          onChange={({ target: { value } }): void =>
            setBankReference({ ...bankReference, agency: value })
          }
          inputProps={{ maxLength: 5 }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Typography className="subtitle">
          Conta*
        </Typography>
        <TextField
          error={accountBankReferenceError}
          helperText={accountBankReferenceError ? 'Campo Obrigatório!' : ''}
          required
          variant="outlined"
          type="text"
          placeholder="Digite o número da conta"
          value={bankReference.account}
          onChange={({ target: { value } }): void =>
            setBankReference({ ...bankReference, account: value })
          }
          inputProps={{ maxLength: 8 }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Gerente*
        </Typography>
        <TextField
          variant="outlined"
          type="text"
          error={managerBankReferenceError}
          helperText={managerBankReferenceError ? 'Campo Obrigatório!' : ''}
          required
          value={bankReference.manager}
          onChange={({ target: { value } }): void =>
            setBankReference({ ...bankReference, manager: value })
          }
          inputProps={{ maxLength: 75 }} 
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Telefone*
        </Typography>
        <InputMask
          required
          mask="(99) 99999-9999"
          value={bankReference.phone}
          onChange={({ target: { value } }): void =>
            setBankReference({ ...bankReference, phone: value })
          }
        >
          {() => (
            <TextField
              error={phoneBankReferenceError}
              helperText={phoneBankReferenceError ? 'Campo Obrigatório!' : ''}
              required
              variant="outlined"
              type="tel"
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} className="text-center">
        <Button
          className="button buttonContainedRounded buttonContainedColorPrimary CTA"
          onClick={() => {
            if (validationBankReference() == true) {
              saveBankReference();
            }
          }}
        >
          <Add /> Adicionar Referência Bancária
        </Button>
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <BankReferencesTable
          columns={columnsBankReferences}
          data={bankReferences}
          onSearchChange={(event: any) => { return debounce(onSearchChange, 700)(event); }}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          currentPage={pagination.page}
          totalRecords={total}
          rowsPerPage={rowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default BankReference;
