import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { getUserById, updateUser } from '../../../services/user-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import { Checkbox, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';
import Modal from '../../../components/shared/Modal/ModalSales'
import { IUser } from '../../../models/IUser';
import { valueToNode } from '@babel/types';
import { IMaskInput } from 'react-imask';
import { getGroups } from '../../../services/options-service';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const getRawValue = (value: string) => {
    return value.replace(/\D/g, '');
}

const CpfMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props: any, ref: any) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="#00.000.000-00"
                definitions={{
                    '#': /[0-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const PhoneMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props: any, ref: any) {
        const { onChange, ...other } = props;

        const mask = other?.value[2] === '9' ? '(#0) 00000-0000' : '(#0) 0000-0000'

        return (
            <IMaskInput
                {...other}
                mask={mask}
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

interface ICreateRoleModal {
    setOpen: any;
    open: boolean;
    refresh: () => void;
    id: string;
}
const EditUser = (props: ICreateRoleModal): JSX.Element => {
    const { open, setOpen, refresh, id } = props;
    const { setAlert } = useContext(AlertContext);
    const [user, setUser] = useState<IUser>({
        id: '',
        name: '',
        login: '',
        cpf_cnpj: '',
        phone_prefix: 0,
        phone: '',
        email: '',
        tipoUsuario: 1,
        loginType: '',
        grupo: ''
    });
    const [nameError, setNameError] = useState(false);
    const [groups, setGroups] = useState<any>([]);
    const [selectedGroupId, setSelectedGroupId] = useState<any>('');

    useEffect(() => {
        fetchGroups()
      }, []);
    
    async function fetchGroups() {
        try {
          const { data: { value } } = await getGroups();
          setGroups(value);
          
        } catch (error: any) {
          snackbar(setAlert, {
            type: 'error',
            message: error?.response?.data?.message
          });
        }
      }

    const saveUser = async () => {
        try {
            const payload = {
                id: user?.id,
                name: user?.name,
                login: getRawValue(user?.cpf_cnpj),
                cpf_cnpj: user?.cpf_cnpj,
                phone_prefix: Number(user?.phone.toString().substr(0, 2)),
                phone: Number(user?.phone.toString().substr(2, user?.phone.toString().length - 1)),
                email: user?.email,
                tipoUsuario: user?.tipoUsuario,
                loginType: 'backoffice',
                grupo: selectedGroupId
            }
            await updateUser(payload);
            snackbar(setAlert, { type: 'success', message: "Usuário salvo com sucesso" });
            refresh();
            setOpen(false);
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
        }
    }

    useEffect(() => {
        if (open && id) {
            fetchUserById()
        }
    }, [open])

    async function fetchUserById() {
        try {

            const { value } = await getUserById({
                    id: id,
                    loginType: 'backoffice'
                });
            setUser({
                id: value?.id,
                name: value?.nome,
                login: value?.login,
                cpf_cnpj: value?.cpf,
                phone_prefix: value?.phone_prefix,
                phone: value?.telefone,
                email: value?.email,
                tipoUsuario: value?.tipoUsuario,
                loginType: 'backoffice',
                grupo:''
            })
            setSelectedGroupId(value?.grupoAspNetUsers[0]?.grupo?.id);
            
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
        }
    }

    return (
        <Modal
            open={open}
            modalWidth={'700px'}
            handleConfirm={() => saveUser()}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={setOpen}
        >
            <Grid container spacing={2}>
                <Grid container alignItems="center" style={{ fontWeight: 'bold', color: '#30235d' }}>
                    <PersonAdd style={{ color: 'orange' }} />

                    &nbsp;&nbsp;EDITAR USUÁRIO
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">NOME</Typography>
                    <TextField
                        label="Nome"
                        variant="outlined"
                        error={nameError}
                        helperText={nameError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        value={user.name}
                        onChange={({ target: { value } }): void =>
                            setUser({ ...user, name: value })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className="subtitle">
                        CPF
                    </Typography>
                    <TextField
                        label="CPF"
                        variant="outlined"
                        error={nameError}
                        helperText={nameError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        value={user.cpf_cnpj}
                        onChange={({ target: { value } }): void =>
                            setUser({ ...user, cpf_cnpj: value })
                        }
                        InputProps={{
                            inputComponent: CpfMaskCustom as any
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className="subtitle">
                        Telefone
                    </Typography>
                    <TextField
                        label="Telefone"
                        variant="outlined"
                        error={nameError}
                        helperText={nameError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        value={user.phone}
                        onChange={({ target: { value } }): void =>
                            setUser({ ...user, phone: getRawValue(value) })
                        }
                        InputProps={{
                            inputComponent: PhoneMaskCustom as any
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className="subtitle">EMAIL</Typography>
                    <TextField
                        label="Email"
                        variant="outlined"
                        error={nameError}
                        helperText={nameError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        value={user.email}
                        onChange={({ target: { value } }): void =>
                            setUser({ ...user, email: value })
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className="subtitle">GRUPO</Typography>
                    <Select
                        value={selectedGroupId}
                        native
                        fullWidth
                        onChange={({ target: { value } }): any =>
                            setSelectedGroupId(value)
                        }
                        inputProps={{
                            name: 'roles',
                            id: 'outlined-age-native-simple',
                        }}
                        >
                        {groups?.map((group: any, index: number) => (
                            <option key={index} value={group.id}>
                            {group.nome}
                            </option>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default EditUser;
