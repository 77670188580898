import React from 'react';
import { Home, Settings, Call, Assignment, Chat, AssignmentLate, Help, AssignmentTurnedIn, Remove, Email, Store, Receipt, People, ShoppingCart, AllInbox, MonetizationOn, DirectionsCar, Build, Group } from '@material-ui/icons';
const sidebarItemsAdmin: any[] = [
  {
    icon: React.createElement(Home, {}),
    name: 'Principal',
    permission: '',
    route: '/home',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(Settings, {}),
    name: 'Segurança',
    permission: '',
    route: '',
    level: 1,
    children: [
      {
        icon: React.createElement(Remove, {}),
        name: 'Usuários',
        permission: '',
        route: '/users',
        level: 2,
        children: [],
      },
      {
        icon: React.createElement(Remove, {}),
        name: 'Grupos',
        permission: '',
        route: '/groups',
        level: 2,
        children: [],
      },
      {
        icon: React.createElement(Remove, {}),
        name: 'Permissões',
        permission: '',
        route: '/permissions',
        level: 2,
        children: [],
      },
    ],
  },
  {
    icon: React.createElement(Group, {}),
    name: 'Corretores',
    permission: '',
    route: '/brokers',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(DirectionsCar, {}),
    name: 'Veículos',
    permission: '',
    route: '/vehicles',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(MonetizationOn, {}),
    name: 'Cotação',
    permission: '',
    route: '/quotation',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(Build, {}),
    name: 'Cobertura',
    permission: '',
    route: '/coverage',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(AssignmentTurnedIn, {}),
    name: 'Apólices',
    permission: '',
    route: '/policies',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(MonetizationOn, {}),
    name: 'Promoções',
    permission: '',
    route: '/promotions',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(Call, {}),
    name: 'Atendimento',
    permission: '',
    route: '/attendance',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(Assignment, {}),
    name: 'Termo de Uso',
    permission: '',
    route: '/terms',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(Chat, {}),
    name: 'Notificação',
    permission: '',
    route: '/notification',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(AssignmentLate, {}),
    name: 'Condições Gerais',
    permission: '',
    route: '/general-conditions',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(Help, {}),
    name: 'FAQ',
    permission: '',
    route: '/faq',
    level: 1,
    children: [],
  },




  // {
  //   icon: React.createElement(People, {}),
  //   name: 'Clientes',
  //   permission: '',
  //   route: '',
  //   level: 1,
  //   children: [
  //     {
  //       icon: React.createElement(Remove, {}),
  //       name: 'Cadastro',
  //       permission: '',
  //       route: '/clients',
  //       level: 2,
  //       children: [],
  //     },
  //     {
  //       icon: React.createElement(Remove, {}),
  //       name: 'Análise',
  //       permission: '',
  //       route: '/clients-external',
  //       level: 2,
  //       children: [],
  //     },
  //   ],
  // },
  // {
  //   icon: React.createElement(Email, {}),
  //   name: 'E-mails',
  //   permission: '',
  //   route: '/email',
  //   level: 1,
  //   children: []
  // },
  // {
  //   icon: React.createElement(Store, {}),
  //   name: 'Produtos',
  //   permission: '',
  //   route: '',
  //   level: 1,
  //   children: [
  //     {
  //       icon: React.createElement(Remove, {}),
  //       name: 'Listar Produtos',
  //       permission: '',
  //       route: '/products',
  //       level: 2,
  //       children: [],
  //     },
  //     {
  //       icon: React.createElement(Remove, {}),
  //       name: 'Criar produto',
  //       permission: '',
  //       route: '/products/create',
  //       level: 2,
  //       children: [],
  //     }
  //   ],
  // },
  // {
  //   icon: React.createElement(Receipt, {}),
  //   name: 'Pedidos',
  //   permission: '',
  //   route: '/orders',
  //   level: 1,
  //   children: [],
  // },
  // {
  //   icon: React.createElement(ShoppingCart, {}),
  //   name: 'Vendas',
  //   permission: '',
  //   route: '/sales',
  //   level: 1,
  //   children: []
  // },
  // {
  //   icon: React.createElement(MonetizationOn, {}),
  //   name: 'Caixa  ',
  //   permission: '', 
  //   route: '/cashier',
  //   level: 1,
  //   children: []
  // },
  // {
  //   icon: React.createElement(AllInbox, {}),
  //   name: 'Estoque',
  //   permission: '',
  //   route: '',
  //   level: 1,
  //   children: [
  //     {
  //       icon: React.createElement(Remove, {}),
  //       name: 'Disponíveis',
  //       permission: '',
  //       route: '/inventory/products-available',
  //       level: 2,
  //       children: [],
  //     },
  //     {
  //       icon: React.createElement(Remove, {}),
  //       name: 'Vendidos',
  //       permission: '',
  //       route: '/inventory/products-sold',
  //       level: 2,
  //       children: [],
  //     }
  //   ],
  // },
];

const sidebarItemsSeller: any[] = [
  {
    icon: React.createElement(Home, {}),
    name: 'Home',
    permission: '',
    route: '/home',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(Store, {}),
    name: 'Produtos',
    permission: '',
    route: '',
    level: 1,
    children: [
      {
        icon: React.createElement(Remove, {}),
        name: 'Listar Produtos',
        permission: '',
        route: '/products',
        level: 2,
        children: [],
      },
      {
        icon: React.createElement(Remove, {}),
        name: 'Criar produto',
        permission: '',
        route: '/products/create',
        level: 2,
        children: [],
      }
    ],
  },
  {
    icon: React.createElement(Receipt, {}),
    name: 'Pedidos',
    permission: '',
    route: '/orders',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(ShoppingCart, {}),
    name: 'Vendas',
    permission: '',
    route: '/sales',
    level: 1,
    children: []
  },
  {
    icon: React.createElement(MonetizationOn, {}),
    name: 'Caixa  ',
    permission: '',
    route: '/cashier',
    level: 1,
    children: []
  },
  {
    icon: React.createElement(AllInbox, {}),
    name: 'Estoque',
    permission: '',
    route: '',
    level: 1,
    children: [
      {
        icon: React.createElement(Remove, {}),
        name: 'Disponíveis',
        permission: '',
        route: '/inventory/products-available',
        level: 2,
        children: [],
      },
      {
        icon: React.createElement(Remove, {}),
        name: 'Vendidos',
        permission: '',
        route: '/inventory/products-sold',
        level: 2,
        children: [],
      }
    ],
  },
];

const sidebarItemsClient: any[] = [
  {
    icon: React.createElement(Home, {}),
    name: 'Home',
    permission: '',
    route: '/home',
    level: 1,
    children: [],
  },
  {
    icon: React.createElement(Receipt, {}),
    name: 'Pedidos',
    permission: '',
    route: '/orders',
    level: 1,
    children: [],
  }

];

export { sidebarItemsAdmin, sidebarItemsSeller, sidebarItemsClient };
