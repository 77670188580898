// Componente baseado em https://material-ui.com/pt/components/menus/#menus

import React, { useRef, useEffect, useState, useContext } from 'react';
import {
  IconButton,
  MenuItem,
  Paper,
  MenuList,
  Popper,
  InputAdornment,
  Grow,
  ClickAwayListener,
  TextField
} from '@material-ui/core';
import { AccountCircle as AccoutCircleIcon, Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import BootstrapTooltip from '../../../shared/Tooltip';
import { AuthContext } from '../../../../contexts/AuthContext';
import { AlertContext } from '../../../../contexts/AlertContext';
import './ToolbarButton.css';
import { useHistory } from 'react-router-dom';
import { snackbar } from '../../../../utils/util';
import ModalWithTransitionSales from '../../../shared/Modal/ModalSales';
import API from '../../../../services/services';


interface IUserInfo {
  email: string;
  exp: number;
  iat: number;
  id: number;
  name: string;
  roles: Array<object>;
  sellerId: number | null;
  sub: number;
}

export const UserMenu = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({} as IUserInfo);
  const [firstName, setFirstName] = useState('');
  const [modal, setModal] = useState(false);
  const [showOldpass, setShowOldpass] = useState(false);
  const [showNewpass, setShowNewpass] = useState(false);
  const [showRepeatNewpass, setShowRepeatNewpass] = useState(false);
  const [errorOldPass, setErrorOldPass] = useState(false);
  const [errorNewPass, setErrorNewPass] = useState(false);
  const [errorRepeatPass, setErrorRepeatPass] = useState(false);
  const [errorEqual, setErrorEqual] = useState(false);
  const prevOpen = useRef(open);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const oldpasswordRef = useRef<HTMLInputElement>(null);
  const newpasswordRef = useRef<HTMLInputElement>(null);
  const repeatnewpasswordRef = useRef<HTMLInputElement>(null);
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const routeHistory = useHistory();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      }
    }),
  );

  const classes = useStyles();

  function handleToggle(): void {
    setOpen((prevOpenOld) => !prevOpenOld);
  }

  function handleClose(event: any): void {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  function handleListKeyDown(event: React.KeyboardEvent): void {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  function logout(): void {
    snackbar(alertContext.setAlert, {
      type: 'success',
      message: 'Realizando Logout...',
    })
    localStorage.clear();
    authContext.setToken('');
    authContext.setPermissions('');

    routeHistory.push('/');
  }

  function validationPassword() {
    let validate: boolean = false;
    if (oldpasswordRef.current?.value.trim() === '') {
      setErrorOldPass(true);
      validate = true;
    } else {
      setErrorOldPass(false);
    }

    if (newpasswordRef.current?.value.trim() === '') {
      setErrorNewPass(true);
      validate = true;
    } else {
      setErrorNewPass(false);
    }

    if (repeatnewpasswordRef.current?.value.trim() === '') {
      setErrorRepeatPass(true);
      validate = true;
    } else {
      setErrorRepeatPass(false);
    }

    if (repeatnewpasswordRef.current?.value !== newpasswordRef.current?.value) {
      setErrorEqual(true);
      validate = true;
    } else {
      setErrorEqual(false);
    }

    return validate;
  }

  async function ChangePassword(old: any, newpass: any, newRepeat: any) {

    if (validationPassword()) {
      return;
    }

    try {
      const response = await API.post(`Login/senha/alterar`, {
        userName: localStorage.getItem('login'),
        senhaAntiga: old,
        senhaNova: newpass
      });

      snackbar(alertContext.setAlert, {
        type: 'success',
        message: 'Senha alterada com sucesso!!'
      });
      setModal(false);
    } catch (error: any) {
      snackbar(alertContext.setAlert, {
        type: 'error',
        message: error?.response?.data?.errors[0],
      });
    }
  }

  useEffect(() => {
    function loadNameUser() {
      const tkn = localStorage.getItem('userJson');     
      let info_user = JSON.parse(tkn || '{}');
      setFirstName(info_user.nome);
    }
    loadNameUser()
  }, []);

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <span style={{ fontSize: 16 }}>&nbsp;&nbsp;{firstName}</span>
        <BootstrapTooltip title={authContext.email} arrow placement="bottom">

          <IconButton
            className="userButton"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <AccoutCircleIcon />
          </IconButton>
        </BootstrapTooltip>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 1 }}
        >
          {({ TransitionProps, placement }): JSX.Element => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >

                    <div className="divider" />
                    <MenuItem
                      onClick={(event): void => {
                        setShowOldpass(false);
                        setShowNewpass(false);
                        setShowRepeatNewpass(false);
                        setErrorOldPass(false);
                        setErrorNewPass(false);
                        setErrorRepeatPass(false);
                        setErrorEqual(false);
                        setModal(true);
                        setOpen(false);
                      }}
                    >
                      Alterar senha
                    </MenuItem>
                    <div className="divider" />
                    <MenuItem
                      onClick={(event): void => {
                        handleClose(event);
                        logout();
                      }}
                    >
                      Sair
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <ModalWithTransitionSales
        open={modal}
        modalWidth={'500'}
        handleClose={() => setModal(false)}
        handleConfirm={() => ChangePassword(
          oldpasswordRef.current?.value,
          newpasswordRef.current?.value,
          repeatnewpasswordRef.current?.value
        )}
        colorBtnConfirm="#30235d"
        textBtnConfirm="Confirmar"
      >
        <div>
          <h2 style={{ color: "#30235d" }}><strong>Alterar Senha</strong></h2>
          <div style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
            width: "400px"
          }}>
            <label style={{
              color: "#30235d",
              fontSize: "16px"
            }} htmlFor="oldpassword">
              <strong>Senha antiga:</strong>
            </label>
            <TextField
              required
              inputRef={oldpasswordRef}
              type={showOldpass ? "text" : "password"}
              error={errorOldPass}
              helperText={errorOldPass ? 'Campo Obrigatório!' : ''}
              variant="outlined"
              name="oldpassword"
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowOldpass(!showOldpass)}
                      edge="end">
                      {showOldpass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
            width: "400px"
          }}>
            <label style={{
              color: "#30235d",
              fontSize: "16px"
            }} htmlFor="newpassword">
              <strong>Nova Senha:</strong>
            </label>
            <TextField
              required
              inputRef={newpasswordRef}
              type={showNewpass ? "text" : "password"}
              error={errorNewPass}
              helperText={errorNewPass ? 'Campo Obrigatório!' : ''}
              variant="outlined"
              name="newpassword"
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowNewpass(!showNewpass)}
                      edge="end">
                      {showNewpass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
            width: "400px"
          }}>
            <label style={{
              color: "#30235d",
              fontSize: "16px"
            }} htmlFor="repeatnewpassword">
              <strong>Confirme a Nova Senha:</strong>
            </label>
            <TextField
              required
              inputRef={repeatnewpasswordRef}
              type={showRepeatNewpass ? "text" : "password"}
              error={errorRepeatPass || errorEqual}
              helperText={errorRepeatPass ? 'Campo Obrigatório!' : errorEqual ? 'Senha deve ser igual a informada' : null}
              variant="outlined"
              name="repeatnewpassword"
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowRepeatNewpass(!showRepeatNewpass)}
                      edge="end">
                      {showRepeatNewpass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </ModalWithTransitionSales>
    </div>
  );
};
