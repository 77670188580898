import React, { useState, useEffect, useContext } from 'react';
import InputMask from 'react-input-mask';

import { Grid, Typography, TextField, InputAdornment, Divider, Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Decision } from '../../../../components/shared/Alert';
import ProvidersTable, { Column, Actions } from '../../../../components/shared/Table';

import nProgress from 'nprogress';
import { snackbar } from '../../../../utils/util';
import { Client } from '../../ClientModel';

import { AlertContext } from '../../../../contexts/AlertContext';
import API from '../../../../services/services';
import { getProviders } from '../../../../services/client-service';
import LabelHeaderTable from '../../../../components/shared/Label/LabelHeaderTable';

const Provider = (props: any): JSX.Element => {

  const { setAlert } = useContext(AlertContext);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [idProvider, setIdProvider] = useState(0);
  const [showDeleteDecisionAlert, setShowDeleteDecisionAlert] = useState(false);
  const [providers, setProviders] = useState<Client[]>([]);
  const [editProviders, setEditProviders] = useState(false);

  const [nameProviderError, setNameProviderError] = useState(false);
  const [countryProviderError, setCountryProviderError] = useState(false);
  const [phoneProviderError, setPhoneProviderError] = useState(false);
  const [participationInPurchasesProviderError, setParticipationInPurchasesProviderError] = useState(false);

  const [provider, setProvider] = useState<any>({
    name: '',
    country: '',
    phone: '',
    participationInPurchases: '',
    customerId: parseInt(props.id)
  } as any);

  const columnsProviders: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'country',
      label: 'País',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'phone',
      label: 'Telefone',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'participationInPurchases',
      label: 'Part. nas Compras (%)',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#006193",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0] as number;
          const name = selectedRow.rowData[1];
          const country = selectedRow.rowData[2];
          const phone = selectedRow.rowData[3];
          const participationInPurchases = selectedRow.rowData[4] as number;
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DELETAR',
                  onClick: (): void => {
                    setShowDeleteDecisionAlert(true);
                    setIdProvider(id);
                  },
                },
                {
                  type: 'EDITAR',
                  onClick: (): void => {
                    setEditProviders(true)
                    setIdProvider(id);
                    provider.name = name
                    provider.country = country
                    provider.phone = phone
                    provider.participationInPurchases = participationInPurchases
                  },
                },
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchProviders();
  }, [pagination]);

  const removeProvider = async (decision: 'OK' | 'CANCEL', id: number) => {
    setShowDeleteDecisionAlert(false);
    nProgress.start();
    if (decision === 'OK') {
      const { data } = await API.delete('/providers', id);
      if (data?.deleted) {
        snackbar(setAlert, {
          type: 'success',
          message: 'Fornecedor removido com sucesso',
        });
        fetchProviders();
      } else {
        snackbar(setAlert, {
          type: 'error',
          message: 'Erro ao remover fornecedor',
        });
      }
    }
    nProgress.done();
  };

  function validationProvider() {
    setNameProviderError(!provider.name);
    setCountryProviderError(!provider.country);
    setPhoneProviderError(!provider.phone);
    setParticipationInPurchasesProviderError(!provider.participationInPurchases);
    return (
      !!provider.name &&
      !!provider.country &&
      !!provider.phone &&
      !!provider.participationInPurchases
    );
  }

  const saveProvider = async () => {
    if (editProviders) {
      try {
        const { status } = await API.patch(`/providers/${idProvider}`, provider);
        if (status === 200) {
          setEditProviders(false)
          fetchProviders();
          provider.name = ''
          provider.country = ''
          provider.phone = ''
          provider.participationInPurchases = ''
          snackbar(setAlert, {
            type: 'success',
            message: 'Fornecedor atualizado com sucesso',
          })
        }
      } catch (err: any) {
        const message = err?.response?.data?.message[0] || 'Erro ao tentar atualizar o fornecedor';
        snackbar(setAlert, {
          type: 'error',
          message
        })
      }
    } else {
      try {
        const { status } = await API.post(`/providers`, provider);
        if (status === 201) {
          snackbar(setAlert, {
            type: 'success',
            message: 'Fornecedor criado com sucesso!',
          })
          fetchProviders();
          provider.name = ''
          provider.country = ''
          provider.phone = ''
          provider.participationInPurchases = ''
        }
      } catch (err: any) {
        const message = err?.response?.data?.message[0] || 'Erro ao tentar criar o fornecedor';
        snackbar(setAlert, {
          type: 'error',
          message
        })
      }
    }
  };

  const fetchProviders = async () => {
    const params = { customerId: props.id };
    getProviders(params)
      .then(({ data }) => {
        setProviders([
          ...new Array(pagination.page * pagination.limit),
          ...data.data,
        ]);
        setTotal(data.total);
      })
      .catch((error) =>
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message || 'Erro ao listar os fornecedores',
        }),
      );
  };

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setProviders([]);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({ ...old, page }));
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};
      const { data } = await API.get<any>(`/providers`, params);
      setProviders([
        ...new Array(pagination.page * pagination.limit),
        ...data.data,
      ]);
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message: err?.response?.data?.message,
      });
    }
  };

  return (
    <Grid container spacing={5} justifyContent="space-between">
      {showDeleteDecisionAlert && (
        <Decision
          title="Exclusão de registro"
          message="Tem certeza de que deseja excluir o registro selecionado?"
          handleDecision={(decision) => removeProvider(decision, idProvider)}
        />
      )}
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Nome*
        </Typography>
        <TextField
          error={nameProviderError}
          helperText={nameProviderError ? 'Campo Obrigatório!' : ''}
          required
          variant="outlined"
          type="text"
          placeholder="Digite o nome do fornecedor"
          value={provider.name}
          onChange={({ target: { value } }): void =>
            setProvider({ ...provider, name: value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          País*
        </Typography>
        <TextField
          error={countryProviderError}
          helperText={countryProviderError ? 'Campo Obrigatório!' : ''}
          required
          variant="outlined"
          type="text"
          placeholder="Digite o país"
          value={provider.country}
          onChange={({ target: { value } }): void =>
            setProvider({ ...provider, country: value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Telefone*
        </Typography>
        <InputMask
          required
          mask="(99) 99999-9999"
          value={provider.phone}
          onChange={({ target: { value } }): void =>
            setProvider({ ...provider, phone: value })
          }
        >
          {() => (
            <TextField
              error={phoneProviderError}
              helperText={phoneProviderError ? 'Campo Obrigatório!' : ''}
              required
              variant="outlined"
              type="tel"
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Participação nas Compras*
        </Typography>
        <TextField
          name="profitSharing"
          title={'Participação no Faturamento'}
          fullWidth
          variant="outlined"
          type={"number"}
          error={participationInPurchasesProviderError}
          helperText={participationInPurchasesProviderError ? 'Campo Obrigatório!' : ''}
          value={provider.participationInPurchases}
          defaultValue={"0"}
          onKeyPress={( event: any ) => {return event >= 48}}
          onChange={({ target: { value } }): void =>
            setProvider({ ...provider, participationInPurchases: Number(value) })
          }
          InputProps={{
            inputProps: { min: 0, pattern: "[^\d\s-\/]"  },
            startAdornment: (
              <InputAdornment position="start">%</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} className="text-center">
        <Button
          className="button buttonContainedRounded buttonContainedColorPrimary CTA"
          onClick={() => {
            if (validationProvider() == true) {
              saveProvider();
            }
          }}
        >
          <Check /> Salvar Fornecedor
        </Button>
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <ProvidersTable
          columns={columnsProviders}
          data={providers}
          onSearchChange={(event: any) => { return debounce(onSearchChange, 700)(event); }}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          currentPage={pagination.page}
          totalRecords={total}
          rowsPerPage={rowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default Provider;
