import API from './services'
import IVehicle from '../models/IVehicle';

type GetVehiclesResponse = {
    value: IVehicle[]
}

type GetVehicleResponse = {
    value: IVehicle
}

export const getVehicles = () => {
    return API.get<GetVehiclesResponse>('/Veiculo');
}

export const getVehicleById = (id: string) => {
    return API.get<GetVehicleResponse>(`/Veiculo/${id}`);
}

export const postVehicle = (vehicle: IVehicle) => {
    return API.post('/Veiculo', vehicle);
}

export const putVehicle = (vehicle: IVehicle, type: 'EDIT' | 'TRANSFER', newUserId?: string) => {
    return API.put(`/Veiculo/${vehicle?.id}`,
        {
            chassi: vehicle?.chassi,
            corId: vehicle?.cor?.id,
            marcaId: vehicle?.marca?.id,
            modeloAno: vehicle?.modeloAno,
            modeloNome: vehicle?.modeloNome,
            placa: vehicle?.placa,
            renavam: vehicle?.renavam,
            situacao: vehicle.situacao,
            usuarioId: type === 'EDIT' ? vehicle?.usuario?.id : newUserId,
            veiculoCoberturas: vehicle?.veiculoCobertura.map(v => {
                return { coberturaId: v?.coberturaOpcao[0]?.coberturaId, coberturaOpcaoId: v?.coberturaOpcao[0]?.id }
            })
        }
    );
}

export const activateDeactivateVehicle = (id: string) => {
    return API.delete('/Veiculo/ativarDesativar', id);
}