import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { PlaylistAddCheck } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { createRole } from '../../../services/role-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import { Checkbox, FormControlLabel } from '@mui/material';
import Modal from '../../../components/shared/Modal/ModalSales';
import API from '../../../services/services';


interface ICreateFAQModal {
    setOpen: any;
    open: boolean;
    refresh: () => void;
}
const CreateFAQModal = (props: ICreateFAQModal): JSX.Element => {
    const { open, setOpen, refresh } = props;
    const { setAlert } = useContext(AlertContext);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');

    const [qstError, setQstError] = useState(false);
    const [aswError, setAswError] = useState(false);

    function validation() {
        setQstError(!question);
        setAswError(!answer);
  
        return (
          !question || !answer
        );
    }

    useEffect(() => {

        if(!open && qstError || aswError){
            setQstError(false);
            setAswError(false);
        }

    },[open])
    
    function cleanFields(){
        setAnswer('');
        setQuestion('');
    }

    async function saveFAQ(){

        //validation
        if(validation()){
            return;
        }

        try {
            //console.log('aqui: ', { pergunta: question, resposta: answer });
            const response = await API.post('/Faq', { pergunta: question, resposta: answer })
            //const response = await createRole({ name: roleName, permissionsIds: [] });
            snackbar(setAlert, { type: 'success', message: "FAQ salva com sucesso" })
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
            //if(error?.response?.data?.statusCode === 400){
            //    snackbar(setAlert, { type: 'error', message: 'Grupo já cadastrado no sistema' });
            //} else {
            //    snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
            //}
    
        }
        cleanFields();
        setOpen(false);
        refresh();
    }

    function closeModal(){
        setQstError(false);
        setAswError(false);
        setOpen(false);
    }
    
    return (
        <Modal 
            open={open}
            modalWidth={'700px'}
            handleConfirm={() => saveFAQ()}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={() => closeModal()}
          >
             <Grid container spacing={2}>
                 <Grid container alignItems="center" style={{ fontWeight: 'bold', color: '#30235d' }}>
                     <PlaylistAddCheck style={{ color: 'orange' }} />

                    &nbsp;&nbsp;CADASTRAR FAQ
                 </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">PERGUNTA</Typography>
                    <TextField
                        label="Pergunta"
                        variant="outlined"
                        error={qstError}
                        helperText={qstError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setQuestion(value)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">RESPOSTA</Typography>
                    <TextField
                        label="Resposta"
                        multiline
                        rows={4}
                        variant="outlined"
                        error={aswError}
                        helperText={aswError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setAnswer(value)
                        }
                    />
                </Grid>
            </Grid>
          </Modal>
    );
};

export default CreateFAQModal;
