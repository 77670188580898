import { Grid } from '@material-ui/core';
import React, { useState, useContext, FormEvent, useEffect } from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import API from '../../services/services';
import { AlertContext } from '../../contexts/AlertContext';
import { AuthContext } from '../../contexts/AuthContext';
import './LoginPage.css';
import LoginComponent from './LoginComponent';
import CreateAccount from './CreateAccount/CreateAccount';
import ForgotPasswordComponent from './ForgotPassword/ForgotPasswordComponent';
import { snackbar } from '../../utils/util';

const Login = ({ children }: any): JSX.Element => {
  const routeHistory = useHistory();
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  // const [onRequestWithGoogle] = useState(false);
  const [, setStep] = useState(0)
  const [captchaError, setCaptchaError] = useState(false)
  const [userError, setUserError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [cpfError, setCpfError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [captchaForgotPassword, setCaptchaForgotPassword] = useState(false);
  const [captchaEquation, setCaptchaEquation] = useState('')
  const [credentialsForgot, setCredentialsForgot] = useState('')
  const [credentials, setCredentials] = useState({
    userName: '',
    password: '',
    grantTypes: "password",
    captchaId: '',
    resultado: 0
  });
  const [showResendMail, setShowResendMail] = useState(true);

  useEffect(() => {
    fetchCaptcha()
  }, [])

  async function fetchCaptcha() {    
    const response = await API.post('/Login/captcha', {ip: 'teste-ip'})    
    console.log(response);
    
    setCredentials({ ...credentials, captchaId: response.data.value.id});
    //setCredentialsForgot({ ...credentialsForgot})
    setCaptchaEquation(response.data.value.soma);
  }

  function emailValidation() {
    try {
      /*if (!credentialsForgot.cpf || !credentialsForgot.result) {
        setCpfError(!credentialsForgot.cpf)
        setCaptchaForgotPassword(!credentialsForgot.result)
        return
      }*/
      API.post('Login/recuperacao-senha', {
        email: credentialsForgot
      })
        .then((data) => {
          setShowResendMail(false)
          snackbar(alertContext.setAlert, { type: 'success', message:'E-mail enviado!' });
        })
        .catch((error) => {
          setShowResendMail(true);
            snackbar(alertContext.setAlert, { type: 'error', message: error?.response?.data?.errors[0] || 'Erro ao recuperar senha' });
        });
    } finally {

    }
  }

  function validarEmail(email: string) {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function validationCredentials() {
    setUserError(!credentials.userName);
    if(credentials.resultado !== 0){
      setCaptchaError(!credentials.resultado);
    }
    setPasswordError(!credentials.password);

    if(credentials.resultado !== 0){
      return !credentials.userName || !credentials.resultado || !credentials.password
    } else {
      return !credentials.userName || !credentials.password
    }
  }

  async function postLogin(event: FormEvent<HTMLFormElement>) {
    setOnRequest(false);
    event.preventDefault();

    if (validationCredentials()) {      
      return;
    }

    try {
      const response = await API.post('Login', credentials);
      const permissions = ["1", '2', 3]

      console.log('response login: ', response.data);
      localStorage.setItem('user', response.data.value.nome);
      localStorage.setItem('login', response.data.value.usuario.userName);

      localStorage.setItem('jwtSecurityToken', response.data.value.token.accessToken);
      localStorage.setItem('userJson', JSON.stringify(response.data.value));
      localStorage.setItem('permissions', JSON.stringify(Array.isArray(permissions) ? permissions : []));
      setOnRequest(false);
      event.preventDefault();
      authContext.setToken(response.data.value.token.accessToken);
      authContext.setPermissions(JSON.stringify(Array.isArray(permissions) ? permissions : []));
      snackbar(alertContext.setAlert, {
        type: 'success',
        message: 'Login efetuado com sucesso.',
        positionX: 'center',
        positionY: 'top',
      });/*
        let arrPermissions = ["1", '2', 3];
        localStorage.setItem('jwtSecurityToken', '123');
        localStorage.setItem('permissions', JSON.stringify(Array.isArray(arrPermissions) ? arrPermissions : []));
        setOnRequest(false);
        event.preventDefault();
        authContext.setToken('123');
        authContext.setPermissions(JSON.stringify(Array.isArray(arrPermissions) ? arrPermissions : []));*/


      routeHistory.push('/home');
    } catch (ex: any) {
      console.log('aque: ', ex.response);
      snackbar(alertContext.setAlert, {
        type: 'error',
        message: ex?.response?.data?.errors[0] || 'Credenciais inválidas',
        positionX: 'center',
        positionY: 'top',
      });
      // if (ex.response.status === 404) {
      //   snackbar(alertContext.setAlert, {
      //     type: 'error',
      //     message: 'Usuário ou senha inválidos',
      //     positionX: 'center',
      //     positionY: 'top',
      //   });
      //   fetchCaptcha();
        
      // } else {
      //   snackbar(alertContext.setAlert, {
      //     type: 'error',
      //     message: ex?.response?.data?.message || 'Credenciais inválidas',
      //     positionX: 'center',
      //     positionY: 'top',
      //   });
      //   fetchCaptcha();        
      // }
    }
  }
  return (
  <>
    <Redirect from="/" to="/Login" />
    <Route path='/forgotPassword'>
      <>
        {children}
        <ForgotPasswordComponent
          showResend={showResendMail}
          onChangeCpfLogin={(event): void => {
            setCredentialsForgot(event.target.value);
          }}
          onChangeCaptcha={(event): void => {
            setCaptchaForgotPassword(false);
            
          }}
          captchaForgot={captchaForgotPassword}
          onRequestLogin={onRequest}
          cpfErrorLogin={cpfError}
          onClickStep={() => {
            return routeHistory.push('/Login')
          }}
          onClickSendEmail={emailValidation}
          captcha={captchaEquation}
          onClickShowResend={() => setShowResendMail(true)}
          email={credentialsForgot}
        />
      </>
    </Route>
    <Route path='/createAccount'>
      <CreateAccount
        onRequestLogin={onRequest}
        onClickBack={() => {
          return routeHistory.push('/Login')
        }}
      />
    </Route>
    <Route path='/Login'>
      <>
        <Grid item>
          {children}
          <LoginComponent
            onChangePasswordLogin={(event): void => {
              setPasswordError(false);
              setCredentials({
                ...credentials,
                password: event.target.value,
              });
            }}
            onChangeUserLogin={(event): void => {
              setUserError(false);
              setCredentials({
                ...credentials,
                userName: event.target.value,
              });
              if(event.target.value === ''){
                setEmailError(false);
              }
            }}
            onChangeCaptchaLogin={(event): void => {
              setCaptchaError(false);
              setCredentials({ ...credentials, resultado: +event.target.value });
            }}
            captcha={captchaEquation}
            onSubmitPostLogin={postLogin}
            onRequestLogin={onRequest}
            userErrorLogin={userError}
            passwordErrorLogin={passwordError}
            captchaErrorLogin={captchaError}
            showPasswordLogin={showPassword}
            onClickShowPassword={() => setShowPassword(!showPassword)}
            onClickStep={() => setStep(1)}
            onClickCreateAccount={() => setStep(2)}
            emailError={emailError} />
        </Grid>
      </>
    </Route>
  </>)
}


export default Login;
