import { useContext, useState, useEffect } from 'react';
import { AlertContext } from '../../../contexts/AlertContext';
import TableListRole, {
  StatusColumnSales,
  Column,
  Actions,
  customFilterDate
} from '../../../components/shared/Table';
import React from 'react';
import API from '../../../services/services';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Decision } from '../../../components/shared/Alert';
import Container from '../../../components/shared/Container';
import { format } from 'date-fns';
import { IRole } from '../../Role/RoleModel';
import CreateRoleModal from '../../Role/CreateRole/CreateRoleModal';
// import ListUsersModal from '../ListUsersModal/ListUsersModal';
import { snackbar } from '../../../utils/util';
// import EditRoleModal from '../EditRoleModal/EditRoleModal';
import LabelHeaderTable from '../../../components/shared/Label/LabelHeaderTable';
import nProgress from 'nprogress';
import moment from 'moment';
import { User } from '../../User/UserModel';
import CreateFAQModal from '../CreateFAQ/CreateFAQModal';
import EditFAQModal from '../EditFAQ/EditFAQModal';
import Modal from '../../../components/shared/Modal/ModalSales';

interface IFaq{
  id: number;
  ordem: number;
  pergunta: string;
  resposta: string;
}

const FAQList = (): JSX.Element => {
  const { setAlert } = useContext(AlertContext);
  const [isLoaded, setIsLoaded] = useState(false);

  const [id, setId] = useState('');
  const [ord, setOrd] = useState(0);
  const [qst, setQst] = useState('');
  const [asw, setAsw] = useState('');
  
  const [showDeleteDecisionAlert, setShowDeleteDecisionAlert] = useState(false);
  const [idToDel, setIdToDel] = useState(0);
  const [selectedFaq, setSelectedFaq] = useState<IFaq>({} as IFaq);

  const [rowIndex, setrowIndex] = useState<any>();
  const [showNewFaq, setShowNewFaq] = useState(false);
  const [showEditFaq, setShowEditFaq] = useState(false);
  const [showListUsersModal, setShowListUsersModal] = useState(false);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: Column[] = [
    {
      name: 'id',
      label: 'Cód.',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'ordem',
      label: 'Ordem',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'pergunta',
      label: 'Pergunta',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'resposta',
      label: 'Resposta',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          return (
            <Actions
              buttonProps={[
                {
                  type: 'EDITAR',
                  onClick: (): void => {
                    setSelectedFaq({ 
                      id: selectedRow.rowData[0],
                      ordem: selectedRow.rowData[1],
                      pergunta: selectedRow.rowData[2],
                      resposta: selectedRow.rowData[3] 
                     })
                    //console.log('aqui: ', selectedRow.rowData);
                    setShowEditFaq(true);
                  },
                  //disabled: (selectedRow.rowData[0] == 1 || selectedRow.rowData[0] == 2 || selectedRow.rowData[0] == 3)
                },
                {
                  type: 'DELETAR',
                  onClick: (): void => {
                    setIdToDel(selectedRow.rowData[0]);
                    //console.log('id da FAQ: ', selectedRow.rowData[0]);
                    const faq = faqs.find(obj => obj?.id === selectedRow.rowData[0]) 
                    //const users = role && role.users;
                    //console.log('id da FAQ: ', id_faq);
                    setShowDeleteDecisionAlert(true);
                    
                  }
                },
              ]}
            />
          );
        },
      },
    },
  ];

  const [faqs, setFaqs] = useState<IFaq[]>([]);
  const [lastPage, setLastPage] = useState(-1);


  useEffect(() => {
    fetchFaqs();
  }, [pagination]);

  async function disableEnablePromotion() {}

  async function fetchFaqs() {
    //setIsLoaded(true);

     /*setIsLoaded(false);
     await API.get<any>(`/Faq`)
       .then(({ data }) => {
         console.log(data)
         setFaqs((old) => [...new Array(pagination.page * pagination.limit), ...data.data]);
         setFaqs([
           ...new Array(pagination.page * pagination.limit),
           ...data.data,
         ]);
         setTotal(data.total);
         setLastPage(pagination.page);
       })
       .catch((error) => snackbar(setAlert, { type: 'error', ex: error }))
       .finally(() => setIsLoaded(true));*/
       try{
        const response = await API.get<any>('/Faq');
        //console.log(response);
        setFaqs(response.data?.value);
       } catch(err: any){
        snackbar(setAlert, { type: 'error', message: err?.response?.data?.message });
       }
       //setIsLoaded(false);

  };

  const deleteTarget = async () => {
    
    try {
      const resp = await API.delete(`Faq`, idToDel);
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });
      //setPagination({ limit:10, page: 0 })
      fetchFaqs();
    } catch (ex: any) {
      //snackbar(setAlert, { type: 'error', message: '' });
      snackbar(setAlert, { type: 'error', message: ex?.response?.data?.message });
    } finally {
      setShowDeleteDecisionAlert(false);
    }
  };

  const deleteMultiple = async (FaqsId: any) => {
    try {
      await API.post('/Faqs/delete-multiple', { ids: FaqsId });
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });

      //return window.location.reload();
      await fetchFaqs();
    } catch (ex) {
    //   snackbar(setAlert, { type: 'error', message: ex?.response?.data?.message });
      snackbar(setAlert, { type: 'error', message: '' });

    } finally {
      setIsLoaded(true);
    }
  };

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setFaqs([]);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page
    }));
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {

    const params = searchText[0]
      ? {
        search: {
          fields: ['name'],
          value: searchText.length ? searchText : null,
        },
        
      }
      : {};
      /*getFaqs(
        pagination.page + 1,
        pagination.limit,
        params
      ).then(({ data }) => {
        setTotal(data.total);
        searchText[0] ? onChangeRowsPerPage(total) : onChangeRowsPerPage(10)
        
        setFaqs(Faqs => Faqs.concat(data.data));
      })*/
  };

  return (
    <>
      <Grid container spacing={0}>
        <Typography className="contextTopBar" variant="h3" component="h2">
          FAQ
        </Typography>

        <Container
          hidden={isLoaded}
          headerTable="Faqs"
          title="Listagem de Faqs"
          showButtonOnTop
          textButtonOnTop="Nova FAQ"
          onClickButtonTop={setShowNewFaq}
        >
          <Grid style={{ padding: "10px" }}>
            <TableListRole
              title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Consultar FAQ</h3>}
              columns={columns}
              data={faqs || []}
              onSearchChange={(event: any) =>
                debounce(onSearchChange, 700)(event)
              }
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={onChangePage}
              currentPage={pagination.page}
              totalRecords={total}
              rowsPerPage={rowsPerPage}
              onRowsDelete={(indexRole) => {
                const FaqsId = faqs
                  .filter((_, i) => indexRole.find((x) => x.index === i))
                  .map((id) => id.id);

                deleteMultiple(FaqsId);
              }}
            />
          </Grid>
        </Container>
        {/*!isLoaded && (
          <Skeleton
            className="skeleton"
            variant="rect"
            animation="wave"
            width="100%"
            height={500}
          />
        )*/}
         <Modal 
            open={showDeleteDecisionAlert}
            modalWidth={'400px'}
            handleConfirm={deleteTarget}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'EXCLUIR'}
            handleClose={() => setShowDeleteDecisionAlert(false)}
          >
            <div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <span style={{fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>EXCLUIR FAQ</span>
              </div>
              <div style={{ fontSize: 18, color: '#8c8c8c', fontWeight: 'bold', marginTop: 30}}>Tem certeza que deseja excluir esta FAQ?</div>
            </div>
          </Modal>
         <CreateFAQModal
          open={showNewFaq}
          setOpen={setShowNewFaq}
          refresh={() => {
            fetchFaqs();
          }
          }
        />
        <EditFAQModal
          open={showEditFaq}
          setOpen={setShowEditFaq}
          faq={selectedFaq}
          refresh={() => {
            fetchFaqs();
          }
          }
        />
      </Grid>
    </>
  );
};

export default FAQList;



