import React, { useState, useContext, useEffect, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { Button, Grid, Typography, TextField, InputAdornment, ListSubheader, MenuItem, } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Container from '../../../components/shared/Container';

import { AlertContext } from '../../../contexts/AlertContext';
import './EditClient.css';

import API from '../../../services/services';
import { getClientById, getSellers } from '../../../services/client-service';

import { colors } from '../../../utils/Theme';
import { snackbar, formatCnpj, formatPhone, formatDateField } from '../../../utils/util';
import { DateInput } from '../../../components/shared/inputs/DateInput';
import moment from 'moment';

const EditClient = () => {
  const { setAlert } = useContext(AlertContext);
  return <EditClientPage {...{ setAlert }} />;
};

const EditClientPage = memo((props: any) => {
  const { setAlert } = props;
  const { id } = useParams<any>();
  const routerHistory = useHistory();

  const [client, setClient] = useState<any>({
    name: '',
    cpf: '',
    email: '',
    sellerId: 0,
  } as any);

  const [company, setCompany] = useState<any>({
    name: '',
    company_name: '',
    address: '',
    neighborhood: '',
    city: '',
    state: '',
    cep: '',
    cnpj: '',
    state_registration: '',
    //snea: '',
    operational_email: '',
    operational_name: '',
    operational_phone: '',
    finance_email: '',
    finance_name: '',
    finance_phone: '',
    branches: 0,
    type_of_activity: '',
    number_of_employees: 0,
    net_worth: 0,
    foundation_data: '',
  } as any);

  const [sellers, setSellers] = useState([] as any[]);
  const [sellerName, setSellerName] = useState('');

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [cnpjError, setCnpjError] = useState(false);
  const [company_nameError, setcompany_nameError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [cepError, setCepError] = useState(false);
  const [cpfError, setCpfError] = useState(false);
  const [sellerError, setSellerError] = useState(false);



  const [companyId, setCompanyId] = useState(0);

  useEffect(() => {
    getClientById(id).then(async (value) => {
      setCompanyId(value.companyId);
      setClient({
        ...client,
        name: value.name,
        cpf: value.cpf,
        email: value.email,
        sellerId: value.sellerId,
      })

      setCompany({
        ...company,
        name: value.company.name,
        company_name: value.company.company_name,
        address: value.company.address,
        neighborhood: value.company.neighborhood,
        city: value.company.city,
        state: value.company.state,
        cep: value.company.cep,
        cnpj: value.company.cnpj ? formatCnpj(value.company.cnpj) : '',
        state_registration: value.company.state_registration,
        //snea: value.company.snea,
        operational_email: value.company.operational_email,
        operational_name: value.company.operational_name,
        operational_phone: value.company.operational_phone ? formatPhone(value.company.operational_phone) : '',
        finance_email: value.company.finance_email,
        finance_name: value.company.finance_name,
        finance_phone: value.company.finance_phone ? formatPhone(value.company.finance_phone) : '',
        branches: value.company.branches,
        type_of_activity: value.company.type_of_activity,
        number_of_employees: value.company.number_of_employees,
        net_worth: value.company.net_worth ? currencyMask(value.company.net_worth) : '',
        foundation_data: value.company.foundation_data && moment(value.company.foundation_data).isValid() ? moment(value.company.foundation_data).format('DD/MM/YYYY') : null,
      })

      var page = 1;
      var lastPage = 1;
      while (lastPage >= page) {
        const { data } = await API.get<any>(
          `/users?page=${page}&limit=10&search[value]=Vendedor&search[fields]=roles.name`,
        );

        data.data.forEach((seller: any) => {
          if (seller.id == value.sellerId) {
            setSellerName(seller.name)
          }
        });
        page++;
        lastPage = data.lastPage;

        setSellers(sellers => sellers.concat(data.data));
      }
    }).catch((error: any) =>
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message || 'Registro não encontrado',
      })
    )
  }, []);



  // useEffect(() => {
  //   getSellers().then(({ data }) => {
  //     setSellers(data);
  //     data.forEach((seller: any) => {
  //       if (seller.id == client.sellerId) {
  //         setSellerName(seller.name)
  //       }
  //     })
  //   }).catch((error: any) =>
  //     snackbar(setAlert, {
  //       type: 'error',
  //       message:
  //         error?.response?.data?.message || 'Erro ao buscar os vendedores',
  //     })
  //   );
  // }, [client.sellerId]);

  function validationClient() {
    setNameError(!client.name);
    setEmailError(!client.email);
    setcompany_nameError(!company.company_name);
    setAddressError(!company.address);
    setCepError(!company.cep);
    setCpfError(!client.cpf);
    setSellerError(!client.sellerId);
    return (
      !!client.name &&
      !!client.email &&
      !!company.company_name &&
      !!company.address &&
      !!company.cep &&
      !!client.sellerId &&
      !!client.cpf
    );
  }

  function cleanFields() {
    setClient({
      name: null,
      cpf: null,
      email: null,
      sellerId: 0,
    });
    setSellerName('');
    setCompany({
      name: '',
      company_name: '',
      address: '',
      neighborhood: '',
      city: '',
      state: '',
      cep: '',
      cnpj: '',
      state_registration: '',
      //snea: '',
      operational_email: '',
      operational_name: '',
      operational_phone: '',
      finance_email: '',
      finance_name: '',
      finance_phone: '',
      branches: 0,
      type_of_activity: '',
      number_of_employees: 0,
      net_worth: 0,
      foundation_data: '',
    })
  }

  const saveClient = async () => {
    moment().toISOString()
    client.cpf = client.cpf ? client.cpf.replace('-', '').replace('.', '').replace('.', '') : null
    company.cnpj = company.cnpj ? company.cnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '') : null
    company.number_of_employees = parseInt(company.number_of_employees)
    company.branches = parseInt(company.branches)
    company.foundation_data = company.foundation_data && moment(company.foundation_data, 'DD/MM/YYYY').isValid() ? moment(company.foundation_data, 'DD/MM/YYYY').toISOString() : null;
    company.net_worth = company.net_worth ? parseFloat(company.net_worth.replace('.', '').replace(',', '.')) : null

    if (company.state_registration == "") {
      company.state_registration = "ISENTO"
    }

    try {
      await API.patch(`/users/${id}`, client);
      const { status } = await API.patch(`/companies/${companyId}`, company);
      if (status === 200) {
        snackbar(setAlert, {
          type: 'success',
          message: 'Cliente atualizado com sucesso',
        })
        routerHistory.goBack();
      }
    } catch (err: any) {
      const message = err?.response?.data?.message[0] || 'Erro ao tentar atualizar o cliente';
      snackbar(setAlert, {
        type: 'error',
        message
      })
    }
  };

  function currencyMask(value: any) {

    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d)/g, "$1");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".")

    return value;
  }

  const dateFormat : string = 'DD/MM/YYYY';

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography className='contextTopBar' variant="h3" component="h2">
          Editar Cliente
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Container>
          <form className="form" autoComplete="off">
            <Grid item xs={12} style={{ paddingTop: "10px" }}>
              <Grid
                container
                spacing={5}
                className="edith-group-form-container"
                style={{ marginBottom: 10 }}
              >
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    CNPJ
                  </Typography>
                  <InputMask
                    required
                    mask="99.999.999/9999-99"
                    value={company.cnpj}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, cnpj: value })
                    }
                  >
                    {() => (
                      <TextField
                        error={cnpjError}
                        helperText={cnpjError ? 'Campo Obrigatório!' : ''}
                        required
                        variant="outlined"
                        type="tel"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Razão Social*
                  </Typography>
                  <TextField
                    error={company_nameError}
                    helperText={company_nameError ? 'Campo Obrigatório!' : ''}
                    variant="outlined"
                    type="text"
                    placeholder="Digite a razão social"
                    value={company.company_name}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, company_name: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Nome Fantasia
                  </Typography>
                  <TextField
                    required
                    variant="outlined"
                    type="text"
                    placeholder="Digite o nome fantasia"
                    value={company.name}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, name: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Nome*
                  </Typography>
                  <TextField
                    error={nameError}
                    helperText={nameError ? 'Campo Obrigatório!' : ''}
                    required
                    variant="outlined"
                    type="text"
                    placeholder="Digite o nome do cliente"
                    value={client.name}
                    onChange={({ target: { value } }): void =>
                      setClient({ ...client, name: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    CPF*
                  </Typography>
                  <InputMask
                    required
                    mask="999.999.999-99"
                    value={client.cpf}
                    onChange={({ target: { value } }): void =>
                      setClient({ ...client, cpf: value })
                    }
                  >
                    {() => (
                      <TextField
                        required
                        error={cpfError}
                        helperText={cpfError ? 'Campo Obrigatório!' : ''}
                        variant="outlined"
                        type="tel"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    E-mail*
                  </Typography>
                  <TextField
                    error={emailError}
                    helperText={emailError ? 'Campo Obrigatório!' : ''}
                    required
                    variant="outlined"
                    type="text"
                    placeholder="Digite o e-mail"
                    value={client.email}
                    onChange={({ target: { value } }): void =>
                      setClient({ ...client, email: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography className="subtitle">
                    Endereço*
                  </Typography>
                  <TextField
                    error={addressError}
                    helperText={addressError ? 'Campo Obrigatório!' : ''}
                    variant="outlined"
                    type="text"
                    placeholder="Digite o endereço"
                    value={company.address}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, address: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Bairro
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Digite o bairro"
                    value={company.neighborhood}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, neighborhood: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Cidade
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Digite a cidade"
                    value={company.city}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, city: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Estado
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Digite o estado"
                    value={company.state}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, state: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    CEP*
                  </Typography>
                  <InputMask
                    mask="99999-999"
                    value={company.cep}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, cep: value })
                    }
                    required
                    name="cep"
                    title={'CEP'}
                  >
                    {() => (
                      <TextField
                        error={cepError}
                        helperText={cepError ? 'Campo Obrigatório!' : ''}
                        variant="outlined"
                        type="text"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Inscrição Estadual
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Digite o I.E."
                    value={company.state_registration}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, state_registration: value })
                    }
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    SNEA
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Digite o SNEA"
                    value={company.snea}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, snea: value })
                    }
                  />
                </Grid> */}
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    E-mail - Operacional
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Digite o e-mail da área operacional"
                    value={company.operational_email}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, operational_email: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Pessoa p/ contato - Operacional
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Digite o nome da pessoa p/ contato"
                    value={company.operational_name}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, operational_name: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Telefone - Operacional
                  </Typography>
                  <InputMask
                    required
                    mask="(99) 99999-9999"
                    value={company.operational_phone}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, operational_phone: value })
                    }
                  >
                    {() => (
                      <TextField
                        variant="outlined"
                        type="tel"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    E-mail - Financeiro
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Digite o e-mail de contas a pagar"
                    value={company.finance_email}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, finance_email: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Pessoa p/ contato - Financeiro
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Digite o nome da pessoa p/ contato"
                    value={company.finance_name}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, finance_name: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Telefone - Financeiro
                  </Typography>
                  <InputMask
                    required
                    mask="(99) 99999-9999"
                    value={company.finance_phone}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, finance_phone: value })
                    }
                  >
                    {() => (
                      <TextField
                        variant="outlined"
                        type="tel"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Filiais
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="number"
                    placeholder="Digite a quantidade de filiais"
                    value={company.branches}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, branches: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Atividade
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Digite a atividade"
                    value={company.type_of_activity}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, type_of_activity: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Nº de Funcionários
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="number"
                    placeholder="Digite o número de funcionários"
                    value={company.number_of_employees}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, number_of_employees: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Capital Social (R$)
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={company.net_worth}
                    onChange={({ target: { value } }): void => {
                      setCompany({ ...company, net_worth: currencyMask(value) })
                    }

                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Data de Fundação
                  </Typography>
                  <DateInput 
                    disableFuture
                    size="medium"
                    value={
                      moment(company.foundation_data, dateFormat).isValid()
                        ? moment(company.foundation_data, dateFormat)
                        : null}
                    name="foundation_data"
                    clearable
                    onChange={(momentDate: moment.Moment) => {setCompany({ 
                        ...company, 
                        foundation_data: moment(momentDate, dateFormat).isValid()
                          ? moment(momentDate).format(dateFormat)
                          : '' 
                    })}}
                    />
                  {/* <TextField
                    required
                    variant="outlined"
                    type="date"
                    placeholder="Digite o nome do comprador"
                    value={company.foundation_data}
                    onChange={({ target: { value } }): void =>
                      setCompany({ ...company, foundation_data: value })
                    }
                  /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography className="subtitle">
                    Vendedor*
                  </Typography>
                  <Autocomplete
                    id="autocomplete-permission"
                    options={sellers}
                    getOptionLabel={(option) => (option.name)}
                    inputValue={sellerName}
                    onInputChange={(event, value) => {
                      setSellerName(value);;
                    }}
                    onChange={(event, value) => {
                      setClient({ ...client, sellerId: value.id })
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={sellerError}
                          helperText={sellerError ? 'Campo Obrigatório!' : ''}
                          placeholder="Pesquisar vendedores..."
                          variant="outlined"
                          size='small'
                          autoComplete="off"
                          required
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="center"
              style={{ padding: '20px' }}
            >
              <Button
                className="button buttonContainedRounded CTA"
                onClick={() => routerHistory.push('/clients')}
                style={{ backgroundColor: colors.tertiary, marginRight: '10px' }}
              >
                Voltar
              </Button>
              <Button
                className="button buttonContainedRounded CTA"
                style={{ backgroundColor: colors.warning, marginRight: '10px' }}
                onClick={() => {
                  cleanFields();
                }}
              >
                Limpar Campos
              </Button>
              <Button
                className="button buttonContainedRounded buttonContainedColorPrimary CTA"
                onClick={() => {
                  if (validationClient() == true) {
                    saveClient();
                  }
                }}
              >
                Salvar Cliente
              </Button>
            </Grid>
          </form>
        </Container>
      </Grid>
    </Grid >
  );
});

export default EditClient;