import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import API from '../../services/services';
import { getGroups } from '../../services/options-service';
import { getGroupPermissions, getPermissionsByGroup, postGroupPermissions, deleteGroupPermissions } from '../../services/group-permissions-service';
import { IRole, IRoleData } from '../Role/RoleModel';
import './Permission.css';
import { IPermissionData, IPermission } from './PermissionModel';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { LockOpen } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { AlertContext } from '../../contexts/AlertContext';
import { snackbar } from '../../utils/util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    cardHeader: {
      padding: theme.spacing(1, 2)
    },
    list: {
      width: 400,
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

function not(a: any, b: any) {
  if (!a || !b) return [];

  return a.filter((value: any) => b.indexOf(value) === -1);
}

function intersection(a: any, b: any) {
  if (!a || !b) return [];

  return a.filter((value: any) => b.indexOf(value) !== -1);
}

function union(a: any, b: any) {
  if (!a || !b) return [];
  return [...a, ...not(b, a)];
}
function setLeftInitialState(a: any, b: string[]) {
  if (!a || !b) return [];
  const intersect = intersection(a, b);

  return intersect.map((item: string) => b.indexOf(item));
}
function setRightInitialState(a: any, b: any) {
  if (!a || !b) return [];

  const filt = b.filter((value: any) => a.indexOf(value) === -1);

  return filt?.map((item: any) => b.indexOf(item));
}

export default function Permission(): JSX.Element {
  const { setAlert } = useContext(AlertContext);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [role, setRole] = useState<IRole>();
  const { id } = useParams<{ id: string }>();
  const [permissions, setPermissions] = useState<any>([]);
  const classes = useStyles();
  const [checked, setChecked] = React.useState<any>([]);
  const [left, setLeft] = React.useState<number[]>([]);
  const [right, setRight] = React.useState<number[]>([]);
  const [groups, setGroups] = useState<any>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<any>('');
  const [groupPermissions, setGroupPermissions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // API.get<IPermissionData>(`/permissions`)
    //   .then(({ data }) => {
    //     setPermissions(data.data);
    //     return data.data;
    //   })
    //   .then(getRoles)
    //   .catch((error) =>
    //     snackbar(setAlert, {
    //       type: 'error',
    //       message: error?.response?.data?.message,
    //     }),
    //   );
    fetchGroups()
  }, []);

  useEffect(() => {
    if (selectedGroupId) {
      fetchGroupPermissions()
      fetchPermissionsByGroup()
    }
  }, [selectedGroupId])

  const getRoles = (permissions: IPermission[]) => {
    // API.get<IRoleData>(`/roles?sorters=name&limit=1000`)
    //   .then(({ data }) => {
    //     setRoles(data.data);
    //     if (id) {
    //       const paramRole = data.data.find(
    //         (role: IRole) => role.id === parseInt(id),
    //       );
    //       setRole(paramRole);
    //       const whatIhave = paramRole?.permissions.map(
    //         (item: any) => item.label,
    //       );
    //       const all = permissions?.map((item: any) => item.label);
    //       setLeft(setLeftInitialState(whatIhave, all));
    //       setRight(setRightInitialState(whatIhave, all));
    //     }
    //   })
    //   .catch((error) =>
    //     snackbar(setAlert, {
    //       type: 'error',
    //       message: error?.response?.data?.message,
    //     }),
    //   );
  };

  async function fetchGroups() {
    setIsLoading(true)
    try {
      const { data: { value } } = await getGroups();
      setGroups(value);
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message: error?.response?.data?.message
      });
    }
    setIsLoading(false)
  }

  async function fetchGroupPermissions() {
    setIsLoading(true)
    try {
      const { data: { value } } = await getGroupPermissions(selectedGroupId);
      setPermissions(value);
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message: error?.response?.data?.message
      });
    }
    setIsLoading(false)
  }

  async function fetchPermissionsByGroup() {
    setIsLoading(true)
    try {
      const { data: { value } } = await getPermissionsByGroup(selectedGroupId);
      setGroupPermissions(value);
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message: error?.response?.data?.message
      });
    }
    setIsLoading(false)
  }

  const findRole = (event: any) => {
    if (!event.target.value) return;

    const answer =
      typeof event.target.value === 'string' ? event.target.value : '';
    const role = roles?.find((role: IRole) => role.id === parseInt(answer));
    const whatIhave = role?.permissions.map((item: any) => item.label);
    const all = permissions.map((item: any) => item.label);
    setLeft(setLeftInitialState(whatIhave, all));
    setRight(setRightInitialState(whatIhave, all));
    setRole(role);
  };

  const leftChecked = intersection(checked, permissions);
  const rightChecked = intersection(checked, groupPermissions);

  const handleToggle = (value: number, type?: 'AVAILABLE' | 'ADDED') => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items: number[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setPermissions(permissions.concat(rightChecked));
    setGroupPermissions(not(groupPermissions, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: React.ReactNode, items: number[], type?: 'AVAILABLE' | 'ADDED') => (
    <Card elevation={0}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            //style={{color: '#f4694c'}}
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionadas`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value: any, i: number) => {
          const labelId = `transfer-list-all-item-${value?.nome}-label`;

          return (
            <ListItem
              key={i}
              role="listitem"
              button
              onClick={handleToggle(value, type)}
            >
              <ListItemIcon>
                <Checkbox
                  //style={{color: '#f4694c'}}
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={
                  type === 'AVAILABLE'
                    ?
                    permissions.map((item: any) => item?.permissao.descricao)[i]
                    :
                    groupPermissions.map((item: any) => item?.permissao.descricao)[i]
                }
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const saveGroupPermissions = async () => {
    setIsLoading(true)
    try {
      const payload = checked.map((p: any) => {
        return {
          permissaoId: p?.id,
          grupoId: selectedGroupId
        }
      })
      await postGroupPermissions({ data: payload })
      snackbar(setAlert, { type: 'success', message: 'Ação realizada com sucesso' });
      setChecked([]);
      fetchGroupPermissions()
      fetchPermissionsByGroup()
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
    }
    setIsLoading(false)
  }

  const removeGroupPermissions = async () => {
    setIsLoading(true)
    try {
      const payload = checked.map((p: any) => {
        return p?.permissao?.id
      })
      await deleteGroupPermissions(selectedGroupId, payload)
      snackbar(setAlert, { type: 'success', message: 'Ação realizada com sucesso' });
      setChecked([]);
      fetchGroupPermissions()
      fetchPermissionsByGroup()
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
    }
    setIsLoading(false)
  }

  return (
    <Grid container direction="column" className="main-grid">
      <Typography className="contextTopBar" variant="h3" component="h2">
        Permissões
      </Typography>
      <Grid
        container
        style={{ fontSize: 23, marginBottom: 22 }}
        direction="row"
        alignItems="center"
      >
        <Grid item style={{ display: 'flex', marginRight: 8 }}>
          <LockOpen style={{ color: ' #FFAC00' }} />
        </Grid>
        <Grid style={{ color: '#30235d', fontWeight: 'bold' }} item>GERENCIAR PERMISSÕES DO GRUPO</Grid>
      </Grid>
      <Typography style={{ alignSelf: 'flex-start', fontWeight: 'bolder', color: '#696969' }}>
        NOME DO GRUPO
      </Typography>
      <FormControl variant="outlined">
        <Select
          native
          onChange={({ target: { value } }): any =>
            setSelectedGroupId(value)
          }
          inputProps={{
            name: 'roles',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          {groups?.map((group: any, index: number) => (
            <option key={index} value={group.id}>
              {group.nome}
            </option>
          ))}
        </Select>
      </FormControl>
      <Typography style={{ marginTop: 20, alignSelf: 'flex-start', fontWeight: 'bolder', color: '#696969' }}>
        PERMISSÕES DO GRUPO
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: 10 }}
        className={classes.root}
      >
        <Grid item>{customList('Permissões disponíveis', permissions, 'AVAILABLE')}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={saveGroupPermissions}
              disabled={leftChecked.length === 0 || isLoading}
              aria-label="move selected right"
              style={{ backgroundColor: "#006193", color: "#fff", padding: 10 }}
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={removeGroupPermissions}
              disabled={rightChecked.length === 0 || isLoading}
              aria-label="move selected left"
              style={{ backgroundColor: "#40b2b7", color: "#fff", padding: 10 }}
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList('Permissões adicionadas', groupPermissions, 'ADDED')}</Grid>
      </Grid>
    </Grid>
  );
}
