import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import './ListClient.css';

import ClientTable, { Column, Actions } from '../../../components/shared/Table';
import Container from '../../../components/shared/Container';
import { Decision } from '../../../components/shared/Alert';
import { Client } from '../ClientModel';
import { AlertContext } from '../../../contexts/AlertContext';

import { snackbar, formatCpf, formatPhone } from '../../../utils/util';
import API from '../../../services/services';
import { getClients } from '../../../services/client-service';
import LabelHeaderTable from '../../../components/shared/Label/LabelHeaderTable';
import nProgress from 'nprogress';

const ListClient = () => {
  const routerHistory = useHistory();

  const [clients, setClients] = useState<Client[]>([]);
  const { setAlert } = useContext(AlertContext);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showDeleteDecisionAlert, setShowDeleteDecisionAlert] = useState(false);
  const [idClient, setIdClient] = useState(0);

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setClients([]);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({ ...old, page }));
  };

  const removeClient = async (decision: 'OK' | 'CANCEL', id: number) => {
    setShowDeleteDecisionAlert(false);
    nProgress.start();
    if (decision === 'OK') {
      const { data } = await API.delete('/users', id);
      if (data?.deleted) {
        snackbar(setAlert, {
          type: 'success',
          message: 'Cliente removido com sucesso',
        });
        return window.location.reload();
      } else {
        snackbar(setAlert, {
          type: 'error',
          message: 'Erro ao remover cliente',
        });
      }
    }
    nProgress.done();
  };

  const columns: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'company_name',
      label: 'Razão Social',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'email',
      label: 'E-mail',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'cpf',
      label: 'CPF',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'phone',
      label: 'Telefone',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#006193",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0] as number;
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DELETAR',
                  onClick: (): void => {
                    setShowDeleteDecisionAlert(true);
                    setIdClient(id);
                  },
                },
                {
                  type: 'EDITAR',
                  route: `/clients/edit/${id}`,
                },
                {
                  type: 'OTHER',
                  icon: <Visibility />,
                  text: 'Visualizar Cliente',
                  onClick: (): void => {
                    routerHistory.push(`/clients/view/${id}`)
                  },
                },
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchClients();
  }, [pagination]);

  const fetchClients = async () => {
    getClients(
      pagination.page + 1,
      pagination.limit,
    )
      .then(({ data }) => {

        //console.log(data);
        data.data.forEach((client: any) => {
          try {
            client.company_name = client.company.company_name
            client.cpf = client.cpf ? formatCpf(client.cpf) : null
            client.phone = client.phone ? formatPhone(client.phone) : null
          } catch (error: any){

          }
          
        });

        setClients([
          ...new Array(pagination.page * pagination.limit),
          ...data.data,
        ]);
        setTotal(data.total);
      })
      .catch((error) =>
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message //|| 'Erro ao listar os clientes',
        }),
      );
  };

  const deleteMultiple = async (clientsId: any) => {
    try {
      await API.post('/users/delete-multiple', clientsId);
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });

      return window.location.reload();
    } catch (ex: any) {
      snackbar(setAlert, { type: 'error', message: ex?.response?.data?.message });
    }
    // finally {
    //   setIsLoaded(true);
    // }
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};

      getClients(
        pagination.page + 1,
        pagination.limit,
        params
      ).then(({ data }) => {
        setTotal(data.total);
        searchText[0] ? onChangeRowsPerPage(total) : onChangeRowsPerPage(10)

        data.data.forEach((client: any) => {
          try{
          client.company_name = client.company.company_name
          client.cpf = client.cpf ? formatCpf(client.cpf) : null
          client.phone = client.phone ? formatPhone(client.phone) : null
          }
          catch (err: any) {
            
          }
        });

        setClients(clients => clients.concat(data.data));
      })
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message: err?.response?.data?.message,
      });
    }
  };

  return (
    <>
      {showDeleteDecisionAlert && (
        <Decision
          title="Exclusão de registro"
          message="Tem certeza de que deseja excluir o registro selecionado?"
          handleDecision={(decision) => removeClient(decision, idClient)}
        />
      )}
      <Grid container>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Clientes
        </Typography>
        <Container
          showButtonOnTop
          textButtonOnTop="Novo Cliente"
          routerLink="/clients/create"
        >
          <Grid item xs={12}>
            <Grid style={{ padding: "10px" }}>
              <ClientTable
                title={<h3 style={{ color: "#006193", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Lista de clientes</h3>}
                columns={columns}
                data={clients}
                onSearchChange={(event: any) => { return debounce(onSearchChange, 700)(event); }}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                currentPage={pagination.page}
                totalRecords={total}
                rowsPerPage={rowsPerPage}
                onRowsDelete={(indexRole) => {
                  const clientsId = clients
                    .filter((_, i) => indexRole.find((x) => x.index === i))
                    .map((id) => id.id);
                  deleteMultiple(clientsId);
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default ListClient;
