import React, { useState, useEffect, useContext } from 'react';
import TermOfUseTable, { Column, Actions } from '../../components/shared/Table';
import { Button, Grid, TextField, Typography, InputAdornment } from '@material-ui/core';
import Container from '../../components/shared/Container';
//import { getUsers, activeUser } from '../../../services/user-service';
import { AlertContext } from '../../contexts/AlertContext';
import { snackbar } from '../../utils/util';
import API from '../../services/services';
import DownloadIcon from '@mui/icons-material/Download';
import LabelHeaderTable from '../../components/shared/Label/LabelHeaderTable';
import { DeleteForever, Launch, CloudDownload, Assignment, CloudUpload } from '@material-ui/icons';
import Modal from '../../components/shared/Modal/ModalSales'
import { fontStyle } from '@mui/system';
import moment from 'moment';

interface iTerm {
  id: number;
  name: string;
  version: number;
  date_created: string;
  msg: string;
}

const TermOfUse: React.FC = () => {
  const [terms, setTerms] = useState<iTerm[]>([]);
  const { setAlert } = useContext(AlertContext);
  const [id] = useState(0);
  const [idToDel, setIdToDel] = useState('');
  const [message, setMessage] = useState('');
  const [editMessage, setEditMessage] = useState({ id: '', value: '' });

  const [messageError, setMessageError] = useState(false);
  const [editMsgError, setEditMsgError] = useState(false);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [showModalDel, setShowModalDel] = useState(false);
  const [showModalNewTerm, setShowModalNewTerm] = useState(false);
  const [showModalUpdateTerm, setShowModalUpdateTerm] = useState(false);

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setTerms([]);
  };
  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page,
    }));
  };

  /*const disableEnableUser = async (event: any, id: number) => {
    try{
      const { data } = await activeUser(id, !event.target.checked);
      
      if(data.updated){
        snackbar(setAlert, {
          type: 'success',
          message: 'Ação realizada com sucesso'
        });
      }
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          err?.response?.data?.message
      });
    }
    
    fetchUsers();
  };
  */
  const columns: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      },
    },
    {
      name: 'mensagem',
      label: 'Sistema',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", } }),
        setCellProps: (): any => ({
          className: 'noWrap',
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 500,
            textOverflow: 'ellipsis !important',
            overflow: 'hidden',
            height: '45px'
          },
        }),
        sort: false,
      }
    },
    {
      name: 'versao',
      label: 'Versão',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'dataCriacao',
      label: 'Data de Criação',
      options: {
        customBodyRender: (value): string =>
          moment(value).format('DD/MM/YYYY'),
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0];
          const msg = selectedRow.rowData[1];
          return (
            <Actions
              buttonProps={[
                {
                  type: 'OTHER',
                  onClick: () => {
                    setIdToDel(id);
                    setShowModalDel(true);
                  },
                  icon: <DeleteForever />,
                  text: 'excluir',
                  id: id,
                },
                {
                  type: 'OTHER',
                  onClick: () => {
                    setEditMessage({ id: id, value: msg })
                    setShowModalUpdateTerm(true);
                  },
                  icon: <Launch />,
                  text: 'editar',
                  id: id
                }
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchTermsOfUse()
  }, [pagination]);

  async function fetchTermsOfUse() {
    try {
      const response = await API.get<any>('Termo');
      //console.log(response);
      setTerms(response.data?.value);
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
    }
  };

  const saveTerm = async () => {

    if (message === "") {
      setMessageError(true);
      return;
    } else {
      setMessageError(false);
    }


    try {
      const response = await API.post('Termo', {
        mensagem: message
      });
      snackbar(setAlert, { type: 'success', message: "Termo salvo com sucesso!" })
      setMessage('');
      fetchTermsOfUse();
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
    }

    setShowModalNewTerm(false);
  }

  const editTerm = async () => {

    if (editMessage.value === "") {
      setEditMsgError(true);
      return;
    } else {
      setEditMsgError(false);
    }

    try {
      const response = await API.put(`Termo/${editMessage.id}`, {
        mensagem: editMessage.value
      });
      snackbar(setAlert, { type: 'success', message: "Termo editado com sucesso!" })
      setEditMessage({ id: '', value: '' });
      fetchTermsOfUse();
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
    }

    setShowModalUpdateTerm(false);
  }

  const deleteTarget = async () => {
    try {
      const response = await API.delete('Termo', idToDel);
      //setPagination({ limit:10, page: 0 })
      snackbar(setAlert, { type: 'success', message: 'Termo de uso excluído com sucesso!' });
      fetchTermsOfUse();
    } catch (ex: any) {
      snackbar(setAlert, { type: 'error', message: ex?.response?.data?.errors[0] });
    }
    setShowModalDel(false);
  };
  /*
    const fetchTermsOfUse = async () => {
      getUsers(
        pagination.page + 1,
        pagination.limit)
        .then(({ data }) => {
          console.log(data);
          
          data.value.forEach((element: any, index: number) => {
            element.deletado = element.deletado === true ? 'Inativo' : 'Ativo';
          });
          setUsers([
            ...new Array(pagination.page * pagination.limit),
            ...data.value,
          ]);
          setTotal(data.total);
        })
        .catch((error) =>
          snackbar(setAlert, {
            type: 'error',
            message:
              error?.response?.data?.message || 'Erro ao listar o usuários',
          }),
        );
    };*/

  /*const deleteMultiple = async (usersId: any) => {
    try {
      await API.post('/users/deactivate-multiple', { ids: usersId });
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });

      //return window.location.reload();
      fetchUsers();
    } catch (ex: any) {
      snackbar(setAlert, { type: 'error', message: ex?.response?.data?.message });
    }
    // finally {
    //   setIsLoaded(true);
    // }
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};
      //const { data } = await API.get<any>(`/users`, params);
      getUsers(
        pagination.page + 1,
        pagination.limit,
        params
      ).then(({ data }) => {
        console.log(data);
        
        setTotal(data.total);
        searchText[0] ? onChangeRowsPerPage(total) : onChangeRowsPerPage(10)
        setUsers(users => users.concat(data.data));
      })
    } catch (err: any) {
      // snackbar(setAlert, {
      //   type: 'error',
      //   message: err?.response?.data?.message,
      // });
    }
  };
*/
  return (
    <>
      <Grid container>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Termos de uso
        </Typography>
        <Button
          style={{ position: 'absolute', top: 65, right: 15, color: '#fff', backgroundColor: '#30235d', borderRadius: 10, padding: 6 }}
          onClick={() => setShowModalNewTerm(true)}
        >
          Novo Termo
        </Button>
        <Container>
          <Grid item xs={12}>
            <Grid style={{ padding: "10px" }}>
              <TermOfUseTable
                title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Consultar Termos</h3>}
                columns={columns}
                data={terms}
                //onSearchChange={(event: any) => {
                //return debounce(onSearchChange, 700)(event);
                //}}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                currentPage={pagination.page}
                totalRecords={total}
                rowsPerPage={rowsPerPage}
              // onRowsDelete={(indexRole) => {
              //   console.log("index: ", indexRole);
              //   const usersId = users
              //     .filter((_: any, i: any) => indexRole.find((x) => x.index === i))
              //     .map((id: any) => id.id);
              //     console.log('users ids: ', usersId)
              //   deleteMultiple(usersId);
              // }}
              />
            </Grid>
          </Grid>
          <Modal
            open={showModalNewTerm}
            modalWidth={'700px'}
            handleConfirm={saveTerm}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={() => {
              if (messageError) {
                setMessageError(false);
              }
              setShowModalNewTerm(false)
            }}
          >
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Assignment htmlColor='#ffb812' />
                <span style={{ fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>&nbsp;CADASTRAR TERMO</span>
              </div>
              <div style={{ color: '#8c8c8c', fontWeight: 'bold', marginTop: 30 }}>MENSAGEM</div>
              <textarea
                cols={59}
                rows={10}
                onChange={(e) => setMessage(e?.currentTarget?.value)}
                placeholder="Digite a mensagem do termo de uso..."
                style={messageError ? {
                  borderRadius: 10,
                  marginTop: 10,
                  resize: 'none',
                  padding: 20,
                  borderColor: '#e60e0e',
                  borderWidth: 2,
                  color: '#000',
                  fontSize: 16
                } :
                  {
                    borderRadius: 10,
                    marginTop: 10,
                    resize: 'none',
                    padding: 20,
                    borderColor: '#eeeef3',
                    borderWidth: 2,
                    color: '#000',
                    fontSize: 16
                  }}
              >
              </textarea>
              {messageError && <span style={{ color: "#e60e0e" }}>campo Obrigatório</span>}

            </div>
          </Modal>
          <Modal
            open={showModalUpdateTerm}
            modalWidth={'700px'}
            handleConfirm={editTerm}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={() => {
              if (editMsgError) {
                setEditMsgError(false);
              }
              setShowModalUpdateTerm(false);
            }}
          >
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Assignment htmlColor='#ffb812' />
                <span style={{ fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>&nbsp;ALTERAR TERMO</span>
              </div>
              <div style={{ color: '#8c8c8c', fontWeight: 'bold', marginTop: 30 }}>MENSAGEM</div>
              <textarea
                cols={59}
                rows={10}
                value={editMessage.value}
                onChange={(e) => {
                  setEditMessage({ ...editMessage, value: e?.currentTarget?.value })
                }}
                placeholder="Digite a mensagem do termo de uso..."
                style={editMsgError ? {
                  borderRadius: 10,
                  marginTop: 10,
                  resize: 'none',
                  padding: 20,
                  borderColor: '#e60e0e',
                  borderWidth: 2,
                  color: '#000',
                  fontSize: 16
                } :
                  {
                    borderRadius: 10,
                    marginTop: 10,
                    resize: 'none',
                    padding: 20,
                    borderColor: '#eeeef3',
                    borderWidth: 2,
                    color: '#000',
                    fontSize: 16
                  }}
              >
              </textarea>
              {editMsgError && <span style={{ color: "#e60e0e" }}>campo Obrigatório</span>}
            </div>
          </Modal>
          <Modal
            open={showModalDel}
            modalWidth={'400px'}
            handleConfirm={deleteTarget}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'EXCLUIR'}
            handleClose={() => setShowModalDel(false)}
          >
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>EXCLUIR TERMO</span>
              </div>
              <div style={{ fontSize: 18, color: '#8c8c8c', fontWeight: 'bold', marginTop: 30 }}>Tem certeza que deseja excluir este termo?</div>
            </div>
          </Modal>
        </Container>
      </Grid>
    </>
  );
};

export default TermOfUse;
