import { Typography, TextField, OutlinedTextFieldProps } from '@material-ui/core';
import React from 'react';

interface IInput extends OutlinedTextFieldProps {
    title?: string
}

const InputText = ({
    title,
    ...props
}: IInput) => {
    const { type } = props;
    let minimunNumber = null;
    if(type === 'number'){
        minimunNumber = 0;
    }
    return (<>
        <Typography  className="subtitle" style={{fontSize:"small",}} >
            {title}
        </Typography>
        <TextField
        inputProps={{ maxLength: 255, min: minimunNumber }} 
        {...props}
        
        size="small" />
    </>)
}
export default InputText;
