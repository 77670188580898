import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { PlaylistAddCheck, Close, Loop } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { createRole } from '../../../services/role-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import { Checkbox, dividerClasses, FormControlLabel } from '@mui/material';
import Modal from '../../../components/shared/Modal/ModalSales';
import API from '../../../services/services';
import LabelGray from '../../../components/shared/Label/LabelGray';

interface IShowQuotation {
    setOpen: any;
    open: boolean;
    quotationSelected: any;
}
const ShowQuotation = (props: IShowQuotation): JSX.Element => {
    const { open, setOpen, quotationSelected } = props;
    const { setAlert } = useContext(AlertContext);

      useEffect(() => {

        function initValues(){
            console.log(quotationSelected);
            //setOrder(String(quotationSelected[1]));
            //setName(quotationSelected[2]);
            //setOptions(quotationSelected[3].map((op: any) => op?.opcao));
        }

        if(open){
            initValues();
        }


    },[open])

    const closeModal = () => {

        setOpen(false);
    }

    return (
        <Modal 
        open={open}
        modalWidth={'700px'}
        handleClose={setOpen}
      >
        <div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Loop htmlColor='#ffb812'/>
            <span style={{fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>&nbsp;DETALHES DA COTAÇÃO</span>
            <div style={{marginLeft: 430, cursor: 'pointer'}} onClick={closeModal}>
                <Close  />
            </div>
          </div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop: 30}}>
              <div style={{ width:'31%'}}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>PLACA</p>
                  <LabelGray label={quotationSelected[3]} />
              </div>
              <div style={{ width:'31%'}}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>MODELO</p>
                  <LabelGray label={quotationSelected[2]} />
              </div>
              <div style={{ width:'31%'}}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>MARCA</p>
                  <LabelGray label={quotationSelected[4]} />
              </div>
          </div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop: 20}}>
              <div style={{ width:'65%'}}>
              <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>PROPRIETÁRIO</p>
              <LabelGray label={quotationSelected[5]} />
              </div>
              <div style={{ width:'31%'}}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>TELEFONE</p>
                  <LabelGray label={quotationSelected[6]} />
              </div>
          </div>
            <div style={{ marginTop:20}}>
            <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>RESUMO</p>
            <LabelGray isLong={true} label={quotationSelected[7]} />
            </div>
        </div>
      </Modal>
    );
};

export default ShowQuotation;
