import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { PlaylistAddCheck } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { createRole } from '../../../services/role-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import { Checkbox, FormControlLabel } from '@mui/material';
import Modal from '../../../components/shared/Modal/ModalSales';
import API from '../../../services/services';
import ChipInput from 'material-ui-chip-input';

interface ICreateCoverage {
    setOpen: any;
    open: boolean;
    fetchData: any;
}
const CreateCoverage = (props: ICreateCoverage): JSX.Element => {
    const { open, setOpen, fetchData } = props;
    const { setAlert } = useContext(AlertContext);
    const [name, setName] = useState('');
    const [options, setOptions] = useState<any[]>([]);

    const [nameError, setNameError] = useState(false);
    const [optionsError, setOptionsError] = useState(false);

    function validation() {
      setNameError(!name);
      setOptionsError(!options.length);

      return (
        !name || !options.length
      );
    }

    useEffect(() => {

        if(!open && nameError || optionsError){
            setNameError(false);
            setOptionsError(false);
        }

    },[open])

    function cleanFields(){
        setName('');
        setOptions([]);
    }

    const createCoverage = async () => {

        if(validation()){
            return;
        }

        const optionsObj = options.map(option => {
            return {opcao:option}
        });

        try {
            const response = await API.post('Cobertura', {
                nome: name,
                descricao: '',
                coberturaOpcao: optionsObj
            });
            snackbar(setAlert, { type: 'success', message: "Cobertura criada com sucesso!" })
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
        }
        cleanFields();
        setOpen(false);
        fetchData();
    }

    return (
        <Modal 
        open={open}
        modalWidth={'700px'}
        handleConfirm={createCoverage}
        colorBtnConfirm={'#30235d'}
        textBtnConfirm={'SALVAR'}
        handleClose={setOpen}
      >
        <div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <PlaylistAddCheck htmlColor='#ffb812'/>
            <span style={{fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>&nbsp;CADASTRAR COBERTURA</span>
          </div>
          <div style={{marginTop: 30}}>
            <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>COBERTURA</p>
            <TextField
              placeholder='Carro Reserva'
              variant='outlined'
              error={nameError}
              helperText={nameError && 'Campo Obrigatório'}
              onChange={({ target:{ value } }) => {
                  setName(value);
              }}
              />
          </div>
          <div style={{ marginTop:20}}>
            <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>VALORES</p>
            <ChipInput
                onChange={(e) => {
                    //console.log(e);
                    setOptions(e);
                }}
                error={optionsError}
                helperText={optionsError && 'Campo Obrigatório'}
                variant="outlined"
                placeholder="Valores"
                style={{width: '100%'}}
            />
            </div>
        </div>
      </Modal>

    );
};

export default CreateCoverage;
