// Componente baseado em: https://material-ui.com/pt/components/modal/#modal
import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Paper, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import { fontSize } from '@mui/system';

export interface ModalProps {
  isButtonResponsive?: boolean
  open: boolean;
  children: JSX.Element;
  modalWidth: string;
  colorBtnConfirm?: string;
  textBtnConfirm?: string;
  handleClose: (open: boolean) => void;
  handleConfirm?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

const ModalWithTransitionSales = (props: ModalProps): JSX.Element => {
  const { open, children, modalWidth, colorBtnConfirm, textBtnConfirm, handleConfirm } = props;
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);


  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const handleClose = (): void => {
    setModalOpen(false);
    props.handleClose(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <>
            <Paper className="modalPaper" style={{ width: modalWidth }}>
              {children}
              { textBtnConfirm && <div style={{ marginTop: "20px",display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Button style={{fontSize: 14, borderWidth:2, borderRadius: "10px", color: "#30235d", borderColor: "#30235d", width: 150}} variant="outlined" size="small" onClick={handleClose}>
                  <p>CANCELAR</p>
                </Button>
                <Button 
                  style={{fontSize: 14, borderWidth:2, borderRadius: "10px", width: 150, color: "#fff", backgroundColor: colorBtnConfirm }}
                  className="button" size="small"
                  onClick={(event: any): void => {
                    handleConfirm !== undefined && handleConfirm(event);
                  }}>
                  <p>{textBtnConfirm}</p>
                </Button>
              </div>}
            </Paper>
          </>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalWithTransitionSales;
