import { Grid, TextField, Typography } from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import Modal from '../../../components/shared/Modal/ModalSales'
import { snackbar } from '../../../utils/util';
import { AlertContext } from '../../../contexts/AlertContext';
import { getGroups } from '../../../services/options-service';
import { IBroker } from '../../../models/Response/IBroker';
import { createUser, getUserById, updateUser } from '../../../services/user-service';
import { IDataGroup, IGroup } from '../../../models/Response/IGroup';
import { getGroup } from '../../../services/grupo-service';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const CpfMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props: any, ref: any) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="#00.000.000-00"
                definitions={{
                    '#': /[0-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const getRawValue = (value: string) => {
    return value.replace(/\D/g, '');
}

const PhoneMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props: any, ref: any) {
        const { onChange, ...other } = props;

        const mask = other?.value[2] === '9' ? '(#0) 00000-0000' : '(#0) 0000-0000'

        return (
            <IMaskInput
                {...other}
                mask={mask}
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

declare interface ICreateRoleBrokerModal {
    open: boolean;
    setOpen: any;
    idBroker?: string;
    refresh: () => void;
}

const initialBroker: IBroker = {
    name: '',
    cpf_cnpj: '',
    phone: '',
    email: '',
    login: '',
    tipoUsuario: 1,
    loginType: 'corretor',
    phone_prefix: 0,
    grupo: ''
}


const CreateBroker = (props: ICreateRoleBrokerModal): JSX.Element => {
    const { open, setOpen, idBroker, refresh } = props;
    const { setAlert } = useContext(AlertContext);
    const [selectedGroupId, setSelectedGroupId] = useState<any>('');
    const [broker, setBroker] = useState<IBroker>(initialBroker);
    const [updateBroker, setUpDateBroker] = useState<IBroker>({...initialBroker, id:''});
    const [formError, setFormError] = useState({
        name: false,
        cpf_cnpj: false,
        phone: false,
        email: false,
    })

    const handleForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setBroker({...broker, [e.target.name]: e.target.value});

        e.target.onblur
        console.log(broker);
    }

    const saveBroker = () => {
        if (idBroker) {
            editBroker();
        } else {
            createBroker();
        }
    }

    const fetchGroups = async () => {
        try {
            const { data: { value } } = await getGroups();
            let response: IGroup[] = value;
            const id = response.filter((element) => element.nome === 'Corretor')[0].id
            setSelectedGroupId(id);
        } catch (error: any) {
          snackbar(setAlert, {
            type: 'error',
            message: error?.response?.data?.message
          });
        }
      }

    const createBroker = async () => {
        try{
            await createUser({
                name: broker?.name,
                login: getRawValue(broker?.cpf_cnpj),
                cpf_cnpj: broker?.cpf_cnpj,
                phone_prefix: Number(broker?.phone.toString().substr(0, 2)),
                phone: Number(broker?.phone.toString().substr(2, broker?.phone.toString().length - 1)),
                email: broker?.email,
                tipoUsuario: broker?.tipoUsuario,
                loginType: 'corretor',
                grupo: selectedGroupId
            })
            setBroker(initialBroker);
            snackbar(setAlert, { type: 'success', message: "Corretor salvo com sucesso" });
        } catch(error: any) {
            setBroker(initialBroker);
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
        }
        refresh();
        setOpen(false);
    }

    const editBroker = async () => {
        try {
            const payload = {
                id: updateBroker?.id,
                name: updateBroker?.name,
                login: getRawValue(updateBroker?.cpf_cnpj),
                cpf_cnpj: updateBroker?.cpf_cnpj,
                phone_prefix: Number(updateBroker?.phone.toString().substr(0, 2)),
                phone: Number(updateBroker?.phone.toString().substr(2, updateBroker?.phone.toString().length - 1)),
                email: updateBroker?.email,
                tipoUsuario: updateBroker?.tipoUsuario,
                loginType: 'corretor',
                grupo: selectedGroupId
            }
            const response = await updateUser(payload);
            snackbar(setAlert, { type: 'success', message: "Corretor atualizado com sucesso" });
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
        }
        setOpen(!open);
        refresh();
    }

    const fetchBrokerById = async () => {
        try {

            const { value } = await getUserById({
                    id: idBroker,
                    loginType: 'corretor' //mudar para corretor
                });
            setUpDateBroker({
                id: value?.id,
                name: value?.nome,
                login: value?.login,
                cpf_cnpj: value?.cpf,
                phone_prefix: value?.phone_prefix,
                phone: value?.telefone,
                email: value?.email,
                tipoUsuario: value?.tipoUsuario,
                loginType: 'corretor',
                grupo:''
            })
            setSelectedGroupId(value?.grupoAspNetbrokers[0]?.grupo?.id);
            
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
        }
    }

    useEffect(() => {
        fetchGroups();
        
        if(idBroker) {
            fetchBrokerById();
        }
        
    }, [idBroker]);
    return (
        <Modal
            open={open}
            modalWidth={'700px'}
            handleConfirm={() => saveBroker()}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={()=> {
                setOpen(!open);
                setUpDateBroker({...initialBroker, id:''});
                setBroker(initialBroker);
            }}
        >
            <Grid container spacing={2}>
                <Grid container alignItems="center" style={{ fontWeight: 'bold', color: '#30235d' }}>
                    <PersonAdd style={{ color: 'orange' }} />

                    &nbsp;&nbsp;CADASTRAR CORRETOR
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">NOME</Typography>
                    <TextField
                        variant="outlined"
                        error={formError.name}
                        helperText={formError.name ? 'Campo Obrigatório!' : ''}
                        required={true}
                        value={idBroker? updateBroker.name : broker.name}
                        name="name"
                        onChange={({target: { value }}): void => {
                                idBroker? setUpDateBroker({ ...updateBroker, name: value }) : setBroker({ ...broker, name: value }) 
                            }
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">CPF</Typography>
                    <TextField
                        variant="outlined"
                        required={true}
                        value={idBroker? updateBroker.cpf_cnpj : broker.cpf_cnpj}
                        onChange={({target: { value }}): void => {
                            idBroker? setUpDateBroker({ ...updateBroker, cpf_cnpj: value }) : setBroker({ ...broker, cpf_cnpj: value }) 
                        }
                    }
                        InputProps={{
                            inputComponent: CpfMaskCustom as any
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">TELEFONE</Typography>
                    <TextField
                        variant="outlined"
                        required={true}
                        value={idBroker? updateBroker.phone : broker.phone}
                        onChange={({target: { value }}): void => {
                            idBroker? setUpDateBroker({ ...updateBroker, phone: getRawValue(value) }) : setBroker({ ...broker, phone: getRawValue(value) }) 
                        }
                    }
                        InputProps={{
                            inputComponent: PhoneMaskCustom as any
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">E-MAIL</Typography>
                    <TextField
                        variant="outlined"
                        required={true}
                        value={idBroker? updateBroker.email : broker.email}
                        onChange={({target: { value }}): void => {
                            idBroker? setUpDateBroker({ ...updateBroker, email: value }) : setBroker({ ...broker, email: value }) 
                        }
                    }
                    />
                </Grid>
            </Grid>
        </Modal>
    );
}

export default CreateBroker;

// ({target: { value }}): void => { setBroker({ ...broker, name: value }) }