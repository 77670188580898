import { Grid, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Container from '../../../components/shared/Container';
import UserTable, { Column, Actions } from '../../../components/shared/Table';
import CreateBroker from '../CreateBrokers/CreateBroker';

import { getUsers, activeUser, removeUser, getBrokers } from '../../../services/user-service';
import { User } from '../../User/UserModel';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import Modal from '../../../components/shared/Modal/ModalSales'
import API from '../../../services/services';
import LabelHeaderTable from '../../../components/shared/Label/LabelHeaderTable';
import { Close, DeleteForever, Group, Loop, PlaylistAddCheck } from '@material-ui/icons';
import { IResponseBroker } from '../../../models/Response/IBroker';

const ListBroker = (): JSX.Element => {
  const [showCreateBroker, setShowCreateBroker] = useState(false);
  const [ showDetailsBroker, setShowDetailsBroker ] = useState(false);
  const [showModalDel, setShowModalDel] = useState(false);

  const [users, setUsers] = useState<User[]>([]);
  const [role, setRole] = useState<User[]>([]);

  const [brokerId, setBrokerId] = useState('');
  const [idToDel, setIdToDel] = useState('');

  const { setAlert } = useContext(AlertContext);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page,
    }));
  };

  const disableEnableUser = async (event: any, id: number) => {
    try {
      const { data } = await activeUser(id);

      if (data.updated) {
        snackbar(setAlert, {
          type: 'success',
          message: 'Ação realizada com sucesso'
        });
      }
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          err?.response?.data?.message
      });
    }

    fetchUsers();
  };

  const columns: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
      }
    },
    {
      name: 'cpf',
      label: 'CPF',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
      }
    },
    {
      name: 'telefone',
      label: 'Telefone',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
      }
    },
    {
      name: 'inativo',
      label: 'Ativo',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        sort: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0];
          let activeColumn = selectedRow.rowData[6]
          // const id = selectedRow.rowData[0];
          // let editBroker: IBroker;
          // let activeColumn = selectedRow.rowData[6];
          // editBroker.id = selectedRow.rowData[0];
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DISABLE',
                  onClick: disableEnableUser,
                  id: id,
                  checked: !activeColumn,
                },
                {
                  type: 'EDITAR',
                  onClick: (): void => {
                    setBrokerId(id);
                    setShowCreateBroker(true);
                  },
                  disabled: !activeColumn
                },
                {
                  type: 'OTHER',
                  onClick: () => setShowDetailsBroker(!showDetailsBroker),
                  icon: <Group />,
                  text: 'SubUsuários'
                  // disabled: state === 'Analise' || state === 'AguardandoPagamento'
                },
                {
                  type: 'OTHER',
                  onClick: () => {
                    setIdToDel(id);
                    setShowModalDel(true);
                  },
                  icon: <DeleteForever />,
                  text: 'exluir',
                  id: id
                },
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchUsers();
    if(showCreateBroker == false) {
      setBrokerId('');
    }
  }, [pagination, showCreateBroker]);

  const fetchUsers = async () => {
    const param = {
      loginType: 'corretor'
    }
    getBrokers()
      .then(({ data }) => {
        data.value.forEach((element: any, index: number) => {
          element.deletado = element.deletado === true ? 'Inativo' : 'Ativo';
        });

        data.value.forEach((element: any, index: number) => {
          element.grupo = element.grupoAspNetUsers[0]?.grupo.nome;
        });

        setUsers([
          ...new Array(pagination.page * pagination.limit),
          ...data.value
        ]);
        setTotal(data.count);
      })
      .catch((error) =>
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message || 'Erro ao listar o usuários',
        }),
      );
  };

  const delBroker = async () => {
    try {
      await removeUser(idToDel)
      snackbar(setAlert, { type: 'success', message: 'Corretor DELETADO com sucesso!' });
      setShowModalDel(false);
      fetchUsers();
    } catch (error) {
      snackbar(setAlert, { type: 'error', message: 'error ao executar exclusão' });
      setShowModalDel(false);
    }
  }

  const deleteMultiple = async (usersId: any) => {
    try {
      await API.post('/users/deactivate-multiple', { ids: usersId });
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });

      //return window.location.reload();
      fetchUsers();
    } catch (ex: any) {
      snackbar(setAlert, { type: 'error', message: ex?.response?.data?.message });
    }
    // finally {
    //   setIsLoaded(true);
    // }
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};
      //const { data } = await API.get<any>(`/users`, params);
      getUsers(
        pagination.page + 1,
        pagination.limit,
        params
      ).then(({ data }) => {
        //console.log(data);

        setTotal(data.total);
        searchText[0] ? onChangeRowsPerPage(total) : onChangeRowsPerPage(10)
        //setUsers(users => users.concat(data.data));
      })
    } catch (err: any) {
      // snackbar(setAlert, {
      //   type: 'error',
      //   message: err?.response?.data?.message,
      // });
    }
  };

    return (
        <>
          <Grid container>
            <Typography className='contextTopBar' variant='h3' component='h2'>Corretores</Typography>
            <Container 
            showButtonOnTop
            textButtonOnTop="Novo Corretor"
            onClickButtonTop={setShowCreateBroker}>
              <Grid xs={12} spacing={1}>
                <UserTable
                  title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Corretores Cadastrados</h3>}
                  columns={columns}
                  data={users}
                  onSearchChange={(event: any) => {
                    return debounce(onSearchChange, 700)(event);
                  }}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  onChangePage={onChangePage}
                  currentPage={pagination.page}
                  totalRecords={total}
                  rowsPerPage={rowsPerPage}
                />
              </Grid>
            </Container>
            <CreateBroker 
              open={showCreateBroker}
              setOpen={setShowCreateBroker}
              refresh={() => {
                fetchUsers();
              }}
              idBroker={brokerId}
            />
             <Modal 
                open={showDetailsBroker}
                modalWidth={'700px'}
                handleClose={setShowDetailsBroker}
              >
             <Grid container spacing={2}>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <PlaylistAddCheck style={{ color: 'orange' }} />
                <span style={{fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>&nbsp;DETALHES DO CORRETOR</span>
                <div style={{marginLeft: 420, cursor: 'pointer'}} onClick={() => setShowDetailsBroker(!showDetailsBroker)}>
                  <Close  />
                </div>
              </div>
              <p>
                Corretor fulano
              </p>   
            </Grid>
          </Modal>
          <Modal 
            open={showModalDel}
            modalWidth={'400px'}
            handleConfirm={delBroker}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SIM'}
            handleClose={setShowModalDel}
          >
            <div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <span style={{fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>EXCLUIR CORRETOR</span>
              </div>
              <div style={{ fontSize: 18, color: '#8c8c8c', marginTop: 30}}>Tem certeza que deseja excluir este corretor?</div>
            </div>
          </Modal>
            
          </Grid>
        </>
    )
}
export default ListBroker;