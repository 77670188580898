import React, { FormEvent } from 'react';
import {
    Grid,
    Typography,
    Button,
    LinearProgress,
    FormControl,
    TextField,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { LogoGrande } from '../../../components/shared/Logo';
import '../LoginPage.css';
import { ChevronLeft, } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';


interface ILogin {
    showResend: boolean;
    onChangeCpfLogin?: (event: any) => void;
    onSubmitPostForgot?: (event: FormEvent<HTMLFormElement>) => void;
    onRequestLogin?: boolean;
    cpfErrorLogin?: boolean;
    onClickStep?: () => void;
    onClickSendEmail?: () => void;
    onClickShowResend?: () => void;
    captchaForgot?: boolean;
    onChangeCaptcha?: (event: any) => void;
    captcha?: string;
    email: string;
}

const ForgotPasswordComponent = ({
    showResend,
    onChangeCpfLogin,
    captcha,
    onRequestLogin,
    cpfErrorLogin,
    captchaForgot,
    onChangeCaptcha,
    onClickStep,
    onClickSendEmail,
    onClickShowResend,
    email
}: ILogin) => {
    const routerHistory = useHistory();
    return (<>
        <div>
            <div className="containerForgotPasswordPage">
                {/* <LogoGrande className="containerLogo" width="100" height="50" /> */}
                <div className="containerCredentials">
                    <Grid
                        className="gridContainerLogo"
                        container
                        spacing={0}
                        justifyContent="center"
                    >
                        <Grid item xs={12}>
                            {onRequestLogin ? (
                                <LinearProgress color="primary" />
                            ) : (
                                <div style={{ height: '4px' }} />
                            )}
                        </Grid>
                        <Grid
                            className="gridContainerCredentials"
                            container
                            spacing={2}
                            justifyContent="center"
                        >
                            <Grid item xs={10}>
                                <Grid className="headerCredentials">
                                    <Typography
                                        className="titleHeader"
                                        variant="h5"
                                        component="h1"
                                        align="left"
                                    >
                                        {showResend ? <b>Esqueci minha senha</b> : <b>Olhe sua caixa de e-mails</b>}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {
                                showResend ? (
                                    <>
                                        <Grid item xs={10}>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <Typography className="subtitle" align="left">
                                                    INFORME O E-MAIL CADASTRADO
                                                </Typography>
                                                <TextField
                                                    onChange={onChangeCpfLogin}
                                                    error={cpfErrorLogin}
                                                    helperText={cpfErrorLogin ? 'Campo Obrigatório!' : ' '}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <Typography className="subtitle">RESPONDA A PERGUNTA ABAIXO</Typography>
                                                <TextField
                                                    onChange={onChangeCaptcha}
                                                    placeholder={captcha}
                                                    variant="outlined"
                                                    error={captchaForgot}
                                                    helperText={captchaForgot ? 'Responda a pergunta!' : ' '}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Button
                                                style={{marginBottom: 20, backgroundColor:'#ffac00'}}
                                                onClick={onClickSendEmail}
                                                className="button CTA loginButton"
                                                disabled={onRequestLogin}
                                                size="large"
                                            >
                                                {onRequestLogin ? 'Enviando email' : 'Recuperar senha'}
                                            </Button>
                                        </Grid>
                                        <Button
                                            onClick={onClickStep}
                                            className="buttonVoltar"
                                        >
                                            <ChevronLeft style={{ color: "#fff" }} fontSize="medium" /> <b style={{ color: "#fff", height: "18px", fontSize: "12px" }}> VOLTAR</b>
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={10}>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <Typography className="subtitleHeader" align="left" >
                                                    As instruções para a recuperação da senha, foram enviadas para o e-mail: <br />
                                                    <span style={{color: '#ffac00'}}>{email}</span>
                                                    
                                                </Typography>

                                                {<Grid style={{ paddingTop: '20px' }}>
                                                    <Typography 
                                                         className="titleHeader"
                                                         variant="h6"
                                                         component="h1"
                                                         align="left"
                                                    >
                                                       <b>Não recebeu o e-mail ?</b>
                                                    </Typography>
                                                </Grid>}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={10}>
                                            <Button
                                                onClick={onClickSendEmail}
                                                //onClick={() =>{routerHistory.push('/'); window.location.reload(); }}
                                                className="button CTA loginButton"
                                                disabled={onRequestLogin}
                                                size="large"
                                                style={{marginBottom: 20, backgroundColor:'#ffac00'}}
                                            >
                                                {onRequestLogin ? 'ENVIANDO E-MAIL' : 'REENVIAR O E-MAIL'}
                                            </Button>
                                        </Grid>
                                        {<Button

                                            onClick={onClickShowResend}
                                            className="buttonVoltar"
                                        >
                                            <ChevronLeft style={{ color: "#fff" }} fontSize="small" /> <b style={{ color: "#fff", height: "18px", fontSize: "11px" }}> VOLTAR</b>
                                        </Button>}
                                    </>
                                )
                            }



                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    </>)
}

export default ForgotPasswordComponent