import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import './styles.css';


type ContainerProps = {
  children: JSX.Element;
  title: string;
  showDownloadButton: boolean;
  order: any;
  showButtonOnTop: boolean;
  textButtonOnTop?: string;
  routerLink?: string;
  showButtonOnTopBack?: boolean;
  textButtonOnTopBack?: string;
  routerLinkBack?: string;
  showButtonAprove?: boolean;
  textButtonAprove?: string;
  showButtonReprove?: boolean;
  textButtonReprove?: string;
  disableBtn?: boolean;
  disableBtnOnTop?: boolean;
  onClickButtonAprove?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  onClickButtonReprove?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  onClickButtonTop?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
};


const Container = (props: ContainerProps & any): JSX.Element => {
  const {
    children,
    title,
    showButtonOnTop,
    showAuxButtonTop,
    textButtonAux,
    textButtonOnTop,
    routerLink,
    showDownloadButton,
    order,
    showButtonOnTopBack,
    textButtonOnTopBack,
    routerLinkBack,
    onClickButtonTop,
    showButtonAprove,
    textButtonAprove,
    showButtonReprove,
    textButtonReprove,
    onClickButtonAprove,
    onClickButtonReprove,
    disableBtn,
    disableBtnOnTop,
    ...restOfProps
  } = props;

  return (
    <Grid item xs={12} {...restOfProps}>

      <div className="flex flexWrap flexJustifySpaceAround">
        <Grid container justifyContent={showButtonOnTopBack ? "space-between" : "flex-end"}>
          {showButtonOnTopBack &&
            <Button
              style={{ backgroundColor: "#fff", color: "#006193", fontWeight: "bold" }}
              startIcon={<ArrowBackIos />}
              className="buttonContainedRounded"
              component={RouterLink}
              to={routerLinkBack}
            >
              {textButtonOnTopBack}
            </Button>
          }
          {/* {showDownloadButton &&
            <DownloadButton 
              order={order}/> 
          } */}
          {showButtonOnTop &&
            (routerLink ? (
              <Button
                style={{ position: 'absolute', top: 65, right: 15, color: '#fff', backgroundColor: '#30235d', borderRadius: 10, padding: 6}}
                component={RouterLink}
                to={routerLink}
              >
                {textButtonOnTop}
              </Button>
            ) : onClickButtonTop ? (
              <Button
                style={{ position: 'absolute', top: 65, right: 15, color: '#fff', backgroundColor: '#30235d', borderRadius: 10, padding: 6}}
                onClick={(event: any): void => {
                  onClickButtonTop(event);
                }}
                disabled={disableBtnOnTop}
              >
                {textButtonOnTop}
              </Button>
            ) : (
              <Button className="button buttonContainedRounded buttonContainedColorPrimary CTA">
                {textButtonOnTop}
              </Button>
            ))}
          {showAuxButtonTop &&
            <Button
              className="button buttonContainedRounded buttonAux CTA"
              component={RouterLink}
              to={routerLink}
            >
              {textButtonAux}
            </Button>
          }
          
          
        </Grid>
      </div>
      <Paper className="paper_content">
        {children}
        <div style={{ padding: "10px" }}>
          {showButtonReprove && <Button
            disabled={disableBtn || false}
            style={{ margin: '3px', backgroundColor: "#f4694c" }}
            className="button buttonContainedRounded CTA"
            onClick={(event: any): void => {
              onClickButtonReprove(event);
            }}
          >
            {textButtonReprove}
          </Button>}
          {showButtonAprove && <Button
            disabled={disableBtn || false}
            style={{ margin: '3px', backgroundColor: "#40b2b7" }}
            className="button buttonContainedRounded CTA"
            onClick={(event: any): void => {
              onClickButtonAprove(event);
            }}
          >
            {textButtonAprove}
          </Button>}
        </div>
      </Paper>

    </Grid>
  );
};

export default Container;
