import React, { useState, useEffect, useContext } from 'react';
import QuotationTable, { Column, Actions, StatusOfColumn } from '../../components/shared/Table';
import { Button, Grid, TextField, Typography, InputAdornment, IconButton, Switch } from '@material-ui/core';
import Container from '../../components/shared/Container';
//import { getUsers, activeUser } from '../../../services/user-service';
import { AlertContext } from '../../contexts/AlertContext';
import { snackbar } from '../../utils/util';
import API from '../../services/services';
import { downloadPdfQuotation } from '../../services/policies-service';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import DownloadIcon from '@mui/icons-material/Download';
import LabelHeaderTable from '../../components/shared/Label/LabelHeaderTable';
import { DeleteForever, Launch, CloudDownload, Assignment, CloudUpload, Loop, DirectionsCar, Visibility, Security, SecurityTwoTone } from '@material-ui/icons';
import Modal from '../../components/shared/Modal/ModalSales'
import { borderRadius, fontStyle } from '@mui/system';
import './styles.css';
import ShowQuotation from './ShowQuotation';
import FinalizeQuotation from './FinalizeQuotation';
import DeleteQuotation from './DeleteQuotation';

interface iQuotation {
  id: number;
  name: string;
  modelo: string;
  phone: string;
  abstract: string;
}

const Quotation: React.FC = () => {
  const [quotation, setQuotation] = useState<iQuotation[]>([] as iQuotation[]);
  const { setAlert } = useContext(AlertContext);
  const [id] = useState(0);

  const [idToDel, setIdToDel] = useState('');

  const [selectedQuotation, setSelectedQuotation] = useState<any[]>([]);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalFinalize, setShowModalFinalize] = useState(false);
  const [showModalDel, setShowModalDel] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setQuotation([]);
  };
  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page,
    }));
  };

  /*const disableEnableUser = async (event: any, id: number) => {
    try{
      const { data } = await activeUser(id, !event.target.checked);
      
      if(data.updated){
        snackbar(setAlert, {
          type: 'success',
          message: 'Ação realizada com sucesso'
        });
      }
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          err?.response?.data?.message
      });
    }
    
    fetchUsers();
  };
  */
  const columns: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'modelo',
      label: 'Modelo',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'placa',
      label: 'Placa',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'marca',
      label: 'Marca',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'proprietarioAntigo',
      label: 'Proprietário',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'telefone',
      label: 'Telefone',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'resumo',
      label: 'Resumo',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0] as number;
          return (
            <Actions
              buttonProps={[
                {
                  type: 'OTHER',
                  onClick: () => {
                    setSelectedQuotation(selectedRow.rowData);
                    setShowModalDetails(true);
                  },
                  icon: <Visibility />,
                  text: 'visualizar',
                  id: id,
                },
                {
                  type: 'OTHER',
                  onClick: () => setShowModalFinalize(true),
                  icon: <RemoveModeratorIcon />,
                  text: 'finalizar',
                  id: id
                },
                {
                  type: 'OTHER',
                  onClick: () => {
                    setIdToDel(selectedRow.rowData[0]);
                    setShowModalDownload(true);
                  },
                  icon: <DeleteForever />,
                  text: 'exluir',
                  id: id
                },
                {
                  type: 'OTHER',
                  onClick: () => { 
                    debugger
                    download(selectedRow.rowData[0])
                  },
                  icon: <DownloadIcon />,
                  text: 'download',
                  id: id
                },
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchQuotation();
  }, [pagination]);

  async function fetchQuotation() {
    try {
      const response = await API.get<any>('Cotacao');
      console.log(response);
      setQuotation(response.data?.value);
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
    }
  };

  const download = async (id: string) => {
    try {
      debugger
      const file = await downloadPdfQuotation(id);
      const a = document.createElement('a');
      a.href = file?.request?.responseURL
      a.download = file?.data
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
  }

  const downloadBeforeDelete = async () => {
    try {
      debugger
      const file = await downloadPdfQuotation(idToDel);
      const a = document.createElement('a');
      //a.target = '_blank'
      a.href = file?.request?.responseURL
      a.download = file?.data
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setShowModalDownload(false);
      setShowModalDel(true);
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
  }

  const delQuotation = async () => {
    try {
      const response = await API.delete('Cotacao', idToDel);
      snackbar(setAlert, { type: 'success', message: 'Cotação DELETADA com sucesso!' });
      fetchQuotation();
      setShowModalDel(false);
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
    }
  };

  return (
    <>
      <Grid container>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Cotações
        </Typography>
        <Container>
          <Grid item xs={12}>
            <Grid style={{ padding: "10px" }}>
              <QuotationTable
                title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Consultar Cotações</h3>}
                columns={columns}
                data={quotation}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                currentPage={pagination.page}
                totalRecords={total}
                rowsPerPage={rowsPerPage}
              />
            </Grid>
          </Grid>
          <ShowQuotation
            open={showModalDetails}
            setOpen={setShowModalDetails}
            quotationSelected={selectedQuotation}
          />
          <FinalizeQuotation
            open={showModalFinalize}
            setOpen={setShowModalFinalize}
          />
          <Modal 
            open={showModalDownload}
            modalWidth={'400px'}
            handleConfirm={downloadBeforeDelete}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SIM'}
            handleClose={setShowModalDownload}
          >
            <div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <span style={{fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>EXCLUIR COTAÇÃO</span>
              </div>
              <div style={{ fontSize: 18, color: '#8c8c8c', marginTop: 30}}>Antes de exluir a cotação, deve ser feito o download da mesma. Deseja baixar agora?</div>
            </div>
          </Modal>
          <Modal 
            open={showModalDel}
            modalWidth={'400px'}
            handleConfirm={delQuotation}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SIM'}
            handleClose={() => setShowModalDel(false)}
          >
            <div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <span style={{fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>EXCLUIR COTAÇÃO</span>
              </div>
              <div style={{ fontSize: 18, color: '#8c8c8c', marginTop: 30}}>Tem certeza que deseja excluir esta cotação?</div>
            </div>
          </Modal>
        </Container>
      </Grid>
    </>
  );
};

export default Quotation;
