export const summaryMoney = (data: any[]) => {
  if (data.length <= 0) return 0;
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'usd',
  });

  return formatter.format(
    data.reduce((prev: any, next: any) => {
      return +prev + +next;
    }),
  );
};

export const getUnique = (arr: any, comp: any) => {
  // store the comparison  values in array
  const unique = arr
    .map((e: any) => e[comp])

    // store the indexes of the unique objects
    .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter((e: any) => arr[e])
    .map((e: any) => arr[e]);

  return unique;
};

export const filterArrayWithOther = (
  arrayToFilter: any[],
  arr: any[],
  field: string,
) => arrayToFilter.filter((el) => !arr.some((f) => f[field] === el[field]));

export const returnIfNotEmpty = (t: string, o: any | any[]) => {
  if (t === 'object') return Object.keys(o).length > 0 ? o : undefined;

  if (t === 'arr') return o.length > 0 ? o : undefined;
};

export const removeDuplicates = (myArr: any[], prop: string | number) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const validateEmail = (email: string) => {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

export const validateCpf = (cpf: string) => {
  
  if(!cpf){
    return true;
  }
  
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.trim() === '') return true;
  // Elimina CPFs invalidos conhecidos	
  if (cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999")
    return true;
  // Valida 1o digito	
  let add = 0;
  for (let i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(9)))
    return true;
  // Valida 2o digito	
  add = 0;
  for (let i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(10)))
    return true;
  return false;

}

export const validateDate = (date: string) => {
  const regexp = /[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}/g;
  return regexp.test(date);
}

export const validateCnpj = (cnpj: string) => {
  
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.trim() === '') return true;

  if (cnpj.length !== 14)
    return true;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999")
    return true;

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2)
      pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== Number(digitos.charAt(0)))
    return true;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2)
      pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != Number(digitos.charAt(1)))
    return true;

  return false;
}

export const validateCep = (cep: string) => {
  const regexp = /[0-9]{5}[-][0-9]{3}/g;
  return regexp.test(cep);
}

export const snackbar = (setAlert: any, alertConfig: any) => {
  setAlert({ open: true, ...alertConfig });
  setTimeout(() => { setAlert({ open: false }) }, 3 * 1000);
}

export const formatCpf = (cpf: string) => {
  cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
    function (regex, arg1, arg2, arg3, arg4) {
      return arg1 + '.' + arg2 + '.' + arg3 + '-' + arg4;
    })
  return cpf;
}

export const formatCnpj = (cnpj: string) => {
  cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    function (regex, arg1, arg2, arg3, arg4, arg5) {
      return arg1 + '.' + arg2 + '.' + arg3 + '/' + arg4 + '-' + arg5;
    })
  return cnpj;
}

export const formatPhone = (phone: string) => {
  phone = phone.replace(/(\d{2})(\d{5})(\d{4})/,
    function (regex, arg1, arg2, arg3) {
      return '(' + arg1 + ') ' + arg2 + '-' + arg3;
    })
  return phone;
}

export const formatDateField = (date: string) => {
  let dd = date.split('-')[2].replace('T00:00:00.000Z', '');
  let mm = date.split('-')[1];
  let year = date.split('-')[0];

  var newDate = year + '-' + mm + '-' + dd;
  return newDate;
}

export const formatDateBr = (date: string) => {
  let dd = date.split('-')[2].replace('T00:00:00.000Z', '');
  let mm = date.split('-')[1];
  let year = date.split('-')[0];

  var newDate = dd + '/' + mm + '/' + year;
  return newDate;
}

export const getYearDate = (date: string) => {
  let year = date.split('-')[0];
  return year;
}

export const getMonthDate = (date: string) => {
  let month = date.split('-')[1];
  let extenseMonth = '';

  switch (month) {
    case '01':
      extenseMonth = 'Janeiro'
      break
    case '02':
      extenseMonth = 'Fevereiro'
      break
    case '03':
      extenseMonth = 'Março'
      break
    case '04':
      extenseMonth = 'Abril'
      break
    case '05':
      extenseMonth = 'Maio'
      break
    case '06':
      extenseMonth = 'Junho'
      break
    case '07':
      extenseMonth = 'Julho'
      break
    case '08':
      extenseMonth = 'Agosto'
      break
    case '09':
      extenseMonth = 'Setembro'
      break
    case '10':
      extenseMonth = 'Outubro'
      break
    case '11':
      extenseMonth = 'Novembro'
      break
    case '12':
      extenseMonth = 'Dezembro'
      break
  }
  return extenseMonth;
}