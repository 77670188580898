import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '../../components/shared/Container';
import logo from '../../assets/bird_logo.png';
import { Store, Receipt, ShoppingCart } from '@material-ui/icons';
import './styles.css'
import { useHistory } from 'react-router-dom';

const Home: React.FC = () => {

  const routeHistory = useHistory();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    function loadPermissionSales() {
      // const tkn = localStorage.getItem('jwtSecurityToken');
      // let info_user_tkn = Buffer.from(`${tkn?.split('.')[1]}`, "base64").toString();
      // const info_user = JSON.parse(info_user_tkn);
      // if(info_user.roles[0].id === 3){
      // }
      setIsClient(true);
    }
    loadPermissionSales()
  }, []);

  return (
    <Grid container spacing={3}>
      <Typography className="contextTopBar" variant="h3" component="h2">
        Seja bem-vindo(a)
      </Typography>
      
    </Grid>
  );
}

export default Home;
