import { useContext, useState, useEffect } from 'react';
import { AlertContext } from '../../../contexts/AlertContext';
import TableListRole, {
  StatusColumnSales,
  Column,
  Actions,
  customFilterDate
} from '../../../components/shared/Table';
import React from 'react';
import API from '../../../services/services';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Decision } from '../../../components/shared/Alert';
import Container from '../../../components/shared/Container';
import { format } from 'date-fns';
import { IRole } from '../../Role/RoleModel';
import CreateRoleModal from '../../Role/CreateRole/CreateRoleModal';
// import ListUsersModal from '../ListUsersModal/ListUsersModal';
import { snackbar } from '../../../utils/util';
// import EditRoleModal from '../EditRoleModal/EditRoleModal';
import LabelHeaderTable from '../../../components/shared/Label/LabelHeaderTable';
import nProgress from 'nprogress';
import { getRoles } from '../../../services/role-service';
import moment from 'moment';
import { DeleteForever, Launch } from '@material-ui/icons'; import CreatePromotionModal from '../CreatePromotion/CreatePromotionModal';
import EditPromotionModal from '../EditPromotion/EditPromotionModal';
import Modal from '../../../components/shared/Modal/ModalSales';
import { ISale } from '../../../models/ISales';
import { activateDeactivateSale, deleteSale, getSales } from '../../../services/sales-services';

const PromotionList = (): JSX.Element => {
  const { setAlert } = useContext(AlertContext);
  const [isLoading, setIsLoading] = useState(false);
  const [id] = useState(0);
  const [showDeleteDecisionAlert, setShowDeleteDecisionAlert] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<any[]>([]);
  const [sales, setSales] = useState<ISale[]>([]);
  const [selectedSaleId, setSelectedSaleId] = useState<string>('');

  const [rowIndex, setrowIndex] = useState<any>();
  const [showCreateSale, setShowCreateSale] = useState(false);
  const [showEditSale, setShowEditSale] = useState(false);
  const [showListUsersModal, setShowListUsersModal] = useState(false);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: Column[] = [
    {
      name: 'id',
      label: 'Cód.',
      options: {
        display: false,
        sort: false,
        viewColumns: false,
        filter: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'deletado',
      label: 'Situação',
      options: {
        display: false,
        viewColumns: false
      }
    },
    {
      name: 'titulo',
      label: 'Título',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'resumo',
      label: 'Resumo',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const rowId = selectedRow.rowData[0];
          const status = selectedRow.rowData[1];
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DISABLE',
                  onClick: () => changeSaleStatus(rowId),
                  checked: status,
                  id: id,
                },
                {
                  type: 'OTHER',
                  onClick: () => openEditSale(rowId),
                  icon: <Launch />,
                  text: 'Editar',
                  disabled: !status,
                  id: id
                },
                {
                  type: 'OTHER',
                  onClick: () => openRemoveSale(rowId),
                  icon: <DeleteForever />,
                  text: 'Excluir',
                  disabled: !status,
                  id: id
                }
              ]}
            />
          );
        },
      },
    },
  ];
  const [roles, setRoles] = useState<IRole[]>([] as any[]);
  const [lastPage, setLastPage] = useState(-1);

  const promotions = [{ titulo: "Título", texto: "Texto da Promoção" }];

  useEffect(() => {
    fetchSales();
  }, [pagination]);

  const openEditSale = (id: string) => {
    setShowEditSale(true)
    setSelectedSaleId(id);
  }

  const openRemoveSale = (id: string) => {
    setShowDeleteDecisionAlert(true);
    setSelectedSaleId(id);
  }

  async function fetchSales() {
    setIsLoading(true);
    try {
      const { data: { value } } = await getSales()
      setSales(value)
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
    }
    setIsLoading(false);
  };

  async function changeSaleStatus(id: any) {
    var sale = sales.find(obj => {
      return obj.id == id;
    })
    const checkForActiveSales = sales?.some(e => {
      return e?.deletado === true
    })
    if (checkForActiveSales && !sale?.deletado) {
      snackbar(setAlert, { type: 'warning', message: 'Não é possível ter duas promoções ativas ao mesmo tempo', timeToClose: 5000 });
    } else {
      try {
        sale.deletado = !sale.deletado;
        await activateDeactivateSale(sale)
        snackbar(setAlert, { type: 'success', message: 'Ação realizada com sucesso' });
        fetchSales()
      } catch (error: any) {
        snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
      }
    }
  };

  async function removeSale(id: string) {
    try {
      await deleteSale(id);
      snackbar(setAlert, { type: 'success', message: 'Ação realizada com sucesso' });
      setShowDeleteDecisionAlert(false);
      fetchSales();
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
    }
  };

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setRoles([]);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page
    }));
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  return (
    <>
      <Grid container spacing={0}>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Promoções
        </Typography>

        <Container
          hidden={isLoading}
          title="Listagem de Grupos"
          showButtonOnTop
          textButtonOnTop="Nova Promoção"
          onClickButtonTop={setShowCreateSale}
        >
          <Grid style={{ padding: "10px" }}>
            <TableListRole
              title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Consultar Promoções</h3>}
              columns={columns}
              data={sales}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={onChangePage}
              currentPage={pagination.page}
              totalRecords={total}
              rowsPerPage={rowsPerPage}
            />
          </Grid>
        </Container>
        {isLoading && (
          <Skeleton
            className="skeleton"
            variant="rect"
            animation="wave"
            width="100%"
            height={500}
          />
        )}
        <Modal
          open={showDeleteDecisionAlert}
          modalWidth={'400px'}
          handleConfirm={() => removeSale(selectedSaleId)}
          colorBtnConfirm={'#30235d'}
          textBtnConfirm={'EXCLUIR'}
          handleClose={() => setShowDeleteDecisionAlert(false)}
        >
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>EXCLUIR PROMOÇÃO</span>
            </div>
            <div style={{ fontSize: 18, color: '#8c8c8c', fontWeight: 'bold', marginTop: 30 }}>Tem certeza que deseja excluir esta promoção?</div>
          </div>
        </Modal>
        <CreatePromotionModal
          open={showCreateSale}
          setOpen={setShowCreateSale}
          refresh={() =>
            fetchSales()
          }
        />
        <EditPromotionModal
          open={showEditSale}
          setOpen={setShowEditSale}
          id={selectedSaleId}
          refresh={() =>
            fetchSales()
          }
        />
      </Grid>
    </>
  );
};

export default PromotionList;



