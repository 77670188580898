import { useContext, useState, useEffect } from 'react';
import { AlertContext } from '../../../contexts/AlertContext';
import TableListRole, {
  StatusColumnSales,
  Column,
  Actions,
  customFilterDate
} from '../../../components/shared/Table';
import React from 'react';
import API from '../../../services/services';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Decision } from '../../../components/shared/Alert';
import Container from '../../../components/shared/Container';
import { format } from 'date-fns';
import { IRole } from '../../Role/RoleModel';
import CreateRoleModal from '../../Role/CreateRole/CreateRoleModal';
// import ListUsersModal from '../ListUsersModal/ListUsersModal';
import { snackbar } from '../../../utils/util';
// import EditRoleModal from '../EditRoleModal/EditRoleModal';
import LabelHeaderTable from '../../../components/shared/Label/LabelHeaderTable';
import nProgress from 'nprogress';
import { getRoles } from '../../../services/role-service';
import moment from 'moment';
import { User } from '../../User/UserModel';
import CreateAttendanceModal from '../CreateAttendance/CreateAttendanceModal';
import EditAttendanceModal from '../EditAttendance/EditAttendanceModal';
import Modal from '../../../components/shared/Modal/ModalSales';


const AttendanceList = (): JSX.Element => {
  const { setAlert } = useContext(AlertContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [id] = useState(0);
  const [showDeleteDecisionAlert, setShowDeleteDecisionAlert] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState<any[]>([]);
  const [attendance, setAttendance] = useState<any>([]);

  const [idToDel, setIdToDel] = useState(0);

  const [rowIndex, setrowIndex] = useState<any>();
  const [showCreatePermission, setShowCreatePermission] = useState(false);
  const [showEditPermission, setShowEditPermission] = useState(false);
  const [showListUsersModal, setShowListUsersModal] = useState(false);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: Column[] = [
    {
      name: 'id',
      label: 'Cód.',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'ordem',
      label: 'Ordem',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'tipo',
      label: 'Tipo',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'descricao',
      label: 'Descrição',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'mensagem',
      label: 'Cód.',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        customBodyRender: (value) => <>{value ? 'Ativado' : 'Desativado'}</>
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0] as number;
          const activeColumn = selectedRow.rowData[6];
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DISABLE',
                  onClick: disableEnableAttendance,
                  id: id,
                  checked: activeColumn,
                },
                {
                  type: 'EDITAR',
                  onClick: (): void => {
                    setSelectedAttendance(selectedRow.rowData);
                    setShowEditPermission(true);
                  },
                  disabled: (selectedRow.rowData[0] == 1 || selectedRow.rowData[0] == 2 || selectedRow.rowData[0] == 3)
                },
                {
                  type: 'DELETAR',
                  onClick: (): void => {
                    setIdToDel(selectedRow.rowData[0]);
                    setShowDeleteDecisionAlert(true);
                  }
                },
              ]}
            />
          );
        },
      },
    },
  ];
  const [roles, setRoles] = useState<IRole[]>([] as any[]);
  const [lastPage, setLastPage] = useState(-1);

  useEffect(() => {
    fetchAttendance();
  }, [pagination]);

  async function disableEnableAttendance(event: any, id: number) {
    try {
      const response = await API.delete(`Atendimento/ativarDesativar`, id);


      snackbar(setAlert, {
        type: 'success',
        message: 'Ação realizada com sucesso!'
      });

    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          err?.response?.data?.errors[0]
      });
    }

    fetchAttendance();
  }

  async function fetchAttendance() {
    //setIsLoaded(true);

    try {
      const response = await API.get<any>('Atendimento');
      //console.log(response);
      setAttendance(response.data.value);
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', ex: error?.response?.data?.errors[0] })
    }

    // setIsLoaded(false);
    // await API.get<any>(`/roles?sorters=name&page=${pagination.page + 1}&limit=${pagination.limit}`)
    //   .then(({ data }) => {
    //     //console.log(data);

    //     // setRoles((old) => [...new Array(pagination.page * pagination.limit), ...data.data]);
    //     setRoles([
    //       ...new Array(pagination.page * pagination.limit),
    //       ...data.data,
    //     ]);
    //     setTotal(data.total);
    //     setLastPage(pagination.page);
    //   })
    //   .catch((error) => snackbar(setAlert, { type: 'error', ex: error }))
    //   .finally(() => setIsLoaded(true));

  };

  const deleteTarget = async () => {


    try {
      const response = await API.delete('/Atendimento', idToDel);
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso!',
      });
      fetchAttendance();
    } catch (ex: any) {
      //snackbar(setAlert, { type: 'error', message: '' });
      snackbar(setAlert, { type: 'error', message: ex?.response?.data?.errors[0] });
    }

    setShowDeleteDecisionAlert(false);
    // } finally {
    //   setIsLoaded(true);
    // }
  };

  const deleteMultiple = async (rolesId: any) => {
    try {
      await API.post('/roles/delete-multiple', { ids: rolesId });
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });

      //return window.location.reload();
      await fetchAttendance();
    } catch (ex) {
      //   snackbar(setAlert, { type: 'error', message: ex?.response?.data?.message });
      snackbar(setAlert, { type: 'error', message: '' });

    } finally {
      setIsLoaded(true);
    }
  };

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setRoles([]);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page
    }));
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {

    const params = searchText[0]
      ? {
        search: {
          fields: ['name'],
          value: searchText.length ? searchText : null,
        },

      }
      : {};
    getRoles(
      pagination.page + 1,
      pagination.limit,
      params
    ).then(({ data }) => {
      setTotal(data.total);
      searchText[0] ? onChangeRowsPerPage(total) : onChangeRowsPerPage(10)

      setRoles(roles => roles.concat(data.data));
    })
  };

  return (
    <>
      <Grid container spacing={0}>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Atendimentos
        </Typography>

        <Container
          hidden={isLoaded}
          headerTable="Grupos cadastrados"
          title="Listagem de Grupos"
          showButtonOnTop
          textButtonOnTop="Novo Atendimento"
          onClickButtonTop={setShowCreatePermission}
        >
          <Grid style={{ padding: "10px" }}>
            <TableListRole
              title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Consultar Atendimentos</h3>}
              columns={columns}
              data={attendance || []}
              onSearchChange={(event: any) =>
                debounce(onSearchChange, 700)(event)
              }
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={onChangePage}
              currentPage={pagination.page}
              totalRecords={total}
              rowsPerPage={rowsPerPage}
              onRowsDelete={(indexRole) => {
                const rolesId = roles
                  .filter((_, i) => indexRole.find((x) => x.index === i))
                  .map((id) => id.id);

                deleteMultiple(rolesId);
              }}
            />
          </Grid>
        </Container>
        {isLoaded && (
          <Skeleton
            className="skeleton"
            variant="rect"
            animation="wave"
            width="100%"
            height={500}
          />
        )}
        <Modal
          open={showDeleteDecisionAlert}
          modalWidth={'400px'}
          handleConfirm={deleteTarget}
          colorBtnConfirm={'#30235d'}
          textBtnConfirm={'EXCLUIR'}
          handleClose={() => setShowDeleteDecisionAlert(false)}
        >
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>EXCLUIR ATENDIMENTO</span>
            </div>
            <div style={{ fontSize: 18, color: '#8c8c8c', fontWeight: 'bold', marginTop: 30 }}>Tem certeza que deseja excluir este atendimento?</div>
          </div>
        </Modal>
        <CreateAttendanceModal
          open={showCreatePermission}
          setOpen={setShowCreatePermission}
          //refresh={() => fetchRoles()}
          refresh={() => {
            fetchAttendance();
          }
          }
        />
        <EditAttendanceModal
          open={showEditPermission}
          setOpen={setShowEditPermission}
          //refresh={() => fetchRoles()}
          refresh={() => {
            fetchAttendance();
          }
          }
          att={selectedAttendance}
        />
      </Grid>
    </>
  );
};

export default AttendanceList;



