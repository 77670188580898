import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { PlaylistAddCheck } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { createRole } from '../../../services/role-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import { Checkbox, FormControlLabel } from '@mui/material';
import Modal from '../../../components/shared/Modal/ModalSales';
import API from '../../../services/services';

interface IFaq{
    id: number;
    ordem: number;
    pergunta: string;
    resposta: string;
}

interface IEditFAQModal {
    setOpen: any;
    open: boolean;
    refresh: () => void;
    faq: IFaq;
}
const EditFAQModal = (props: IEditFAQModal): JSX.Element => {
    const { open, setOpen, refresh, faq } = props;
    const { setAlert } = useContext(AlertContext);

    const [order, setOrder] = useState('');
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');

    const [orderError, setOrderError] = useState(false);
    const [questionError, setQuestionError] = useState(false);
    const [answerError, setAnswerError] = useState(false);

    function validation() {
      setOrderError(!order);
      setQuestionError(!question);
      setAnswerError(!answer);

      return (
        !order || !question || !answer
      );
    }

    useEffect(() => {

        function initValues(){
            setOrder(String(faq.ordem));
            setQuestion(faq.pergunta);
            setAnswer(faq.resposta);   
        }

        if(open){
            initValues();
        }

        if(!open && orderError || questionError || answerError){
            setOrderError(false);
            setQuestionError(false);
            setAnswerError(false);
        }

    },[open])

    const editFAQ = async () => {

        if(validation()){
            return;
        }

        try {
            const response = await API.put(`Faq/${faq.id}`, {
                ordem: order,
                pergunta: question,
                resposta: answer
            });
            snackbar(setAlert, { type: 'success', message: "FAQ editada com sucesso" })
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
        }
        
        setOpen(false);
        refresh();
    }

    return (
        <Modal 
            open={open}
            modalWidth={'700px'}
            handleConfirm={() => editFAQ()}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={setOpen}
          >
             <Grid container spacing={2}>
                 <Grid container alignItems="center" style={{ fontWeight: 'bold', color: '#30235d' }}>
                     <PlaylistAddCheck style={{ color: 'orange' }} />

                    &nbsp;&nbsp;EDITAR FAQ
                 </Grid>
                 <Grid item xs={12}>
                    <Typography className="subtitle">ORDEM</Typography>
                    <TextField
                        value={order}
                        label="Ordem"
                        variant="outlined"
                        error={orderError}
                        helperText={orderError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setOrder(value)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">PERGUNTA</Typography>
                    <TextField
                        value={question}
                        label="Pergunta"
                        variant="outlined"
                        error={questionError}
                        helperText={questionError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setQuestion(value)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">RESPOSTA</Typography>
                    <TextField
                        value={answer}
                        label="Resposta"
                        multiline
                        rows={4}
                        variant="outlined"
                        error={answerError}
                        helperText={answerError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setAnswer(value)
                        }
                    />
                </Grid>
            </Grid>
          </Modal>

    );
};

export default EditFAQModal;
