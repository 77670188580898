import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CustomTablePaginationActions from './PaginationActions';

export default function CustomTableFooter(
  rowCount: number,
  page: number,
  rowsPerPage: number,
  changeRowsPerPage: (page: string | number) => void,
  changePage: (newPage: number) => void,
) {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          colSpan={3}
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{ inputProps: { 'aria-label': 'Linhas por página' } }}
          onPageChange={(e) => {
            // console.log('onPageChange', e)
          }}
          onRowsPerPageChange={(e) => changeRowsPerPage(e.target.value)}
          ActionsComponent={(props) =>
            CustomTablePaginationActions({ ...props, onPageChange: changePage })
          }
          labelDisplayedRows={({ from, to, count }) =>
            `${page + 1} de ${Math.ceil(rowCount / rowsPerPage)}`
          }
          labelRowsPerPage="Linhas por página"
        />
      </TableRow>
    </TableFooter>
  );
}
