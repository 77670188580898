import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { PlaylistAddCheck } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { createRole } from '../../../services/role-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import { Checkbox, FormControlLabel } from '@mui/material';
import Modal from '../../../components/shared/Modal/ModalSales';
import API from '../../../services/services';

import ChipInput from 'material-ui-chip-input';

interface IEditCoverage {
    setOpen: any;
    open: boolean;
    coverageSelected: any;
    updateData: any
}
const EditCoverage = (props: IEditCoverage): JSX.Element => {
    const { open, setOpen, coverageSelected, updateData } = props;
    const { setAlert } = useContext(AlertContext);

    const [order, setOrder] = useState('');
    const [name, setName] = useState('');
    const [options, setOptions] = useState<any[]>([]);

    const [orderError, setOrderError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [optionsError, setOptionsError] = useState(false);

    function validation() {
      setOrderError(!order);
      setNameError(!name);
      setOptionsError(!options.length);

      return (
        !order || !name || !options.length
      );
    }

    useEffect(() => {

        function initValues(){
            //console.log('auqq: ', coverageSelected);
            setOrder(String(coverageSelected[1]));
            setName(coverageSelected[2]);
            setOptions(coverageSelected[3].map((op: any) => op?.opcao));   
        }

        if(open){
            initValues();
        }

        if(!open && orderError || nameError || optionsError){
            setOrderError(false);
            setNameError(false);
            setOptionsError(false);
        }

    },[open])

    const editCoverage = async () => {

        if(validation()){
            return;
        }

        const optionsObj = options.map(option => {
            return {opcao:option}
        });

        try {
            const response = await API.put(`Cobertura/${coverageSelected[0]}`, {
                ordem: order,
                nome: name,
                descricao: '',
                coberturaOpcao: optionsObj
            });
            snackbar(setAlert, { type: 'success', message: "Cobertura editada com sucesso!" })
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
        }

        setOpen(false);
        updateData();
    }

    return (
        <Modal 
            open={open}
            modalWidth={'700px'}
            handleConfirm={editCoverage}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={setOpen}
          >
            <div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <PlaylistAddCheck htmlColor='#ffb812'/>
                <span style={{fontWeight: 'bold', color: '#30235d', alignSelf: 'center'}}>&nbsp;EDITAR COBERTURA</span>
              </div>
              <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop: 30}}>
                  <div style={{ width:'48%'}}>
                      <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>ORDEM</p>
                      <TextField
                        value={order}
                        variant='outlined'
                        placeholder='5'
                        style={{ backgroundColor: '#eeeef3'}}
                        error={orderError}
                        helperText={orderError && 'Campo Obrigatório'}
                        onChange={({ target:{ value } }) => {
                            setOrder(value);
                        }}
                    />
                  </div>
                  <div style={{ width:'48%'}}>
                      <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>COBERTURA</p>
                      <TextField
                        value={name}
                        placeholder='Carro Reserva'
                        variant='outlined'
                        error={nameError}
                        helperText={nameError && 'Campo Obrigatório'}
                        onChange={({ target:{ value } }) => {
                            setName(value);
                        }}
                    />
                  </div>
              </div>
              <div style={{ marginTop:20}}>
                <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>VALORES</p>
                <ChipInput
                    value={options}
                    onAdd={(v) => setOptions([...options, v])}
                    onDelete={(v) => setOptions(options.filter(op => op !== v))}
                    error={optionsError}
                    helperText={optionsError && 'Campo Obrigatório'}
                    variant="outlined"
                    placeholder="Valores"
                    style={{width: '100%'}}
                />
                </div>
            </div>
          </Modal>

    );
};

export default EditCoverage;
