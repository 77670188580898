import React, { useState } from 'react';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  Responsive,
  MUIDataTableState,
  SelectableRows,
  MUIDataTableOptions,
  MUIDataTableTextLabels,
} from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Switch } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  DeleteForever as DeleteIcon,
  Launch,
  MoreVert,
  Group as GroupIcon,
} from '@material-ui/icons';
import { ThemeOptions, Theme } from '@material-ui/core/styles';
import ModalWithTransition from '../Modal';
import BootstrapTooltip from '../Tooltip';
import './styles.css';
import { stringToTitleCase } from '../../../utils/ConversionEvents';
import CustomTableFooter from './CustomFooter';
import { DateInput } from '../inputs/DateInput';
import moment from 'moment';

interface ActionProps {
  type?: string;
  onClick?: any;
  route?: string;
  icon?: JSX.Element;
  text?: string;
  modalWidth?: string;
  component?: () => JSX.Element;
  target?: string;
  disabled?: boolean;
  disabledDelete?: boolean;
  checked?: boolean;
  id?: number;
}

interface ActionsProps {
  buttonProps: ActionProps[];
}

const getMuiTheme = (): Theme =>
  createTheme({
    overrides: {
      MUIDataTableToolbarSelect: {
        root: {
          boxShadow: 'none !important',
          backgroundColor: '#ffffff',
          borderRadius: 0,
          minHeight: '65px',
        },
      },
      MUIDataTableSelectCell: {
        checked: { color: 'var(--yellowDark)!important' },
        headerCell: { backgroundColor: "#eeeff3" }

      },
      MUIDataTableToolbar: {
        icon: {
          '&:hover': {
            color: 'var(--tannat);',
          },
        },
      },
      MuiPaper: {
        elevation4: {
          boxShadow: 'none !important',
        },
      },

    },

  } as ThemeOptions);

export const Delete = (props: ActionProps): JSX.Element => {
  const { onClick, disabledDelete } = props;
  return (
    <BootstrapTooltip title="Excluir" arrow placement="top">
      <span>
        <IconButton
          onClick={(event: any): void => onClick!(event)}
          disabled={disabledDelete}>
          <DeleteIcon />
        </IconButton>
      </span>
    </BootstrapTooltip>
  );
};

export const Disable = (props: ActionProps): JSX.Element => {
  const { onClick, checked, id, disabled } = props;
  return (
    <BootstrapTooltip title={checked ? 'Inativar' : 'Ativar'} arrow placement="top">
      <span>
        <IconButton>
          <Switch
            color="primary"
            onChange={(event) => {
              onClick(event, id);
            }}
            checked={checked}
            disabled={disabled}
          />
        </IconButton>
      </span>
    </BootstrapTooltip>
  );
};

export const Group = (props: ActionProps): JSX.Element => {
  const { onClick } = props;
  return (
    <BootstrapTooltip title="Listar Usuários" arrow placement="top">
      <span>
        <IconButton onClick={(event: any): void => onClick!(event)}>
          <GroupIcon />
        </IconButton>
      </span>
    </BootstrapTooltip>
  );
};
export const Edit = (props: ActionProps): JSX.Element => {
  const { route, onClick, disabled } = props;
  return (
    <BootstrapTooltip title="Editar" arrow placement="top">
      {route ? (
        <span>
          <IconButton component={RouterLink} to={route} disabled={disabled}>
            <Launch />
          </IconButton>
        </span>
      ) : (
        <span>
          <IconButton
            onClick={(event: any): void => onClick!(event)}
            disabled={disabled}
          >
            <Launch />
          </IconButton>
        </span>
      )}
    </BootstrapTooltip>
  );
};

export const RouteButton = (props: ActionProps): JSX.Element => {
  const { route, onClick, icon, text, target = '_self' } = props;
  return (
    <BootstrapTooltip title={text} arrow placement="top">
      {route ? (
        <IconButton component={RouterLink} to={route} target={target}>
          {icon}
        </IconButton>
      ) : (
        <IconButton onClick={(event: any): void => onClick!(event)}>
          {icon}
        </IconButton>
      )}
    </BootstrapTooltip>
  );
};

export const OtherButton = (props: ActionProps): JSX.Element => {
  const { onClick, icon, text, disabled } = props;
  return (
    <BootstrapTooltip title={text} arrow placement="top">
      <span>
        <IconButton
          onClick={(event: any): void => onClick!(event)}
          disabled={disabled}
        >
          {icon}
        </IconButton>
      </span>
    </BootstrapTooltip>
  );
};

export const ModalButton = (props: ActionProps): JSX.Element => {
  const { text, icon, component: ModalComponent, modalWidth } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <BootstrapTooltip title={text} arrow placement="top">
        <IconButton onClick={(): void => setOpen(true)}>{icon}</IconButton>
      </BootstrapTooltip>

      {ModalComponent && (
        <ModalWithTransition
          open={open}
          handleClose={setOpen}
          modalWidth={
            modalWidth || (window.screen.width > 1600 ? '50vw' : '60vw')
          }
        >
          <ModalComponent />
        </ModalWithTransition>
      )}
    </>
  );
};

export const Actions = (props: ActionsProps): JSX.Element => {
  const arrayProps = props;
  const menuButton = arrayProps.buttonProps.filter(
    (prop) => prop.type === 'MENU',
  )[0];

  const deleteButton = arrayProps.buttonProps.filter(
    (prop) => prop.type === 'DELETAR',
  )[0];
  const editButton = arrayProps.buttonProps.filter(
    (prop) => prop.type === 'EDITAR',
  )[0];
  const disableButton = arrayProps.buttonProps.filter(
    (prop) => prop.type === 'DISABLE',
  )[0];
  const listGroupButton = arrayProps.buttonProps.filter(
    (prop) => prop.type === 'LIST_GROUP',
  )[0];

  let deleteClickEvent;
  let menuClickEvent;
  let editClickEvent;
  let disableClickEvent;
  let listGroupClickEvent;
  let route;
  let disabled;
  let disabledDelete;
  let disableChecked;
  let id;

  if (deleteButton) {
    deleteClickEvent = deleteButton.onClick;
    disabledDelete = deleteButton.disabledDelete;
  }

  if (menuButton) {
    menuClickEvent = menuButton.onClick;
  }

  if (editButton) {
    route = editButton.route;
    editClickEvent = editButton.onClick;
    disabled = editButton.disabled;
  }

  if (disableButton) {
    disableClickEvent = disableButton.onClick;
    disableChecked = disableButton.checked;
    disabled = disableButton.disabled;
    id = disableButton.id;
  }
  if (listGroupButton) {
    listGroupClickEvent = listGroupButton.onClick;
  }

  const RouteButtons = (): JSX.Element => (
    <>
      {arrayProps.buttonProps
        .filter((prop) => prop.type === 'ROTA')
        .map((item, index) => (
          <RouteButton
            key={index}
            type="ROTA"
            route={item.route}
            icon={item.icon}
            text={item.text}
            onClick={item.onClick}
            target={item.target}
          />
        ))}
    </>
  );

  const ModalButtons = (): JSX.Element => (
    <>
      {arrayProps.buttonProps
        .filter((prop) => prop.type === 'MODAL')
        .map((item, index) => (
          <ModalButton
            key={index}
            type="MODAL"
            icon={item.icon}
            text={item.text}
            component={item.component}
            modalWidth={item.modalWidth}
          />
        ))}
    </>
  );

  const OtherButtons = (): JSX.Element => (
    <>
      {arrayProps.buttonProps
        .filter((prop) => prop.type === 'OTHER')
        .map((item, index) => (
          <OtherButton
            key={index}
            type="OTHER"
            icon={item.icon}
            text={item.text}
            onClick={item.onClick}
            disabled={item.disabled}
          />
        ))}
    </>
  );

  return (
    <div className="flexWrapCenterCenter">
      <ModalButtons />
      <RouteButtons />
      <OtherButtons />
      {deleteClickEvent && <Delete type="DELETAR" onClick={deleteClickEvent} disabledDelete={disabledDelete} />}
      {menuClickEvent && <MoreVert type="MENU" onClick={menuClickEvent} />}
      {disableButton && (
        <Disable
          type="DISABLE"
          id={id}
          checked={disableChecked}
          onClick={disableClickEvent}
          disabled={disabled}
        />
      )}
      {listGroupClickEvent && (
        <Group type="button" onClick={listGroupClickEvent} />
      )}
      {route ? (
        <Edit type="EDITAR" route={route} disabled={disabled} />
      ) : (
        editClickEvent && <Edit type="EDITAR" onClick={editClickEvent} disabled={disabled} />
      )}
    </div>
  );
};

export const StatusColumn = (value: string): JSX.Element => (
  <div className="flex flexAlignItemsCenter">
    <div
      className={`iconRedondoStatus ${value === 'PENDENTE'
        ? 'cellStatusPending'
        : value === 'PROCESSANDO'
          ? 'cellStatusProcessing'
          : value === 'ENVIADO'
            ? 'cellStatusAtivo'
            : 'cellStatusInativo'
        }`}
    />
    <div className="flex flexAlignItemsCenter flexJustifyCenter">
      {stringToTitleCase(value)}
    </div>
  </div>
);

export const StatusColumnSales = (value: string): JSX.Element => (
  <div className="flex flexAlignItemsCenter">
    {value === "Em processamento" ? <div className={'iconRedondoStatus cellStatusPending'} /> : null}
    {value === "Venda autorizada" ? <div className={'iconRedondoStatus cellStatusAuthorized'} /> : null}
    {value === "Cancelado" ? <div className={'iconRedondoStatus cellStatusCanceled'} /> : null}
    {value === "Pagamento confirmado" ? <div className={'iconRedondoStatus cellStatusConfirmed'} /> : null}
    {value === "Finalizado" ? <div className={'iconRedondoStatus cellStatusFinished'} /> : null}
    {/*fields clients table */}
    {value === "Aprovado" ? <div className={'iconRedondoStatus cellStatusAuthorizedStatus'} /> : null}
    {value === "Pendente" ? <div className={'iconRedondoStatus cellStatusPending'} /> : null}
    {value === "Reprovado" ? <div className={'iconRedondoStatus cellStatusCanceled'} /> : null}

    <div className="flex flexAlignItemsCenter flexJustifyCenter">
      {value}
    </div>
  </div>
);

export const StatusOfColumn = (value: string): JSX.Element => (
  <div className="flex flexAlignItemsCenter">
    { value === 'Ativo' ? <div className={'iconRedondoStatus cellActiveStatus'} /> 
    : value === 'Inativo' ? <div className={'iconRedondoStatus cellInactiveStatus'} /> 
    : value === 'Analise' ? <div className={'iconRedondoStatus cellUnderAnalysisStatus'} /> 
    : value ===  'AguardandoCancelamento' ? <div className={'iconRedondoStatus cellUnderCancellationStatus'} />
    : <div className={'iconRedondoStatus cellUnderPaymentStatus'} />}
    {/* */}
    <div className="flex flexAlignItemsCenter flexJustifyCenter">
      {value === 'Ativo' ? "Ativo" 
      : value === 'Inativo' ? "Inativo" 
      : value === 'Analise' ? 'Em análise' 
      : value === 'AguardandoCancelamento' ? "Aguardando cancelamento" 
      : 'Aguardando pagamento'}
    </div>
  </div>
);

export const BooleanColumn = (valor: boolean): JSX.Element => (
  <div className="flex flexAlignItemsCenter">
    <div
      className={`iconRedondoStatus ${!valor ? 'cellStatusAtivo' : 'cellStatusInativo'
        }`}
    />
    <div className="flex flexAlignItemsCenter flexJustifyCenter">
      {!valor ? 'Ativo' : 'Inativo'}
    </div>
  </div>
);

// TODO - Temp override of BooleanColumn component
export const BooleanColumnReverse = (valor: boolean): JSX.Element => (
  <div className="flex flexAlignItemsCenter">
    <div
      className={`iconRedondoStatus ${valor ? 'cellStatusPending' : 'cellStatusAtivo'
        }`}
    />
    <div className="flex flexAlignItemsCenter flexJustifyCenter">
      {valor ? 'Open' : 'Close'}
    </div>
  </div>
);

export const LinkColumn = (value: string): JSX.Element => (
  <div className="flex flexAlignItemsStart flexJustifyStart">
    <a
      href={value}
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', color: '#c81a78' }}
    >
      Acessar URL
    </a>
  </div>
);

const fixedOptions: MUIDataTableOptions = {
  customFooter: CustomTableFooter,
  filter: true,
  print: false,
  viewColumns: true,
  serverSide: false,
  textLabels: {
    body: {
      noMatch: 'Nenhum dado encontrado.',
      toolTip: 'Filtrar',
      columnHeaderTooltip: (column: any): string =>
        `Ordenar por ${column.label}`,
    },
    toolbar: {
      search: 'Pesquisar por texto',
      downloadCsv: 'Exportar para CSV',
      print: 'Imprimir',
      viewColumns: 'Escolher Colunas',
      filterTable: 'Filtrar Tabela',
    },
    filter: {
      all: 'Todos',
      title: 'Filtros',
      reset: 'Voltar ao Padrão',

    },
    viewColumns: {
      title: 'Exibir Colunas',
      titleAria: 'Exibir/Esconder colunas',
    },
    selectedRows: {
      text: 'Registro(s) selecionado(s)',
      //delete: 'Excluir',
      //deleteAria: 'Excluir linhas selecionadas',
    },
  } as MUIDataTableTextLabels,
};

export interface Column {
  label?: string;
  name: string;
  customBodyRender?: (
    value: any,
    tableMeta: any,
    updateValue: any,
  ) => React.Component;
  options?: MUIDataTableColumnOptions;
}

function setCellNoWrapText(columns: Column[]): Column[] {
  columns.forEach((item) => {
    item.options = {
      setCellProps: (): any => ({
        className: 'noWrap',
        style: {
          whiteSpace: 'nowrap',
          height: '45px'
        },
      }),
    };
  });

  return columns;
}

interface TableProps {
  title?: JSX.Element,
  loading?: boolean;
  options?: MUIDataTableOptions;
  data: any[];
  columns: Column[];
  currentPage?: number;
  totalRecords?: number;
  rowsPerPage?: number;
  exportToCSV?: boolean;
  fileNameExportCSV?: string;
  selectableRows?: SelectableRows;
  responsiveOption?: Responsive;
  noWrapTextInCells?: boolean;
  onSearchChange?: any;
  customSearch?: any;
  searchText?: string;
  onTableChange?: (
    action: string,
    tableState: MUIDataTableState,
  ) => void | undefined;
  onChangePage?: (currentPage: number) => void;
  onChangeRowsPerPage?: (numberOfRows: number) => void;
  onRowsDelete?: (
    selectedRows: Array<{ index: number; dataIndex: number }>,
  ) => void;
}

export const customFilterDate: MUIDataTableColumnOptions = {
  filterType: 'custom',
  filterOptions: {
    logic: ((prop, filter, row) => {
      if (!filter || !filter.length || (filter.length && !filter[0].toString())) {
        return false;
      }
      return !(filter && filter.length > 0 && prop && prop.toString() === filter[0].toString());
    }),
    display: (filterList, onChange, index, column) => (
      <div>
        <DateInput
          variant="standard"
          label="Data"
          disableFuture
          value={moment(filterList[index][0], 'DD/MM/YYYY').isValid()
            ? moment(filterList[index][0], 'DD/MM/YYYY')
            : null
          }
          classWrapper={'filter--DateWrapper'}
          name="filterDate"
          clearable
          onChange={(momentDate: moment.Moment) => {
            let value = (moment(momentDate, 'DD/MM/YYYY').isValid()
              ? moment(momentDate).format('DD/MM/YYYY')
              : '');
            filterList[index] = [];
            filterList[index][0] = value;
            onChange(
              filterList[index],
              index,
              column
            )
          }}
        />
      </div>
    ),
  },
  customFilterListOptions: {
    render: v => {
      return v && v.length ? v[0] : null;
    },
  },
}

const Table = (props: TableProps): JSX.Element => {
  const {
    loading,
    data,
    columns,
    currentPage,
    totalRecords,
    rowsPerPage: rowsPerPageProp,
    exportToCSV,
    fileNameExportCSV,
    selectableRows: selectableRowsProp,
    noWrapTextInCells,
    onTableChange: onTableChangeProp,
    onChangePage: onChangePageProp,
    onChangeRowsPerPage: onChangeRowsPerPageProp,
    onRowsDelete,
    onSearchChange,
    customSearch,
    searchText,
    options,
    title
  } = props;

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={title || ""}
        columns={noWrapTextInCells ? setCellNoWrapText(columns) : columns}
        data={data}
        options={{
          ...options,
          onTableChange: onTableChangeProp,
          onChangePage: onChangePageProp,
          onChangeRowsPerPage: onChangeRowsPerPageProp,
          customToolbarSelect: (selectedRows): JSX.Element => (
            <div></div>
            // <div className="iconButtonToolbarTable">
            //   <BootstrapTooltip
            //     title="Excluir Selecionada(s)"
            //     arrow
            //     placement="left"
            //   >
            //     <IconButton
            //       onClick={(): void => {
            //         onRowsDelete && onRowsDelete(selectedRows.data);
            //       }}
            //     >
            //       <DeleteIcon />
            //     </IconButton>
            //   </BootstrapTooltip>
            // </div>
          ),
          filterType: 'dropdown',
          responsive: 'standard',
          page: currentPage || 0,
          count: totalRecords,
          rowsPerPage: rowsPerPageProp || 10,
          download: !!exportToCSV,
          elevation: 0,
          searchText,
          customSearch: customSearch,
          onDownload: (buildHead, buildBody, columns, data) =>
            `\uFEFF${buildHead(columns)}${buildBody(data)}`,
          onSearchChange,
          downloadOptions: fileNameExportCSV
            ? { filename: fileNameExportCSV, separator: ';' }
            : { filename: 'table', separator: ';' },
          selectableRows: selectableRowsProp || 'multiple',
          ...fixedOptions,
        }}

      />
    </MuiThemeProvider>
  );
};

export default Table;