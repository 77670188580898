import React, { useState, useContext, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { sidebarItemsAdmin, sidebarItemsClient, sidebarItemsSeller } from './SidebarItemsData';
import { SidebarContext } from '../../../../contexts/SidebarContext';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import './SidebarItems.css';

const InnerCollapsableItem = (props: any) => {
  const { open, item, history, isSuper, permissions } = props;
  // const hasAccess =
  //   isSuper || permissions.includes(item.permission) || item.permission === '';

  const navigate = () => {
    history.push(item.route);
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding >
          <ListItem
            button
            onClick={navigate}
            style={{ paddingLeft: item.level === 3 ? 90 : item.level === 2 ? 30 : 80 }} >
            <ListItemIcon
              style={{ display: 'flex', justifyContent: 'center', color: item.route === history.location.pathname ? '#ffac00' : '#fff' }} >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.name} style={{ color: item.route === history.location.pathname ? '#ffac00' : '#fff' }} />
          </ListItem>
        </List>
      </Collapse >
    // hasAccess && (
    //   <Collapse in={open} timeout="auto" unmountOnExit>
    //     <List component="div" disablePadding>
    //       <ListItem
    //         button
    //         onClick={navigate}
    //         style={{ paddingLeft: item.level === 3 ? 90 : item.level === 2 ? 30 : 80 }} >
    //         <ListItemIcon
    //           style={{ display: 'flex', justifyContent: 'center', color: item.route === history.location.pathname ? '#006193' : 'gray' }} >
    //           {item.icon}
    //         </ListItemIcon>
    //         <ListItemText primary={item.name} style={{ color: item.route === history.location.pathname ? '#006193' : 'gray' }} />
    //       </ListItem>
    //     </List>
    //   </Collapse >
    // )
  );
};

const CollapsableListItem = (props: any) => {
  

  const {
    item,
    history,
    sidebar,
    //permissions,
    selected,
    index,
    handleOpen,
    authContext,
  } = props;
  const [open, setOpen] = useState(false);
  // const hasAccess =
  //   permissions.includes(item.permission) || item.permission === '';


  const handleClick = () => {
    
    // open route if item has no children
    if (item.route !== '') {
      history.push(item.route);
    }
    if (sidebar.open) {
      setOpen(!open);

    } else {
      if (item.level === 1 && item.children.length) {
        handleOpen()
        setOpen(true);
      } else {
        setOpen(false);

      }
    }
  };

  return (
    <>
    <List
          className={
            `${selected[index] && 'selectedSidebarItem'}
            ${sidebar.open ? 'sidebarItemOpened' : 'sidebarItemClosed'}
            sidebarItem`
          }
        >
          <ListItem
            button
            onClick={handleClick}
            style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
          >
            {
              (selected[index] && sidebar.open)
              &&
              <div style={{ position: 'absolute', height: 10, width: 10, borderRadius: 5, backgroundColor: '#ffac00', top: 17, left: -22 }}></div>
            }
            <ListItemIcon
              style={{ display: 'flex', justifyContent: 'center', color: selected[index] ? '#ffac00' : '#fff' }} >
              {item.icon}
            </ListItemIcon>
            {sidebar.open && <ListItemText primary={item.name} style={{ fontSize: item.level > 1 ? 16 : 18 }} />}
          </ListItem>
          {item.children.map((item: any, index: number) => {
            return item.children.length > 0 ? (
              <Collapse key={index} in={open && sidebar.open} timeout="auto" unmountOnExit style={{ position: 'relative' }} >
                <CollapsableListItem
                  item={item}
                  padding={25}
                  sidebar={sidebar}
                  history={history}
                  authContext={authContext}
                  //permissions={permissions}
                />
              </Collapse>
            ) : (
              <div key={index}>
                <InnerCollapsableItem
                  history={history}
                  item={item}
                  open={open && sidebar.open}
                  setOpen={setOpen}
                  //permissions={permissions}
                />
              </div>
            );
          })}
        </List>
      {/* {hasAccess && (
        <List
          className={
            `${selected[index] && 'selectedSidebarItem'}
            ${sidebar.open ? 'sidebarItemOpened' : 'sidebarItemClosed'}
            sidebarItem`
          }
        >
          <ListItem
            button
            onClick={handleClick}
            style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
          >
            {
              (selected[index] && sidebar.open)
              &&
              <div style={{ position: 'absolute', height: 10, width: 10, borderRadius: 5, backgroundColor: '#006193', top: 17, left: -22 }}></div>
            }
            <ListItemIcon
              style={{ display: 'flex', justifyContent: 'center', color: selected[index] ? '#006193' : 'gray' }} >
              {item.icon}
            </ListItemIcon>
            {sidebar.open && <ListItemText primary={item.name} style={{ fontSize: item.level > 1 ? 16 : 18 }} />}
          </ListItem>
          {item.children.map((item: any, index: number) => {
            return item.children.length > 0 ? (
              <Collapse key={index} in={open && sidebar.open} timeout="auto" unmountOnExit style={{ position: 'relative' }} >
                <CollapsableListItem
                  item={item}
                  padding={25}
                  sidebar={sidebar}
                  history={history}
                  authContext={authContext}
                  permissions={permissions}
                />
              </Collapse>
            ) : (
              <div key={index}>
                <InnerCollapsableItem
                  history={history}
                  item={item}
                  open={open && sidebar.open}
                  setOpen={setOpen}
                  permissions={permissions}
                />
              </div>
            );
          })}
        </List>
      )} */}
    </>
  );
};

const SidebarItems = (props: any): JSX.Element => {
  const { handleClose, handleOpen } = props;
  const sidebarContext = useContext(SidebarContext);
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const permissions = JSON.parse(authContext.permissions);
  const [selectedItemA, setselectedItemA] = useState(sidebarItemsAdmin.map((item, index) => index === 0));
  const [selectedItemS, setselectedItemS] = useState(sidebarItemsSeller.map((item, index) => index === 0));
  const [selectedItemC, setselectedItemC] = useState(sidebarItemsClient.map((item, index) => index === 0));
  const [typeUser, setTypeUser] = useState(0);

  const handleClickA = () => {
    let res = [];
    for (const item of sidebarItemsAdmin) {

      if (item.children.length > 0) {
        res.push(item.children.some((children: any) => history.location.pathname === children.route));
      } else {
        res.push(history.location.pathname === item.route);
      }

    }
    setselectedItemA(res);
  }

  const handleClickS = () => {
    let res = [];
    for (const item of sidebarItemsSeller) {

      if (item.children.length > 0) {
        res.push(item.children.some((children: any) => history.location.pathname === children.route));
      } else {
        res.push(history.location.pathname === item.route);
      }

    }
    setselectedItemS(res);
  }

  const handleClickC = () => {
    
    let res = [];
    for (const item of sidebarItemsClient) {

      if (item.children.length > 0) {
        res.push(item.children.some((children: any) => history.location.pathname === children.route));
      } else {
        res.push(history.location.pathname === item.route);
      }

    }
    setselectedItemC(res);
  }

  useEffect(() => {
    function loadSideBarItems() {
      // const tkn = localStorage.getItem('jwtSecurityToken');
      // let info_user_tkn = Buffer.from(`${tkn?.split('.')[1]}`, "base64").toString();
      // const info_user = JSON.parse(info_user_tkn);
      // setTypeUser(info_user.roles[0].id)
      setTypeUser(1);
    }
    loadSideBarItems()
  }, []);

  return (
    <>
      {typeUser === 1 || typeUser > 3 ? sidebarItemsAdmin.map((item: any, index: number) => {
        return <div key={index} onClick={handleClickA}>
          <CollapsableListItem
            handleClose={handleClose}
            handleOpen={handleOpen}
            item={item}
            selected={selectedItemA}
            index={index}
            sidebar={sidebarContext}
            history={history}
            //permissions={permissions}
            authContext={authContext}
          />
        </div>
      }) : null}
      {typeUser === 2 ? sidebarItemsSeller.map((item: any, index: number) => {
        return <div key={index} onClick={handleClickS}>
          <CollapsableListItem
            handleClose={handleClose}
            handleOpen={handleOpen}
            item={item}
            selected={selectedItemS}
            index={index}
            sidebar={sidebarContext}
            history={history}
            //permissions={permissions}
            authContext={authContext}
          />
        </div>
      }) : null}
      {typeUser === 3 ? sidebarItemsClient.map((item: any, index: number) => {
        return <div key={index} onClick={handleClickC}>
          <CollapsableListItem
            handleClose={handleClose}
            handleOpen={handleOpen}
            item={item}
            selected={selectedItemC}
            index={index}
            sidebar={sidebarContext}
            history={history}
            permissions={permissions}
            authContext={authContext}
          />
        </div>
      }) : null}
    </>
  );
};

export default SidebarItems;
