import React, { useState, useContext, useEffect, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button, Grid, Typography, Tabs, Tab, Paper } from '@material-ui/core';
import { GroupAdd, ArrowBack, LocationOn, AttachMoney, WorkOutline, Edit, AccountCircle, MailOutline } from '@material-ui/icons';
import Buyer from './Buyers';
import Provider from './Providers';
import Partner from './Partners';
import Revenue from './Revenues';
import BankReference from './BankReferences';

import API from '../../../services/services';
import nProgress from 'nprogress';

import TabPanel from '../../../components/shared/TabPanel';
import { AlertContext } from '../../../contexts/AlertContext';
import './ViewClient.css';

import { colors } from '../../../utils/Theme';
import { snackbar, formatCnpj, formatPhone, formatDateBr } from '../../../utils/util';
import { getClientById } from '../../../services/client-service';
import moment from 'moment';

const ViewClient = () => {
  const { setAlert } = useContext(AlertContext);
  return <CreateClientPage {...{ setAlert }} />;
};


interface IRoles {
  id: number;
}
interface IUserInfo {
  email: string;
  exp: number;
  iat: number;
  id: number;
  name: string;
  roles: Array<IRoles>;
  sellerId: number | null;
  sub: number;
}

const CreateClientPage = memo((props: any) => {
  const { id } = useParams<any>();
  const { setAlert } = useContext(AlertContext);

  const [canResendPass, setCanResendPass] = useState(true);
  const [value, setValue] = useState(0);
  const [userInfo, setUserInfo] = useState({} as IUserInfo);
  const [client, setClient] = useState<any>({
    name: '',
    cpf: '',
    email: '',
    social_name: '',
    company_name: '',
    address: '',
    neighborhood: '',
    city: '',
    state: '',
    cep: '',
    cnpj: '',
    state_registration: '',
    operational_email: '',
    operational_name: '',
    operational_phone: '',
    finance_email: '',
    finance_name: '',
    finance_phone: '',
    branches: '',
    type_of_activity: '',
    number_of_employees: '',
    net_worth: '',
    foundation_data: '',
  } as any);

  const routerHistory = useHistory();

  const resendUserPassword = async (event: any) => {
    setCanResendPass(false);
    nProgress.start();
    try {
      const { data } = await API.get<any>( '/users/'+id+'/resend-password');
      
      if (data?.updated) {
        snackbar(setAlert, {
          type: 'success',
          message: 'Senha reenviada com sucesso',
        });
        //return window.location.reload();
      } else {
        snackbar(setAlert, {
          type: 'error',
          message: 'Erro ao enviar a senha',
        });
      }
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message: err?.response?.data?.message
      });
    }
    nProgress.done();
    setCanResendPass(true);
  }

  useEffect(() => {
    (async function fetchItem() {
      try {
        const value = await getClientById(id);
        setClient({
          ...client,
          name: value.name,
          cpf: value.cpf,
          email: value.email,
          social_name: value.company.name,
          company_name: value.company.company_name,
          address: value.company.address,
          neighborhood: value.company.neighborhood,
          city: value.company.city,
          state: value.company.state,
          cep: value.company.cep,
          cnpj: value.company.cnpj != null ? formatCnpj(value.company.cnpj) : '',
          state_registration: value.company.state_registration,
          operational_email: value.company.operational_email,
          operational_name: value.company.operational_name,
          operational_phone: value.company.operational_phone != null ? formatPhone(value.company.operational_phone) : '',
          finance_email: value.company.finance_email,
          finance_name: value.company.finance_name,
          finance_phone: value.company.finance_phone != null ? formatPhone(value.company.finance_phone) : '',
          branches: value.company.branches,
          type_of_activity: value.company.type_of_activity,
          number_of_employees: value.company.number_of_employees,
          net_worth: value.company.net_worth != null ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.company.net_worth) : '',
          foundation_data: value.company.foundation_data && moment(value.company.foundation_data).isValid() ? moment(value.company.foundation_data).format('DD/MM/YYYY') : ' - ',
        })
      } catch (err: any) {
        snackbar(setAlert, {
          type: 'error',
          message: 'Registro não encontrado',
        });
      }
    })();

    function loadInfoUser() {
      const tkn = localStorage.getItem('jwtSecurityToken');
      let info_user_tkn = Buffer.from(`${tkn?.split('.')[1]}`, "base64").toString();
      const info_user = JSON.parse(info_user_tkn);
      setUserInfo(info_user);
    }
    loadInfoUser();
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: any) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Typography className='contextTopBar' variant="h3" component="h2">
        Visualizar Cliente
      </Typography>

      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Product Tabs"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Dados Gerais do Cliente" {...a11yProps(0)} />
          <Tab label="Compradores" {...a11yProps(1)} />
          <Tab label="Fornecedores" {...a11yProps(2)} />
          <Tab label="Sócios" {...a11yProps(3)} />
          <Tab label="Referência Bancária" {...a11yProps(4)} />
          <Tab label="Faturamento" {...a11yProps(5)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid item xs={12} className="flexJustifyStart mb-3">
            <Typography variant="h4" style={{ color: '#29305B' }}><b>{client.company_name}</b></Typography>
            <Typography variant="h6" style={{ color: '#006193' }}>{client.social_name} - {client.cnpj}</Typography>
          </Grid>
          <Grid item xs={12} className="flexJustifyStart mb-3">
            <Typography variant="h6" style={{ color: '#006193' }}><b>Ramo: {client.type_of_activity}</b></Typography>
          </Grid>
          <Grid item xs={12} className="flexJustifyStart mb-3">
            <Typography variant="h6" style={{ color: '#006193' }}><LocationOn style={{ color: '#f4694c', marginRight: 1 }} /> Endereço</Typography>
            <Typography variant="h6" style={{ color: '#cccccc' }}>{client.address}</Typography>
            <Typography variant="h6" style={{ color: '#cccccc' }}>{client.neighborhood} - {client.city}/{client.state}</Typography>
            <Typography variant="h6" style={{ color: '#cccccc' }}>CEP: {client.cep}</Typography>
          </Grid>
          <Grid item xs={12} className="flex flexJustifyStart mb-3">
            <Grid item xs={6}>
              <Grid item xs={12} className="flex flexJustifyStart mr-1">
                <Grid item xs={1} className="bgColorOrange text-center text-white flexWrapCenterCenter card-rounded-left">
                  <AttachMoney style={{ fontSize: '2rem' }} />
                </Grid>
                <Grid item xs={11} className="bgColorGrey CTA card-rounded-right">
                  <Typography className="ml-1">{client.finance_name} (Financeiro)</Typography>
                  <Typography className="ml-1">{client.finance_phone}</Typography>
                  <Typography className="ml-1">{client.finance_email}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12} className="flex flexJustifyStart mr-1">
                <Grid item xs={1} className="bgColorOrange text-center text-white flexWrapCenterCenter card-rounded-left">
                  <WorkOutline style={{ fontSize: '2rem' }} />
                </Grid>
                <Grid item xs={11} className="bgColorGrey CTA card-rounded-right">
                  <Typography className="ml-1">{client.operational_name} (Operacional)</Typography>
                  <Typography className="ml-1">{client.operational_phone}</Typography>
                  <Typography className="ml-1">{client.operational_email}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="flex flexJustifyStart">
            <Grid item xs={4} className="flexJustifyStart mt-1">
              <Typography variant="h6" style={{ color: '#006193' }}><b>Inscrição Estadual</b></Typography>
              <Typography style={{ color: '#cccccc' }}>{client.state_registration}</Typography>
            </Grid>
            <Grid item xs={4} className="flexJustifyStart mt-1">
              <Typography variant="h6" style={{ color: '#006193' }}><b>Data de Fundação</b></Typography>
              <Typography style={{ color: '#e4caca' }}>{client.foundation_data}</Typography>
            </Grid>
            <Grid item xs={4} className="flexJustifyStart mt-1">
              <Typography variant="h6" style={{ color: '#006193' }}><b>Nº de Filiais</b></Typography>
              <Typography style={{ color: '#cccccc' }}>{client.branches}</Typography>
            </Grid>
            <Grid item xs={4} className="flexJustifyStart mt-1">
              <Typography variant="h6" style={{ color: '#006193' }}><b>Nº de Funcionários</b></Typography>
              <Typography style={{ color: '#cccccc' }}>{client.number_of_employees}</Typography>
            </Grid>
            <Grid item xs={4} className="flexJustifyStart mt-1">
              <Typography variant="h6" style={{ color: '#006193' }}><b>Capital Social</b></Typography>
              <Typography style={{ color: '#cccccc' }}>{client.net_worth}</Typography>
            </Grid>
          </Grid>
            { 
              userInfo.roles && Number(userInfo.roles[0].id) === 1 
              ? 
              (<div>
                <hr style={{	color: '#ccc', borderColor: '#ccc',	backgroundColor: '#ccc',	height: '1px',	border: 'unset', marginTop:30  }}/>
              
                <Grid item xs={12} className="flexJustifyStart mb-3" style={{ marginTop:30 }}>
                  <Typography style={{ color: '#006193' }}>
                    <AccountCircle style={{ verticalAlign:'middle', marginRight:5 }}/><b>Usuário:</b> <span style={{color:'#ccc'}}>{client.email}</span>
                  </Typography>
                  <Button
                      className="button buttonContainedRounded buttonContainedColorDanger CTA"
                      onClick={resendUserPassword}
                      style={{ marginTop:20 }}
                      disabled={!canResendPass}
                      >
                        <MailOutline style={{ marginRight:5 }}/> Reenviar senha
                    </Button>
                </Grid>
              </div>)
              : 
              ''
            }
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid item xs={12} className="flexJustifyStart">
            <h4 style={{ color: '#006193' }}><GroupAdd style={{ color: 'orange', marginRight: 1 }} /> Cadastrar Compradores</h4>
          </Grid>
          <Buyer id={id} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid item xs={12} className="flexJustifyStart">
            <h4 style={{ color: '#006193' }}><GroupAdd style={{ color: 'orange', marginRight: 1 }} /> Cadastrar Fornecedores</h4>
          </Grid>
          <Provider id={id} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid item xs={12} className="flexJustifyStart">
            <h4 style={{ color: '#006193' }}><GroupAdd style={{ color: 'orange', marginRight: 1 }} /> Cadastrar Sócios</h4>
          </Grid>
          <Partner id={id} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Grid item xs={12} className="flexJustifyStart">
            <h4 style={{ color: '#006193' }}><GroupAdd style={{ color: 'orange', marginRight: 1 }} /> Cadastrar Referência Bancária</h4>
          </Grid>
          <BankReference id={id} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Grid item xs={12} className="flexJustifyStart">
            <h4 style={{ color: '#006193' }}><GroupAdd style={{ color: 'orange', marginRight: 1 }} /> Cadastrar Faturamento</h4>
          </Grid>
          <Revenue id={id} />
        </TabPanel>

        <Grid container justifyContent="space-between" style={{ padding: '20px' }}>
          <Button
            className="button buttonContainedRounded CTA"
            onClick={() => routerHistory.push('/clients')}
            style={{ backgroundColor: colors.tertiary }}
          >
            <ArrowBack /> Voltar
          </Button>
          <Button
            className="button buttonContainedRounded buttonContainedColorPrimary CTA"
            onClick={() => routerHistory.push(`/clients/edit/${id}`)}
          >
            <Edit /> Editar Cliente
          </Button>
        </Grid>
      </Paper>
    </>
  );
});

export default ViewClient;
