import API from './services';
import { IPolicy } from '../models/IPolicy';

interface IGetPoliciesResponse {
    value: IPolicy[]
}

interface IGetPolicyByIdResponse {
    value: IPolicy
}

export const getPolicies = () => {
    return API.get<IGetPoliciesResponse>('/Apolice');
}

export const getPolicyById = (id: string) => {
    return API.get<IGetPolicyByIdResponse>(`/Apolice/${id}`);
}

export const getFile = (id: string) => {
    return API.get<any>(`/Apolice/download/${id}`);
}

export const downloadPdfQuotation = (id: string) => {
    return API.get<any>(`/Cotacao/download/${id}`)
}

export const postPolicy = (policy: FormData) => {
    return API.post('/Apolice/Upload', policy)
}

export const putPolicy = (policy: FormData, policyId?: string) => {
    return API.put(`/Apolice/${policyId}`, policy)
}

export const deletePolicy = (id: string) => {
    return API.delete(`/Apolice/${id}`, '')
}

