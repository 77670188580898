import React, { FormEvent, useState } from 'react';
import {
    Grid,
    Button,
    Stepper,
    Step,
    StepLabel,
    Container,
    Typography,
    StepConnector,
    withStyles,
    makeStyles,
    StepIconProps,
    StepButton,
} from '@material-ui/core';
import { LogoGrande } from '../../../components/shared/Logo';
import '../LoginPage.css';
import { ChevronLeft, ChevronRight, } from '@material-ui/icons';
import EmailImg from '../../../assets/EmailCampaingLogin.png'
import AccessData from './Steps/AccessData';
import AboutCompany from './Steps/AboutCompany';
import Adress from './Steps/Adress';
import Contact from './Steps/Contact';
import clsx from 'clsx';
import { IAboutCompany, IAccessData, ICompanyAddress, ICompanyContacts } from '../LoginModel';

interface ILogin {
    onSubmitPostLogin?: (event: FormEvent<HTMLFormElement>) => void;
    onRequestLogin?: boolean;
    onClickBack?: () => void
}

const CreateAccount = ({
    onClickBack
}: ILogin) => {

    const steps = ['Dados de acesso', 'Sobre a empresa', 'Endereço', 'Contato'];
    const [activeStep, setActiveStep] = React.useState(0);
    const [accessData, setAccessData] = useState<any>({ name: '', email: '' });
    const [aboutCompany, setAboutCompany] = useState<IAboutCompany>({
        name: '',
        company_name: '',
        cnpj: '',
        state_registration: '',
        branches: 0,
        type_of_activity: '',
        number_of_employees: 0,
        net_worth: 0,
        foundation_data: ''
    });
    const [address, setAddress] = useState<ICompanyAddress>({
        address: '',
        neighborhood: '',
        city: '',
        state: '',
        cep: ''
    });
    const [contacts, setContacts] = useState<ICompanyContacts>({
        operational_name: '',
        operational_phone: '',
        operational_email: '',
        finance_name: '',
        finance_phone: '',
        finance_email: ''
    })

    //Step styles
    const ColorlibConnector = withStyles({
        line: {
            border: 0,
            backgroundColor: 'transparent',
        },
    })(StepConnector);

    const StepperLabel = makeStyles({
        label: {
            color: 'white',
        },
        labelContainer: {
            color: 'white !important',
        },
        root: {
            color: 'white !important',
        },
        active: {
            color: 'white !important',
        },
        completed: {
            color: 'white !important',
        },
    })(StepLabel);

    const useColorlibStepIconStyles = makeStyles({
        root: {
            zIndex: 1,
            width: 35,
            height: 35,
            display: 'flex',
            borderRadius: '50%',
            borderWidth: 'thin',
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: "white",
            borderStyle: 'solid',
        },
        active: {
            backgroundColor: '#006193',
            borderColor: "#006193",
            borderStyle: 'solid',
            color: 'white'
        }
    });

    function ColorlibStepIcon(props: StepIconProps) {
        const classes = useColorlibStepIconStyles();
        const { active } = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <Typography>1</Typography>,
            2: <Typography>2</Typography>,
            3: <Typography>3</Typography>,
            4: <Typography>4</Typography>,
        };

        return (
            <div
                className={clsx(classes.root, { [classes.active]: active })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <Container>
                        <AccessData throwAboutCompany={nextStep} accessData={accessData} setAccessData={setAccessData} />
                    </Container>
                );
            case 1:
                return (
                    <Container>
                        <AboutCompany
                            throwAdress={nextStep}
                            throwAccessData={backStep}
                            setAboutCompany={setAboutCompany}
                            aboutCompany={aboutCompany}
                        />
                    </Container>
                );
            case 2:
                return (
                    <Container>
                        <Adress
                            optionsComplete={[{ title: 'Comercial' }, { title: 'Residencial' }]}
                            throwAboutCompany={backStep}
                            throwContact={nextStep}
                            setAddress={setAddress}
                            address={address} />
                    </Container>
                );
            case 3:
                return (
                    <Container>
                        <Contact
                            sendAccountQuery={nextStep}
                            throwAdress={backStep}
                            setContacts={setContacts}
                            contacts={contacts}
                            finalResult={{
                                customer: accessData,
                                company: {
                                    ...aboutCompany,
                                    ...address,
                                    ...contacts
                                }
                            }}
                        />
                    </Container>
                );
            case 4:
                return (
                    //mensagem de email enviado
                    <Grid container style={{ padding: "1.5%", alignSelf: "baseline !important" }}>
                        <Grid item md={12} sm={12} xs={12} style={{alignSelf: "baseline !important"}} >
                            <Typography className="labelAux" align='center' variant="h5" style={{fontSize: "1.6rem"}} > SEU CADASTRO FOI FINALIZADO COM SUCESSO!</Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} style={{marginBottom: "35px"}} >
                            <Typography align='center' style={{paddingBottom: "10px"}}> Agora o seu cadastro passará pelo processo de análise.</Typography>
                            <Typography align='center'>
                                Assim que seu cadastro for liberado, as informações de login serão enviadas para seu email.
                            </Typography>
                        </Grid>
                    </Grid>

                );
        }
    }

    const nextStep = () => {
        const newActiveStep = (activeStep + 1)
        setActiveStep(newActiveStep);
    };

    const backStep = () => {
        const newActiveStep = (activeStep - 1)
        setActiveStep(newActiveStep);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    return (
        <Grid container justifyContent="center" alignItems="center" >
            {/* <LogoGrande className="containerLogo" width="100" height="50" /> */}
            <Grid item container style={{ flexFlow: 'row', paddingLeft: 20, paddingRight: 20 }} justifyContent="center">

                <Grid container justifyContent='space-between' style={{ maxWidth: 253, backgroundColor: "#40B2B7", borderRadius: "16px 0px 0px 16px" }}>

                    {(activeStep !== 4) ?
                        <Stepper connector={<ColorlibConnector />} style={{ backgroundColor: "#40B2B7", borderRadius: "16px 0px 0px 16px", alignSelf: "center" }}
                            activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepButton onClick={handleStep(index)}>
                                            <StepLabel classes={StepperLabel} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper> : <Grid> <img width="100%" src={EmailImg} alt="Logo Officell " /> </Grid>}

                </Grid>

                <Grid container style={{ maxWidth: 1162, backgroundColor: "#FFFFFF", borderRadius: "0px 16px 16px 0px", padding: '1.5%' }}>
                    {getStepContent(activeStep)}
                </Grid>
            </Grid>

            <Button
                className="buttonVoltar"
                onClick={onClickBack}
            >
                <ChevronLeft style={{ color: "#000000" }} fontSize="small" /> <b style={{ color: "#000000", height: "18px", fontSize: "11px" }}> VOLTAR</b>
            </Button>
        </Grid>
    )
}

export default CreateAccount;