import React, { useState } from 'react';
import {
    Grid,
    Button,
    Typography,
    TextField,
} from '@material-ui/core';
import '../../LoginPage.css';
import { ChevronLeft, ChevronRight, } from '@material-ui/icons';
import InputText from '../../../../components/shared/inputs/InputText';
import { IAboutCompany } from '../../LoginModel';
import { validateDate } from '../../../../utils/util';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { DateInput } from '../../../../components/shared/inputs/DateInput';

interface ICompany {
    throwAccessData?: () => void;
    throwAdress?: () => void;
    setAboutCompany: React.Dispatch<React.SetStateAction<IAboutCompany>>;
    aboutCompany: IAboutCompany;
}
interface IAboutCompanyErrors {
    company_name: Error;
    cnpj: Error;
    state_registration: Error;
    foundation_data: Error;
}
interface Error {
    value: boolean;
    text: string;
}


const AboutCompany = ({ throwAccessData, throwAdress, setAboutCompany, aboutCompany }: ICompany) => {
    const [errors, setErrors] = useState<IAboutCompanyErrors>({
        company_name: { value: false, text: '' },
        cnpj: { value: false, text: '' },
        state_registration: { value: false, text: '' },
        foundation_data: { value: false, text: '' },
    })

    const handleContinue = () => {
        setErrors({
            company_name: { value: false, text: '' },
            cnpj: { value: false, text: '' },
            state_registration: { value: false, text: '' },
            foundation_data: { value: false, text: '' },
        });

        const isNameInvalid = aboutCompany.company_name === '';
        // const isCnpjInvalid = validateCnpj(aboutCompany.cnpj);
        //const isStateRegistrationInvalid = aboutCompany.state_registration === '';
        const isFoundationDateInvalid = !validateDate(aboutCompany.foundation_data) && aboutCompany.foundation_data !== '';

        if (isNameInvalid) setErrors(prev => ({ ...prev, company_name: { value: true, text: 'Campo obrigatório*' } }));
        //if (isStateRegistrationInvalid) setErrors(prev => ({ ...prev, state_registration: { value: true, text: 'Campo obrigatório*' } }));
        if (isFoundationDateInvalid) setErrors(prev => ({ ...prev, foundation_data: { value: true, text: 'Data inválida*' } }));
        // if (isCnpjInvalid) setErrors(prev => ({ ...prev, cnpj: { value: true, text: 'CNPJ inválido*' } }));

        if (isNameInvalid || isFoundationDateInvalid) return;

        if (throwAdress) throwAdress()
    };

    const dateFormat : string = 'DD/MM/YYYY';
    return (<>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
                <InputText title={'Razão social*'} fullWidth variant="outlined"
                    value={aboutCompany.company_name}
                    onChange={(event) => setAboutCompany(prev => ({ ...prev, company_name: event.target.value }))}
                    error={errors.company_name.value}
                    helperText={errors.company_name.text}
                 />
            
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <InputText title={'Nome fantasia'} variant="outlined"
                    value={aboutCompany.name}
                    onChange={(event) => setAboutCompany(prev => ({ ...prev, name: event.target.value }))}
                />  
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
                <Typography className="subtitle" style={{ fontSize: "small" }} >
                    CNPJ
                </Typography>
                <InputMask
                    mask="99.999.999/9999-99"
                    value={aboutCompany.cnpj}
                    onChange={(event) => setAboutCompany(prev => ({ ...prev, cnpj: event.target.value }))}
                    required
                    placeholder=""
                    name="cnpj"
                    title={'CNPJ*'}
                >
                    {(inputProps: any) => <TextField {...inputProps} helperText={errors.cnpj.text} error={errors.cnpj.value} type="text" variant="outlined" fullWidth size="small" />}
                </InputMask>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <InputText
                    value={aboutCompany.state_registration}
                    type="number"
                    title={'INSC. Estadual'} fullWidth variant="outlined"
                    onChange={(event) => setAboutCompany(prev => ({ ...prev, state_registration: event.target.value }))}
                     />
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4}>
                <InputText title={'N° Filiais'}
                    value={aboutCompany.branches}
                    variant="outlined" type="number"
                    onChange={(event) => setAboutCompany(prev => ({ ...prev, branches: Number(event.target.value ? event.target.value : 0) }))} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <InputText
                    value={aboutCompany.type_of_activity}
                    title={'Ramo de atividade'} fullWidth variant="outlined"
                    onChange={(event) => setAboutCompany(prev => ({ ...prev, type_of_activity: event.target.value }))} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <InputText
                    value={aboutCompany.number_of_employees}
                    title={'N° Funcionários'} fullWidth variant="outlined" type="number"
                    onChange={(event) => setAboutCompany(prev => ({ ...prev, number_of_employees: Number(event.target.value ? event.target.value : 0) }))} />
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
                <InputText
                    value={aboutCompany.net_worth}
                    title={'Capital social (R$)'} variant="outlined"
                    type="number"
                    onChange={(event) => setAboutCompany(prev => ({ ...prev, net_worth: Number(event.target.value ? event.target.value : 0) }))} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>

                <Typography className="subtitle" style={{ fontSize: "small" }} >
                    Data de fundação
                </Typography>
                {console.log(aboutCompany.foundation_data)}
                <DateInput 
                    disableFuture
                    value={moment(aboutCompany.foundation_data, dateFormat).isValid()
                        ? moment(aboutCompany.foundation_data, dateFormat)
                        : null}
                    name="foundation_data"
                    clearable
                    onChange={(momentDate: moment.Moment) => {setAboutCompany(prev => ({ ...prev, foundation_data: moment(momentDate, dateFormat).isValid()
                        ? moment(momentDate).format(dateFormat)
                        : '' }))}}
                    />


                {/* <InputMask
                    mask="99/99/9999"
                    value={aboutCompany.foundation_data}
                    onChange={(formattedDate: string, dateObject: Date) => {console.log(formattedDate); setAboutCompany(prev => ({ ...prev, foundation_data:formattedDate }))}}
                    placeholder=""
                    name="foundation_data"
                    title={'Data de fundação'}
                >
                    {(inputProps: any) => <DateInput value={aboutCompany.foundation_data}/>}
                </InputMask> */}
            </Grid>
        </Grid>

        <Grid container justifyContent="space-between" style={{ marginTop: "20px" }}>
            <Button
                onClick={throwAccessData}
                className="button buttonContainedRounded buttonContainedColorSecondary"
            >
                <ChevronLeft fontSize="small" />
                Dados de acesso
            </Button>
            <Button
                onClick={handleContinue}
                className="button buttonContainedRounded CTA buttonContainedColorPrimary"
            >
                Continuar
                <ChevronRight fontSize="small" />
            </Button>
        </Grid>
    </>)
}

export default AboutCompany;