import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'Open Sans',
            'Nunito',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    }
});

export const colors = {
    tertiary: '#40B2B7',
    warning: '#db7f28'
}