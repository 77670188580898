import { Button, Divider, Grid, TextField, Typography, Snackbar } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React, { useState } from 'react';
import ReactInputMask from 'react-input-mask';
import InputText from '../../../../components/shared/inputs/InputText';
import { addCustomer } from '../../../../services/user-service';
import { ICompanyContacts } from '../../LoginModel';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { colors } from '../../../../utils/Theme'

interface IAboutContact {
    throwAdress?: () => void;
    sendAccountQuery?: () => void;
    setContacts: React.Dispatch<React.SetStateAction<ICompanyContacts>>;
    contacts: ICompanyContacts;
    finalResult: any;
}
interface ICompanyContactErrors {
    name: Error;
    email: Error;
    cpf: Error;
}

interface Error {
    value: boolean;
    text: string;
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateAccount = ({ throwAdress, sendAccountQuery, setContacts, contacts, finalResult }: IAboutContact) => {
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState('')

    const handleContinue = () => {
        //finalResult.customer.cpf != undefined ? finalResult.customer.cpf.replace('-', '').replace('.', '').replace('.', '') : undefined;
        finalResult.customer.cpf = finalResult.customer.cpf.replaceAll('-', '').replaceAll('.', '')
        const customer = { ...finalResult.customer};
        const company = finalResult.company

        if(company.state_registration == "")
        {
            company.state_registration = "ISENTO"
        }
        addCustomer({ ...customer, company })
            .then(({ data }) => {
                if (sendAccountQuery && data) sendAccountQuery()
            })
            .catch((err) => {
                setMessage(err?.response?.data?.message || 'Ocorreu um erro, confira se o email é válido')
                setAlert(true)
                setTimeout(() => setAlert(false), 3000)
            })


    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
                <Typography className="labelAux"> Operacional</Typography>
                <Divider />
                <InputText title={'Nome do contato'} variant="outlined"
                    value={contacts.operational_name}
                    onChange={(event) => setContacts(prev => ({ ...prev, operational_name: event.target.value }))}
                />

                <Typography className="subtitle" style={{ fontSize: "small" }} >
                    Telefone
                </Typography>
                <ReactInputMask
                    mask="(99)99999-9999"
                    value={contacts.operational_phone}
                    onChange={(event) => setContacts(prev => ({ ...prev, operational_phone: event.target.value }))}
                    required
                    placeholder=""
                    name="phone"
                    title={'Telefone'}
                >
                    {(inputProps: any) => <TextField {...inputProps} type="tel" variant="outlined" fullWidth size="small" />}
                </ReactInputMask>
                <InputText title={'Email'} variant="outlined"
                    value={contacts.operational_email}
                    onChange={(event) => setContacts(prev => ({ ...prev, operational_email: event.target.value }))} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
                <Typography className="labelAux"> Financeiro</Typography>
                <Divider />
                <InputText title={'Nome do contato'} variant="outlined"
                    value={contacts.finance_name}
                    onChange={(event) => setContacts(prev => ({ ...prev, finance_name: event.target.value }))} />

                <Typography className="subtitle" style={{ fontSize: "small" }} >
                    Telefone
                </Typography>
                <ReactInputMask
                    mask="(99)99999-9999"
                    value={contacts.finance_phone}
                    onChange={(event) => setContacts(prev => ({ ...prev, finance_phone: event.target.value }))}
                    required
                    placeholder=""
                    name="phone"
                    title={'Telefone'}
                >
                    {(inputProps: any) => <TextField {...inputProps} type="tel" variant="outlined" fullWidth size="small" />}
                </ReactInputMask>
                <InputText title={'Email'} variant="outlined"
                    value={contacts.finance_email}
                    onChange={(event) => setContacts(prev => ({ ...prev, finance_email: event.target.value }))} />
            </Grid>
            <Grid container justifyContent="space-between" style={{ marginTop: "20px", padding: "8px" }}>
                <Button
                    onClick={throwAdress}
                    className="button buttonContainedRounded CTA"
                    style={{ backgroundColor: colors.tertiary }}
                >
                    <ChevronLeft fontSize="small" />
                    Endereço
                </Button>
                <Button
                    onClick={handleContinue}
                    className="button buttonContainedRounded CTA buttonContainedColorPrimary"
                >
                    Finalizar cadastro
                    <ChevronRight fontSize="small" />
                </Button>
            </Grid>
            <Snackbar open={alert} autoHideDuration={6000} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} >
                <Alert severity="error">{message}</Alert>
            </Snackbar>
        </Grid>
    )
}

export default CreateAccount;