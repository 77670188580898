import React, { useState, useEffect, useContext } from 'react';
import InputMask from 'react-input-mask';
import { Grid, Typography, TextField, InputAdornment, Divider, Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Decision } from '../../../../components/shared/Alert';
import BuyersTable, { Column, Actions } from '../../../../components/shared/Table';

import nProgress from 'nprogress';
import { snackbar } from '../../../../utils/util';
import { Client } from '../../ClientModel';

import { AlertContext } from '../../../../contexts/AlertContext';
import API from '../../../../services/services';
import { getBuyers } from '../../../../services/client-service';
import LabelHeaderTable from '../../../../components/shared/Label/LabelHeaderTable';

const Buyer = (props: any): JSX.Element => {

  const { setAlert } = useContext(AlertContext);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [idBuyer, setIdBuyer] = useState(0);
  const [showDeleteDecisionAlert, setShowDeleteDecisionAlert] = useState(false);
  const [buyers, setBuyers] = useState<Client[]>([]);
  const [editBuyer, setEditBuyer] = useState(false);

  const [nameBuyerError, setNameBuyerError] = useState(false);
  const [contactNameBuyerError, setContactNameBuyerError] = useState(false);
  const [phoneBuyerError, setPhoneBuyerError] = useState(false);
  const [profitSharingBuyerError, setProfitSharingBuyerError] = useState(false);

  const [buyer, setBuyer] = useState<any>({
    name: '',
    contact: '',
    phone: '',
    profitSharing: '',
    customerId: parseInt(props.id)
  } as any);

  const columnsBuyers: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'contact',
      label: 'Contato',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'phone',
      label: 'Telefone',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'profitSharing',
      label: 'Part. no Faturamento',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#006193",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0] as number;
          const name = selectedRow.rowData[1];
          const contact = selectedRow.rowData[2];
          const phone = selectedRow.rowData[3];
          const profitSharing = selectedRow.rowData[4] as number;
          return (
            <Actions
              buttonProps={[
                {
                  type: 'DELETAR',
                  onClick: (): void => {
                    setShowDeleteDecisionAlert(true);
                    setIdBuyer(id);
                  },
                },
                {
                  type: 'EDITAR',
                  onClick: (): void => {
                    setIdBuyer(id);
                    setEditBuyer(true)
                    buyer.name = name
                    buyer.contact = contact
                    buyer.phone = phone
                    buyer.profitSharing = profitSharing
                  },
                },
              ]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    fetchBuyers();
  }, [pagination]);

  const removeBuyer = async (decision: 'OK' | 'CANCEL', id: number) => {
    setShowDeleteDecisionAlert(false);
    nProgress.start();
    if (decision === 'OK') {
      const { data } = await API.delete('/buyers', id);
      if (data?.deleted) {
        snackbar(setAlert, {
          type: 'success',
          message: 'Comprador removido com sucesso',
        });
        fetchBuyers()
      } else {
        snackbar(setAlert, {
          type: 'error',
          message: 'Erro ao remover comprador',
        });
      }
    }
    nProgress.done();
  };

  function validationBuyer() {
    setNameBuyerError(!buyer.name);
    setContactNameBuyerError(!buyer.contact);
    setPhoneBuyerError(!buyer.phone);
    setProfitSharingBuyerError(!buyer.profitSharing);
    return (
      !!buyer.name &&
      !!buyer.contact &&
      !!buyer.phone &&
      !!buyer.profitSharing
    );
  }

  const saveBuyer = async () => {
    if (editBuyer) {
      try {
        const { status } = await API.patch(`/buyers/${idBuyer}`, buyer);
        if (status === 200) {
          setEditBuyer(false)
          fetchBuyers();
          buyer.name = ''
          buyer.contact = ''
          buyer.phone = ''
          buyer.profitSharing = ''
          snackbar(setAlert, {
            type: 'success',
            message: 'Comprador atualizado com sucesso',
          })
        }
      } catch (err: any) {
        const message = err?.response?.data?.message[0] || 'Erro ao tentar atualizar o comprador';
        snackbar(setAlert, {
          type: 'error',
          message
        })
      }
    } else {
      try {
        const { status } = await API.post(`/buyers`, buyer);
        if (status === 201) {
          snackbar(setAlert, {
            type: 'success',
            message: 'Comprador criado com sucesso!',
          })
          fetchBuyers();
          buyer.name = ''
          buyer.contact = ''
          buyer.phone = ''
          buyer.profitSharing = ''
        }
      } catch (err: any) {
        const message = err?.response?.data?.message[0] || 'Erro ao tentar criar o comprador';
        snackbar(setAlert, {
          type: 'error',
          message
        })
      }
    }
  };

  const fetchBuyers = async () => {
    const params = { customerId: props.id };
    getBuyers(params)
      .then(({ data }) => {
        setBuyers([
          ...new Array(pagination.page * pagination.limit),
          ...data.data,
        ]);
        setTotal(data.total);
      })
      .catch((error) =>
        snackbar(setAlert, {
          type: 'error',
          message:
            error?.response?.data?.message || 'Erro ao listar os compradores',
        }),
      );
  };

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setBuyers([]);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({ ...old, page }));
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {
    try {
      const params = searchText[0]
        ? {
          search: {
            fields: ['name'],
            value: searchText.length ? searchText : null,
          },
        }
        : {};
      const { data } = await API.get<any>(`/buyers`, params);
      setBuyers([
        ...new Array(pagination.page * pagination.limit),
        ...data.data,
      ]);
    } catch (err: any) {
      snackbar(setAlert, {
        type: 'error',
        message: err?.response?.data?.message,
      });
    }
  };

  return (
    <Grid container spacing={5} justifyContent="space-between">
      {showDeleteDecisionAlert && (
        <Decision
          title="Exclusão de registro"
          message="Tem certeza de que deseja excluir o registro selecionado?"
          handleDecision={(decision) => removeBuyer(decision, idBuyer)}
        />
      )}
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Nome*
        </Typography>
        <TextField
          error={nameBuyerError}
          helperText={nameBuyerError ? 'Campo Obrigatório!' : ''}
          required
          variant="outlined"
          type="text"
          placeholder="Digite o nome do comprador"
          value={buyer.name}
          onChange={({ target: { value } }): void =>
            setBuyer({ ...buyer, name: value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Nome do Contato*
        </Typography>
        <TextField
          error={contactNameBuyerError}
          helperText={contactNameBuyerError ? 'Campo Obrigatório!' : ''}
          required
          variant="outlined"
          type="text"
          placeholder="Digite o nome do contato"
          value={buyer.contact}
          onChange={({ target: { value } }): void =>
            setBuyer({ ...buyer, contact: value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Telefone*
        </Typography>
        <InputMask
          required
          mask="(99) 99999-9999"
          value={buyer.phone}
          onChange={({ target: { value } }): void =>
            setBuyer({ ...buyer, phone: value })
          }
        >
          {() => (
            <TextField
              error={phoneBuyerError}
              helperText={phoneBuyerError ? 'Campo Obrigatório!' : ''}
              required
              variant="outlined"
              type="tel"
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className="subtitle">
          Participação no Faturamento*
        </Typography>
        <TextField
          name="profitSharing"
          title={'Participação no Faturamento'}
          fullWidth
          variant="outlined"
          type="number"
          error={profitSharingBuyerError}
          helperText={profitSharingBuyerError ? 'Campo Obrigatório!' : ''}
          value={buyer.profitSharing}
          onChange={({ target: { value } }): void =>
            setBuyer({ ...buyer, profitSharing: Number(value) })
          }
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: (
              <InputAdornment position="start">%</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} className="text-center">
        <Button
          className="button buttonContainedRounded buttonContainedColorPrimary CTA"
          onClick={() => {
            if (validationBuyer() == true) {
              saveBuyer();
            }
          }}
        >
          <Check /> Salvar Comprador
        </Button>
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <BuyersTable
          columns={columnsBuyers}
          data={buyers}
          onSearchChange={(event: any) => { return debounce(onSearchChange, 700)(event); }}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          currentPage={pagination.page}
          totalRecords={total}
          rowsPerPage={rowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default Buyer;
