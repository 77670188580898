import React, { FormEvent } from 'react';
import {
    Grid,
    Typography,
    Button,
    LinearProgress,
    InputAdornment,
    FormControl,
    IconButton,
    TextField,
    Link,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { LogoGrande } from '../../components/shared/Logo';

import './LoginPage.css';
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface ILogin {
    onChangeUserLogin?: (event: any) => void;
    onChangePasswordLogin?: (event: any) => void;
    onChangeCaptchaLogin?: (event: any) => void;
    onSubmitPostLogin?: (event: FormEvent<HTMLFormElement>) => void;
    onRequestLogin?: boolean;
    userErrorLogin?: boolean;
    passwordErrorLogin?: boolean;
    captchaErrorLogin?: boolean;
    showPasswordLogin?: boolean;
    onClickShowPassword?: () => void;
    onClickStep?: () => void;
    onClickCreateAccount?: () => void;
    children?: any;
    captcha?: string;
    emailError?: boolean;
}

const LoginComponent = ({
    onChangeUserLogin,
    onChangePasswordLogin,
    onSubmitPostLogin,
    onRequestLogin,
    userErrorLogin,
    passwordErrorLogin,
    captchaErrorLogin,
    onChangeCaptchaLogin,
    showPasswordLogin,
    onClickShowPassword,
    onClickStep,
    onClickCreateAccount,
    children,
    captcha,
    emailError
}: ILogin) => {
    const history = useHistory()

    return (<>
        <div>
            <form onSubmit={onSubmitPostLogin} className="containerLoginPage">
                {children}
                <div className="containerCredentials">
                    <Grid
                        className="gridContainerLogo"
                        container
                        spacing={0}
                        justifyContent="center"
                    >
                        <Grid item xs={12}>
                            {onRequestLogin ? (
                                <LinearProgress color="primary" />
                            ) : (
                                <div style={{ height: '4px' }} />
                            )}
                        </Grid>
                        <Grid
                            className="gridContainerCredentials"
                            container
                            spacing={2}
                            justifyContent="center"
                        >
                            <Grid item xs={10}>
                                <div className="headerCredentials">
                                    <Typography
                                        className="titleHeader"
                                        variant="h5"
                                        align="left"
                                    >
                                        <b>Entrar</b>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={10}>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <Typography className="subtitle">LOGIN</Typography>
                                    <TextField
                                        placeholder='Login'
                                        //type='email'
                                        error={userErrorLogin || emailError}
                                        id="outlined-adornment-mail"
                                        helperText={userErrorLogin ? 'Campo Obrigatório!' : emailError ? 'Insira um formato de e-mail válido' : null}
                                        variant="outlined"
                                        onChange={onChangeUserLogin}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={10}>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <Typography className="subtitle">SENHA</Typography>
                                    <TextField
                                        id="outlined-adornment-password"
                                        placeholder='Senha'
                                        error={passwordErrorLogin}
                                        helperText={passwordErrorLogin ? 'Campo Obrigatório!' : ''}
                                        variant="outlined"
                                        onChange={onChangePasswordLogin}
                                        type={showPasswordLogin ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={onClickShowPassword}
                                                        edge="end">
                                                        {showPasswordLogin ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={10}>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <Typography className="subtitle">RESPONDA A PERGUNTA ABAIXO</Typography>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        placeholder={captcha}
                                        error={captchaErrorLogin}
                                        helperText={captchaErrorLogin ? 'Responda a pergunta!' : ''}
                                        onChange={onChangeCaptchaLogin}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={10}>
                                <Button
                                    style={{ backgroundColor: '#ffac00'}}
                                    type="submit"
                                    className="button CTA loginButton"
                                    disabled={onRequestLogin}
                                    size="large"
                                >
                                    {onRequestLogin ? 'EFETUANDO O LOGIN' : 'ENTRAR'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Link variant="body2" onClick={() => {
                                    return history.push('/forgotPassword')
                                }} style={{cursor:"pointer"}}>
                                    <b>ESQUECI MINHA SENHA</b>
                                </Link>
                            </Grid>
                            {/*<Grid item xs={10}>
                                <Button
                                    style={{ backgroundColor: '#180048'}}
                                    //type="submit"
                                    className="button CTA loginButton"
                                    size="large"
                                    onClick={() => {
                                        //return history.push('/createAccount')
                                    }}
                                >
                                    CRIAR CONTA
                                </Button>
                                </Grid>*/}
                            <Grid item xs={12}>
                                <div className="footer" />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </form>
        </div>
    </>)
}

export default LoginComponent