import { useContext, useState, useEffect } from 'react';
import { AlertContext } from '../../../contexts/AlertContext';
import TableListRole, {
  StatusColumnSales,
  Column,
  Actions,
  customFilterDate
} from '../../../components/shared/Table';
import React from 'react';
import API from '../../../services/services';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Decision } from '../../../components/shared/Alert';
import Container from '../../../components/shared/Container';
import { format } from 'date-fns';
import { IRole } from '../../Role/RoleModel';
import CreateRoleModal from '../../Role/CreateRole/CreateRoleModal';
// import ListUsersModal from '../ListUsersModal/ListUsersModal';
import { snackbar } from '../../../utils/util';
// import EditRoleModal from '../EditRoleModal/EditRoleModal';
import LabelHeaderTable from '../../../components/shared/Label/LabelHeaderTable';
import nProgress from 'nprogress';
import { getRoles } from '../../../services/role-service';
import moment from 'moment';
import { User } from '../../User/UserModel';
import Modal from '../../../components/shared/Modal/ModalSales';
import CreateNotificationModal from '../CreateNotification/CreateNotificationModal';
import ShowNotificationModal from '../ShowNotification';
import { Group } from '@material-ui/icons';

const NotificationList = (): JSX.Element => {
  const { setAlert } = useContext(AlertContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [idToDel, setIdToDel] = useState('');
  const [showDeleteDecisionAlert, setShowDeleteDecisionAlert] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<any[]>([]);
  const [notifications, setNotifications] = useState<any>([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [clients, setClients] = useState([]);

  const [rowIndex, setrowIndex] = useState<any>();
  const [showCreateNotification, setShowCreateNotification] = useState(false);
  const [showEditNotification, setShowEditNotification] = useState(false);
  const [showListUsersModal, setShowListUsersModal] = useState(false);

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: Column[] = [
    {
      name: 'id',
      label: 'Cód.',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'titulo',
      label: 'Título',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'texto',
      label: 'Notificação',
      options: {
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
      },
    },
    {
      name: 'clientes',
      label: 'Clientes',
      options: {
        display: false,
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        customBodyRender: (value) => <>{value.map((op: any) => op?.nome).toString()}</>,
      },
    },
    {
      name: 'dataCriacao',
      label: 'Data de Criação',
      options: {
        customBodyRender: (value): string =>
          moment(value).format('DD/MM/YYYY'),
        sort: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3", color: "#006193" } }),
        ...customFilterDate
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          return (
            <Actions
              buttonProps={[

                {
                  type: 'DELETAR',
                  onClick: (): void => {
                    setIdToDel(selectedRow.rowData[0]);
                    setShowDeleteDecisionAlert(true);

                  },
                },
                {
                  type: 'OTHER',
                  onClick: (): void => {
                    setSelectedClients(selectedRow.rowData[3]);
                    setShowEditNotification(true);
                  },
                  icon: <Group />,
                  text: 'Detalhes'
                },
              ]}
            />
          );
        },
      },
    },
  ];
  const [roles, setRoles] = useState<IRole[]>([] as any[]);
  const [lastPage, setLastPage] = useState(-1);

  useEffect(() => {
    fetchNotifications();
  }, [pagination]);

  useEffect(() => {
    fetchClients();
  }, []);

  async function disableEnablePromotion() { }

  async function fetchNotifications() {
    try {
      const response = await API.get<any>('Notificacao');
      //console.log('hi: ',response);
      setNotifications(response.data?.value);
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
    }

  };

  async function fetchClients() {
    //setIsLoaded(true);

    try {
      const response = await API.get<any>('Usuario/App');
      setClients(response.data.value)
      
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
    }
  }

  const deleteTarget = async () => {
    try {
      const response = await API.delete('Notificacao', idToDel);
      //setPagination({ limit:10, page: 0 })
      snackbar(setAlert, { type: 'success', message: 'Notificação excluída com sucesso!' });
      fetchNotifications();
    } catch (ex: any) {
      snackbar(setAlert, { type: 'error', message: ex?.response?.data?.errors[0] });
    }
    setShowDeleteDecisionAlert(false);
  };

  /*const deleteMultiple = async (rolesId: any) => {
    try {
      await API.post('/roles/delete-multiple', { ids: rolesId });
      snackbar(setAlert, {
        type: 'success',
        message: 'Exclusão realizada com sucesso',
      });

      //return window.location.reload();
      await fetchPromotions();
    } catch (ex) {
    //   snackbar(setAlert, { type: 'error', message: ex?.response?.data?.message });
      snackbar(setAlert, { type: 'error', message: '' });

    } finally {
      setIsLoaded(true);
    }
  };*/

  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setRoles([]);
  };

  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page
    }));
  };

  let timer: NodeJS.Timeout;
  function debounce<Params extends any[]>(
    func: (args: any) => Promise<void>,
    timeout: number,
  ): (...args: Params) => void {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  const onSearchChange = async (searchText: any) => {

    const params = searchText[0]
      ? {
        search: {
          fields: ['name'],
          value: searchText.length ? searchText : null,
        },

      }
      : {};
    getRoles(
      pagination.page + 1,
      pagination.limit,
      params
    ).then(({ data }) => {
      setTotal(data.total);
      searchText[0] ? onChangeRowsPerPage(total) : onChangeRowsPerPage(10)

      setRoles(roles => roles.concat(data.data));
    })
  };

  return (
    <>
      <Grid container>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Notificações
        </Typography>

        <Container
          headerTable="Grupos cadastrados"
          title="Listagem de Grupos"
          showButtonOnTop
          textButtonOnTop="Nova Notificação"
          onClickButtonTop={setShowCreateNotification}
        >
          <Grid style={{ padding: "10px" }}>
            <TableListRole
              title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Consultar Notificações</h3>}
              columns={columns}
              data={notifications || []}
              onSearchChange={(event: any) =>
                debounce(onSearchChange, 700)(event)
              }
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={onChangePage}
              currentPage={pagination.page}
              totalRecords={total}
              rowsPerPage={rowsPerPage}
              onRowsDelete={(indexRole) => {
                const rolesId = roles
                  .filter((_, i) => indexRole.find((x) => x.index === i))
                  .map((id) => id.id);

                //deleteMultiple(rolesId);
              }}
            />
          </Grid>
        </Container>
        {isLoaded && (
          <Skeleton
            className="skeleton"
            variant="rect"
            animation="wave"
            width="100%"
            height={500}
          />
        )}
        <Modal
          open={showDeleteDecisionAlert}
          modalWidth={'400px'}
          handleConfirm={() => deleteTarget()}
          colorBtnConfirm={'#30235d'}
          textBtnConfirm={'EXCLUIR'}
          handleClose={() => setShowDeleteDecisionAlert(false)}
        >
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>EXCLUIR NOTIFICAÇÃO</span>
            </div>
            <div style={{ fontSize: 18, color: '#8c8c8c', fontWeight: 'bold', marginTop: 30 }}>Tem certeza que deseja excluir esta notificação?</div>
          </div>
        </Modal>
        <CreateNotificationModal
          open={showCreateNotification}
          setOpen={setShowCreateNotification}
          //refresh={() => fetchRoles()}
          clientsToSelect={clients}
          refresh={() => {
            fetchNotifications();
          }
          }
        />
        <ShowNotificationModal
          open={showEditNotification}
          setOpen={setShowEditNotification}
          clients={selectedClients}
        />
      </Grid>
    </>
  );
};

export default NotificationList;