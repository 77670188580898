import React, { Suspense } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import ProgressRouter from '../components/shared/ProgressRouter';
import Home from '../pages/Home/Home';
import CreateUser from '../pages/User/CreateUser/CreateUser';
import EditUser from '../pages/User/EditUser/EditUser';
import ListUser from '../pages/User/ListUser/ListUser';
import CreateClient from '../pages/Client/CreateClient/CreateClient';
import EditClient from '../pages/Client/EditClient/EditClient';
import ListClient from '../pages/Client/ListClient/ListClient';
import ViewClient from '../pages/Client/ViewClient/ViewClient';
import ListClientExternal from '../pages/Client/ListClientExternal/ListClientExternal';
import Permission from '../pages/Permission/Permission';
import RoleList from '../pages/Role/RoleList/RoleList';
import PromotionList from '../pages/Promotion/PromotionList/PromotionList';
import AttendanceList from '../pages/Attendance/AttendanceList/AttendanceList';
import FAQList from '../pages/FAQ/FAQList/FAQList';
import TermOfUse from '../pages/TermOfUse';
import NotificationList from '../pages/Notification/NotificationList/NotificationList';
import Vehicles from '../pages/Vehicles';
import Quotation from '../pages/Quotation';
import Coverage from '../pages/Coverage';
import Policies from '../pages/Policies';
import GeneralConditions from '../pages/GeneralConditions'
import ListBroker from '../pages/Brokers/ListBrokers/ListBroker';

type routes = {
  title: string;
  path: string;
  exact: boolean;
  component: any;
};

const routesData: routes[] = [
  {
    title: 'Home',
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    title: 'Listagem de usuários',
    path: '/users',
    exact: true,
    component: ListUser,
  },
  {
    title: 'Criação de usuários',
    path: '/users/create',
    exact: true,
    component: CreateUser,
  },
  {
    title: 'Edição de usuários',
    path: '/users/edit/:id',
    exact: true,
    component: EditUser,
  },
  {
    title: 'Listagem de Clientes',
    path: '/clients',
    exact: true,
    component: ListClient,
  },
  {
    title: 'Criação de Clientes',
    path: '/clients/create',
    exact: true,
    component: CreateClient,
  },
  {
    title: 'Edição de Clientes',
    path: '/clients/edit/:id',
    exact: true,
    component: EditClient,
  },
  {
    title: 'Visualizar Cliente',
    path: '/clients/view/:id',
    exact: true,
    component: ViewClient,
  },
  {
    title: 'Listagem de Clientes',
    path: '/clients-external',
    exact: true,
    component: ListClientExternal,
  },
  {
    title: 'Listagem de Grupos',
    path: '/groups',
    exact: true,
    component: RoleList,
  },
  {
    title: 'Permissões do grupo',
    path: '/permissions',
    exact: true,
    component: Permission,
  },
  {
    title: 'Listagem de Promoções',
    path: '/promotions',
    exact: true,
    component: PromotionList,
  },
  {
    title: 'Listagem de Atendimentos',
    path: '/attendance',
    exact: true,
    component: AttendanceList,
  },
  {
    title: 'FAQ',
    path: '/faq',
    exact: true,
    component: FAQList,
  },
  {
    title: 'Notificação',
    path: '/notification',
    exact: true,
    component: NotificationList,
  },
  {
    title: 'Termos de uso',
    path: '/terms',
    exact: true,
    component: TermOfUse
  },
  {
    title: 'Corretores',
    path: '/brokers',
    exact: true,
    component: ListBroker
  },
  {
    title: 'Veículos',
    path: '/vehicles',
    exact: true,
    component: Vehicles
  },
  {
    title: 'Cotações',
    path: '/quotation',
    exact: true,
    component: Quotation
  },
  {
    title: 'Cobertura',
    path: '/coverage',
    exact: true,
    component: Coverage
  },
  {
    title: 'Apólices',
    path: '/policies',
    exact: true,
    component: Policies
  },
  {
    title: 'Condições Gerais',
    path: '/general-conditions',
    exact: true,
    component: GeneralConditions
  }
];

const Routes = (): JSX.Element => (
  <Suspense fallback={<></>}>
    <Switch>
      <Route path='/teste' component={Home} />
      {routesData.map((route, index) => (
        <ProgressRouter key={index} {...route} />
      ))}
      <Redirect to='/home' />
    </Switch>
  </Suspense>
);

export default Routes;
