import React from 'react';
import { Grid } from '@material-ui/core';
import { Group } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';

interface IListUsersModal {
    setOpen: any;
    open: boolean;
    users: any;
}
const ListUsersModal = (props: IListUsersModal): JSX.Element => {
    const { open, setOpen, users } = props;    
    return (
        <ModalWithTransition open={open} handleClose={setOpen} modalWidth={'300'}>
            <Grid container spacing={2}>
                <Grid container alignItems="center" style={{ fontWeight: 'bold' }}>
                    <Group style={{ color: 'orange', marginRight: 7 }} />

                    Usuários no Grupo
                </Grid>
                {users?.length
                    ?
                    users?.map((item: any, index: number) => <Grid key={index} item xs={12}>{item.name}      </Grid>)
                    :
                    'Não há usuários'
                }
            </Grid>
        </ModalWithTransition>

    );
};

export default ListUsersModal;
