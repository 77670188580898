import React from 'react';

export default function LabelGray({ label, isLong }: any){
    return(
        <div style={{
            textJustify: 'initial',
            backgroundColor: '#eeeef3',
            borderRadius: 10,
            padding: 10,
            height: isLong ? 100 : 50,
            borderColor: '#eeeef3',
            borderWidth: 2,
            fontSize: 16
          }}>
            {label}
        </div>
    );
}