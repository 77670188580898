import ThemeProvider from '@material-ui/styles/ThemeProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import AuthProvider from './contexts/AuthContext';
import './index.css';
import App from './pages/Home';
import { theme } from './utils/Theme'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </ThemeProvider>,
    document.getElementById('root'),
);
