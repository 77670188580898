import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { PlaylistAddCheck } from '@material-ui/icons';
import { Autocomplete } from '@mui/material';
import { AlertContext } from '../../../contexts/AlertContext';
import Modal from '../../../components/shared/Modal/ModalSales';
import { snackbar } from '../../../utils/util';
import API from '../../../services/services';

interface IAttendance {
    name: string;
    typeAtt: string;
    description: string;
}

interface ICreateRoleModal {
    setOpen: any;
    open: boolean;
    refresh: () => void;
    att: any
}
const EditAttendanceModal = (props: ICreateRoleModal): JSX.Element => {
    const { open, setOpen, refresh, att } = props;
    const { setAlert } = useContext(AlertContext);
    const [name, setName] = useState('');
    const [typeAtt, setTypeAtt] = useState('');
    const [description, setDescription] = useState('')
    const [message, setMessage] = useState('')
    const [order, setOrder] = useState(0)

    const [nameError, setNameError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [orderError, setOrderError] = useState(false);

    const types = [
        'Email',
        'WhatsApp',
        'Telefone'
    ]

    function validation() {
        setNameError(!name);
        setTypeError(!typeAtt);
        setDescriptionError(!description);
        setMessageError(!message);
        setOrderError(!order);

        return (
            !name || !typeAtt || !description || !message || !order
        );
    }

    useEffect(() => {

        function initValues() {
            setOrder(att[1]);
            setName(att[2]);
            setTypeAtt(att[3]);
            setDescription(att[4]);
            setMessage(att[5]);
        }

        if (open) {
            initValues();
        }

        if (!open && nameError || typeError || descriptionError || messageError) {
            setNameError(false);
            setTypeError(false);
            setDescriptionError(false);
            setMessageError(false);
            setOrderError(false);
        }

    }, [open])

    function cleanFields() {
        setName('');
        setTypeAtt('');
        setDescription('');
        setMessage('');
        setOrder(0);
    }

    const editAttendance = async () => {
        //validation
        if (validation()) {
            return;
        }

        try {
            //console.log('aqui: ', { pergunta: question, resposta: answer });
            const response = await API.put(`/Atendimento/${att[0]}`, {
                nome: name,
                tipo: typeAtt,
                descricao: description,
                mensagem: message,
                ordem: order
            })
            //const response = await createRole({ name: roleName, permissionsIds: [] });
            snackbar(setAlert, { type: 'success', message: "Atendimento editado com sucesso!" })
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });

        }
        cleanFields();
        setOpen(false);
        refresh();
    }
    return (
        <Modal
            open={open}
            modalWidth={'700px'}
            handleConfirm={editAttendance}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={setOpen}
        >
            <Grid container spacing={2}>
                <Grid container alignItems="center" style={{ fontWeight: 'bold', color: '#30235d' }}>
                    <PlaylistAddCheck style={{ color: 'orange' }} />

                    &nbsp;&nbsp;EDITAR ATENDIMENTO
                </Grid>
                <Grid item xs={6}>
                    <Typography className="subtitle">ORDEM</Typography>
                    <TextField
                        value={order}
                        variant="outlined"
                        error={orderError}
                        helperText={orderError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setOrder(Number(value))
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className="subtitle">TIPO</Typography>
                    <Autocomplete
                        disablePortal
                        options={types}
                        value={typeAtt}
                        getOptionLabel={(option: any) => option}
                        onChange={(event: any, option: any): void => {
                            setTypeAtt(option)
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">NOME</Typography>
                    <TextField
                        value={name}
                        variant="outlined"
                        error={nameError}
                        helperText={nameError && 'Campo Obrigatório!'}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setName(value)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">DESCRIÇÃO</Typography>
                    <TextField
                        value={description}
                        variant="outlined"
                        error={descriptionError}
                        helperText={descriptionError && 'Campo Obrigatório!'}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setDescription(value)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">MENSAGEM</Typography>
                    <TextField
                        value={message}
                        variant="outlined"
                        error={descriptionError}
                        helperText={descriptionError && 'Campo Obrigatório!'}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setMessage(value)
                        }
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default EditAttendanceModal;
