import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography, Chip } from '@material-ui/core';
import { PlaylistAddCheck } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { createRole } from '../../../services/role-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import { Checkbox, FormControlLabel } from '@mui/material';
import Modal from '../../../components/shared/Modal/ModalSales';
import PermissionGroupSelect from '../../../components/shared/inputs/PermissionGroupSelect';
import API from '../../../services/services';
import { Autocomplete } from '@material-ui/lab';
import ChipInput from 'material-ui-chip-input';


interface ICreateRoleModal {
    setOpen: any;
    open: boolean;
    refresh: () => void;
    clientsToSelect: any[];
}
const CreateNotificationModal = (props: ICreateRoleModal): JSX.Element => {
    const { open, setOpen, refresh, clientsToSelect } = props;
    const { setAlert } = useContext(AlertContext);

    const [title, setTitle] = useState('');
    const [notification, setNotification] = useState('');
    const [clients, setClients] = useState<any[]>([]);
    const [selectedClients, setSelectedClients] = useState<any[]>([]);

    const [titleError, setTitleError] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [clientsError, setClientsError] = useState(false);

    function validation() {
      setTitleError(!title);
      setNotificationError(!notification);
      setClientsError(!clients.length);

      return (
        !title || !notification || !clients.length
      );
    }

    useEffect(() => {

        if(!open && titleError || notificationError || clientsError){
            setTitleError(false);
            setNotificationError(false);
            setClientsError(false);
        }

    },[open])

    function cleanFields(){
        setTitle('');
        setNotification('');
        setClients([]);
    }

    const createNotification = async () => {

        if(validation()){
            return;
        }

        const ids = clients.map((client: any) => client?.id);
        //console.log(title, notification, ids);
        //return;


        try {
            const response = await API.post('Notificacao',{
              titulo: title,
              texto: notification,
              clienteId: ids
            });
            snackbar(setAlert, { type: 'success', message: "Notificação criada com sucesso!" })
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.errors[0] });
        }
        cleanFields();
        setOpen(false);
        refresh();
    }

    const selectAll = () => {
      setClients(clientsToSelect);
    };

    const removeAll = () => {
      setClients([]);
    };

    return (
        <Modal 
            open={open}
            modalWidth={'700px'}
            handleConfirm={createNotification}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={setOpen}
          >
             <Grid container spacing={2}>
                 <Grid container alignItems="center" style={{ fontWeight: 'bold', color: '#30235d' }}>
                     <PlaylistAddCheck style={{ color: 'orange' }} />

                    &nbsp;&nbsp;CADASTRAR NOTIFICAÇÃO
                 </Grid>
                 <Grid item xs={12}>
                    <Typography className="subtitle">TÍTULO</Typography>
                    <TextField
                        label="Título"
                        variant="outlined"
                        error={titleError}
                        helperText={titleError && 'Campo Obrigatório!'}
                        onChange={({ target: { value } }): void =>
                            setTitle(value)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">NOTIFICAÇÃO</Typography>
                    <TextField
                        label="Notificação"
                        multiline
                        rows={4}
                        variant="outlined"
                        error={notificationError}
                        helperText={notificationError && 'Campo Obrigatório!'}
                        onChange={({ target: { value } }): void =>
                            setNotification(value)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                  <Typography className="subtitle" >
                    CLIENTES
                  </Typography>
                  <Grid item xs={12} sm={12} md={12}>
                  <Autocomplete
                    id='clients-autocomplete'
                    options={clientsToSelect}
                    multiple
                    getOptionLabel={(option) => option.nome}
                    value={clients}
                    filterSelectedOptions
                    disableCloseOnSelect
                    onChange={(_, value: any) => {
                      //console.log(clients)
                      setClients(value);
                    }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map(
                        (option, index) =>
                          option && (
                            <Chip
                              label={option.nome}
                              {...getTagProps({ index })}
                              disabled={false}
                            />
                          ),
                      )
                    }
                    renderInput={(params) => {
                      return (
                          <TextField
                            onChange={(e) => {
                              
                            }}
                            //value={clients}
                            {...params}
                            error={clientsError}
                            helperText={clientsError && 'Campo Obrigatório!'}
                            variant="outlined"
                            placeholder="Clientes"
                            style={{width: '100%'}}
                        />
                        
                      );
                    }}
                    />
                    <Button onClick={selectAll} color="primary">
                      + Adicionar todos
                    </Button>
                    <Button onClick={removeAll} color="secondary">
                      - Remover todos
                    </Button>
                  </Grid>
                </Grid>
            </Grid>
          </Modal>
    );
};

export default CreateNotificationModal;
