import React, { useState, useEffect, useContext } from 'react';
import PoliciesTable, { Column, Actions } from '../../components/shared/Table';
import { Button, Grid, TextField, Typography, InputAdornment } from '@material-ui/core';
import { Autocomplete, Chip } from '@mui/material';
import Container from '../../components/shared/Container';
//import { getUsers, activeUser } from '../../../services/user-service';
import { AlertContext } from '../../contexts/AlertContext';
import { snackbar } from '../../utils/util';
import { getGeneralConditions, postGeneralCondition, getFile, activateDeactivateCondition, deleteGeneralCondition } from '../../services/general-codititons-service';
import LabelHeaderTable from '../../components/shared/Label/LabelHeaderTable';
import { DeleteForever, Launch, CloudDownload, Assignment, CloudUpload, PlaylistAddCheck, PictureAsPdf } from '@material-ui/icons';
import Modal from '../../components/shared/Modal/ModalSales'
import { fontStyle } from '@mui/system';
import { IGeneralCondition } from '../../models/IGeneralCondition';
import { getUsers } from '../../services/options-service';
import './styles.css';

const GeneralConditions: React.FC = () => {
  const { setAlert } = useContext(AlertContext);
  const [id] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [generalConditions, setGeneralConditions] = useState<IGeneralCondition[]>([])
  const [generalCondition, setGeneralCondition] = useState<IGeneralCondition>({
    id: '',
    descricao: '',
    nomeArquivoCondicao: '',
    status: true,
    arquivo: '',
  })
  const [users, setUsers] = useState<any>([]);
  const [selectedConditionId, setSelectedConditionId] = useState('');

  const [pagination, setPagination] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [showModalDel, setShowModalDel] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);

  const [clients, setClients] = useState<any[]>([]);
  const clientsToSelect = ["Cliente","Corretor"]


  const onChangeRowsPerPage = (numberOfRows: number) => {
    setPagination({ limit: numberOfRows, page: 0 });
    setRowsPerPage(numberOfRows);
    setGeneralConditions([]);
  };
  const onChangePage = (page: number) => {
    setPagination((old) => ({
      ...old,
      page,
    }));
  };

  const columns: Column[] = [
    {
      name: 'id',
      label: 'Código',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        display: false,
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: 'descricao',
      label: 'Descrição',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'nomeArquivoCondicao',
      label: 'Nome do arquivo',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'aplicativo',
      label: 'Aplicativo',
      options: {
        customHeadLabelRender: (value: any) => <LabelHeaderTable label={value.label} />,
        setCellHeaderProps: value => ({ style: { backgroundColor: "#eeeff3" } }),
        sort: false,
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (): any => ({
          style: {
            backgroundColor: "#eeeff3",
            color: "#30235d",
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: 130,
            minWidth: 130,
          },
        }),
        customBodyRender: (value, selectedRow): JSX.Element => {
          const id = selectedRow.rowData[0];
          const status = selectedRow.rowData[1];
          return (
            <Actions
              buttonProps={[
                {
                  type: 'OTHER',
                  onClick: () => openRemoveCondition(id),
                  icon: <DeleteForever />,
                  text: 'Excluir',
                  id: id
                },
                {
                  type: 'OTHER',
                  onClick: () => fetchGeneralConditionFile(id),
                  icon: <PictureAsPdf />,
                  text: 'Download de Documento',
                  id: id
                },
                {
                  type: 'DISABLE',
                  onClick: () => changeConditionStatus(id),
                  checked: status,
                  id: id
                },

              ]}
            />
          );
        },
      },
    },
  ];

  async function changeConditionStatus(id: string) {
    setIsLoading(true)
    try {
      await activateDeactivateCondition(id)
      snackbar(setAlert, { type: 'success', message: 'Ação realizada com sucesso' });
      fetchGeneralConditions()
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
    }
    setIsLoading(false)
  };

  const openRemoveCondition = (id: string) => {
    setShowModalDel(true)
    setSelectedConditionId(id)
  }

  useEffect(() => {
    fetchGeneralConditions();
    fetchUsers();
  }, [pagination]);

  const fetchGeneralConditions = async () => {
    setIsLoading(true);
    try {
      const { data: { value } } = await getGeneralConditions();
      setGeneralConditions(value);
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
    }
    setIsLoading(false);
  }

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const { data: { value } } = await getUsers({ tipoUsuario: 'Cliente' });
      setUsers(value);
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
    }
    setIsLoading(false);
  }

  const fetchGeneralConditionFile = async (id: string) => {
    setIsLoading(true)
    try {
      const file = await getFile(id);
      const a = document.createElement('a');
      a.target = '_blank'
      a.href = file?.request?.responseURL
      a.download = file?.data
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoading(false)
  }

  const saveGeneralCondition = async () => {
    setIsLoading(true)
    try {
      var formData = new FormData();
      formData.append('descricao', generalCondition?.descricao);
      formData.append('aplicativo', clients.join());

      formData.append('arquivo', generalCondition?.arquivo);
      await postGeneralCondition(formData)
      setShowNewModal(false)
      snackbar(setAlert, { type: 'success', message: 'Ação realizada com sucesso' });
      fetchGeneralConditions()
    } catch (error: any) {
      snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
    }
    setIsLoading(false)
  }

  const removeGeneralCondition = async (id: string) => {
    setIsLoading(true)
    try {
      await deleteGeneralCondition(id)
      fetchGeneralConditions()
      snackbar(setAlert, {
        type: 'success',
        message: 'Ação realizada com sucesso'
      });
      setShowModalDel(false)
    } catch (error: any) {
      snackbar(setAlert, {
        type: 'error',
        message:
          error?.response?.data?.message
      });
    }
    setIsLoading(false)
  }

  const selectAll = () => {
    setClients(clientsToSelect);
  };

  const removeAll = () => {
    setClients([]);
  };

  return (
    <>
      <Grid container>
        <Typography className="contextTopBar" variant="h3" component="h2">
          Documentos
        </Typography>
        <Button
          style={{ position: 'absolute', top: 65, right: 15, color: '#fff', backgroundColor: '#30235d', borderRadius: 10, padding: 6 }}
          onClick={() => setShowNewModal(true)}
        >
          Novo Documento
        </Button>
        <Container>
          <Grid item xs={12}>
            <Grid style={{ padding: "10px" }}>
              <PoliciesTable
                title={<h3 style={{ color: "#30235d", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Consultar Documentos</h3>}
                columns={columns}
                data={generalConditions}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                currentPage={pagination.page}
                totalRecords={total}
                rowsPerPage={rowsPerPage}
              />
            </Grid>
          </Grid>
          <Modal
            open={showNewModal}
            modalWidth={'700px'}
            handleConfirm={() => saveGeneralCondition()}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={() => setShowNewModal(false)}
          >
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PlaylistAddCheck htmlColor='#ffb812' />
                <span style={{ fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>&nbsp;NOVA CONDIÇÃO GERAL</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30 }}>
                <div style={{ width: '100%' }}>
                  <p style={{ color: '#8c8c8c', fontWeight: 'bold', }}>DESCRIÇÃO</p>
                  <TextField
                    variant='outlined'
                    onChange={({ target: { value } }): void =>
                      setGeneralCondition({ ...generalCondition, descricao: value })
                    }
                  />
                </div>
              </div>
              <Grid item xs={12}>
                  <Typography className="subtitle" >
                    APLICATIVO
                  </Typography>
                  <Grid item xs={12} sm={12} md={12}>
                  <Autocomplete
                    id='clients-autocomplete'
                    options={clientsToSelect}
                    multiple
                    //getOptionLabel={option}
                    value={clients}
                    filterSelectedOptions
                    disableCloseOnSelect
                    onChange={(_, value: any) => {
                      setClients(value);
                    }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map(
                        (option, index) =>
                          option && (
                            <Chip
                              label={option}
                              {...getTagProps({ index })}
                              disabled={false}
                            />
                          ),
                      )
                    }
                    renderInput={(params) => {
                      return (
                          <TextField
                            onChange={(e) => {
                              
                            }}
                            //value={clients}
                            {...params}
                          
                            variant="outlined"
                            //placeholder="Clientes"
                            style={{width: '100%'}}
                        />
                        
                      );
                    }}
                    />
                    <Button onClick={selectAll} color="primary">
                      + Adicionar todos
                    </Button>
                    <Button onClick={removeAll} color="secondary">
                      - Remover todos
                    </Button>
                  </Grid>
                </Grid>
              <p style={{ marginTop: 20, color: '#8c8c8c', fontWeight: 'bold', }}>IMPORTAR DOCUMENTO</p>
              <input
                style={{ display: 'none' }}
                id="file-input"
                type="file"
                accept=".pdf"
                onChange={(event: any) => {
                  {
                    setGeneralCondition({ ...generalCondition, arquivo: event.target.files[0] });
                  }
                }}
              />
              <div style={{ display: 'flex' }}>
                <label htmlFor="file-input" style={{ display: 'flex' }}>
                  <Button
                    variant="outlined"
                    component="span"
                    style={{ fontSize: 14, borderWidth: 2, borderRadius: "4px", color: "#000000", borderColor: "#000000", width: '100%' }}
                  >
                    Escolher arquivo
                  </Button>
                  <span style={{
                    display: 'block',
                    width: 500,
                    overflow: 'hidden',
                    padding: 10,
                    whiteSpace: 'nowrap',
                    alignSelf: 'center',
                    textOverflow: 'ellipsis'
                  }}>
                    {generalCondition?.nomeArquivoCondicao ? generalCondition?.nomeArquivoCondicao : generalCondition?.arquivo ? generalCondition?.arquivo?.name : 'Nenhum arquivo selecionado'}
                  </span>
                </label>
              </div>
            </div>
          </Modal>
          <Modal
            open={showModalDel}
            modalWidth={'400px'}
            handleConfirm={() => removeGeneralCondition(selectedConditionId)}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'EXCLUIR'}
            handleClose={() => setShowModalDel(false)}
          >
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 18, fontWeight: 'bold', color: '#30235d', alignSelf: 'center' }}>EXCLUIR CONDIÇÃO</span>
              </div>
              <div style={{ fontSize: 18, color: '#8c8c8c', fontWeight: 'bold', marginTop: 30 }}>Tem certeza que deseja excluir esta condição?</div>
            </div>
          </Modal>
        </Container>
      </Grid>
    </>
  );
};

export default GeneralConditions;
