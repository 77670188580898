import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Grid, TextField, Button, Typography, MuiThemeProvider } from '@material-ui/core';
import { PlaylistAddCheck } from '@material-ui/icons';
import ModalWithTransition from '../../../components/shared/Modal';
import { createRole } from '../../../services/role-service';
import { AlertContext } from '../../../contexts/AlertContext';
import { snackbar } from '../../../utils/util';
import { Checkbox, createMuiTheme, FormControlLabel, Theme } from '@mui/material';
import Modal from '../../../components/shared/Modal/ModalSales'
import { ISale } from '../../../models/ISales';
import { postSale } from '../../../services/sales-services';
import TextEditor from '../../../components/shared/TextEditor'
import MUIRichTextEditor from 'mui-rte';
import { stateToHTML } from 'draft-js-export-html';


interface ICreateRoleModal {
    setOpen: any;
    open: boolean;
    refresh: () => void;
}
const CreatePromotionModal = (props: ICreateRoleModal): JSX.Element => {
    const { open, setOpen, refresh } = props;
    const { setAlert } = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [sale, setSale] = useState<ISale>({
        titulo: '',
        promocao: '',
        resumo: '',
        deletado: false
    });
    const [promocao, setPromocao] = useState('');

    const [nameError, setNameError] = useState(false);

    const savePromotion = async () => {
        setIsLoading(true)
        try {
            await postSale(sale);
            snackbar(setAlert, { type: 'success', message: 'Ação realizada com sucesso' });
            refresh()
            setOpen(false)
        } catch (error: any) {
            snackbar(setAlert, { type: 'error', message: error?.response?.data?.message });
        }
        setIsLoading(false)
    }

      //style
    const defaultTheme: Theme = createMuiTheme({
        palette: {
        primary: {
            main: '#000000',
        },
        },
    });

    Object.assign(defaultTheme, {
        overrides: {
        MUIRichTextEditor: {
            root: {},
            container: {
            display: 'flex',
            flexDirection: 'column-reverse',
            },
            editor: {
            height: '200px',
            maxHeight: '300px',
            overflow: 'auto',
            textAlign: 'left',
            },
            toolbar: {
            borderTop: '1px solid gray',
            },
            placeHolder: {
            width: '100%',
            height: '200px',
            textAlign: 'left',
            top: 0,
            },
            anchorLink: {
            color: '#333333',
            textDecoration: 'underline',
            },
        },
        },
    });

    return (
        <Modal
            open={open}
            modalWidth={'700px'}
            handleConfirm={() => savePromotion()}
            colorBtnConfirm={'#30235d'}
            textBtnConfirm={'SALVAR'}
            handleClose={setOpen}
        >
            <Grid container spacing={2}>
                <Grid container alignItems="center" style={{ fontWeight: 'bold', color: '#30235d' }}>
                    <PlaylistAddCheck style={{ color: 'orange' }} />

                    &nbsp;&nbsp;CADASTRAR PROMOÇÃO
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">Título:</Typography>
                    <TextField
                        variant="outlined"
                        error={nameError}
                        helperText={nameError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setSale({ ...sale, titulo: value })
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="subtitle">Resumo:</Typography>
                    <TextField
                        variant="outlined"
                        error={nameError}
                        helperText={nameError ? 'Campo Obrigatório!' : ''}
                        required={true}
                        onChange={({ target: { value } }): void =>
                            setSale({ ...sale, resumo: value })
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    {/* <TextEditor textValue={promocao} changeEvent={setPromocao} /> */}
                    <MuiThemeProvider theme={defaultTheme}>
                        <MUIRichTextEditor
                            label="Digite aqui !"
                            controls={[
                                'title',
                                'bold',
                                'underline',
                                'numberList',
                                'bulletList',
                                'strikethrough',
                                'clear'
                              ]}
                            onChange={(event) => {
                                const plainText = stateToHTML(event.getCurrentContent());
                                setSale({ ...sale, promocao: plainText });
                            }}
                        />
                    </MuiThemeProvider>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: 'flex' }}>
                        <Typography className="subtitle">Ativar promoção?</Typography>
                        <Checkbox onChange={({ target: { checked } }): void =>
                            setSale({ ...sale, deletado: checked })
                        }
                        />
                    </div>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CreatePromotionModal;
