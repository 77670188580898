import API from './services';


const getRoles = (page: number, limit: number, params?: {}) => {
  return API.get<any>(`/roles?page=${page}&limit=${limit}`, params);
};

const getRoleById = async (id: number) => {
  const { data } = await API.get<any>(`/roles/${id}`);
  return data;
};

const updateRole = async (id: number, data: any) => {

  return await API.patch(`/roles/${id}`, data);
};

const createRole = (role: any) => {
  return API.post(`/roles`, role);
};

const removeRole = (id: number) => {
  return API.delete(`/roles`, id);
}

export { createRole, updateRole, getRoleById, getRoles, removeRole };
